<template>
  <span>
    <b-button
            variant="edit"
            @click="showTaskModal(task)"
            qid="delete-s-cancel-button"
    >
      <span v-if="task">
        <font-awesome-icon icon="pen"/>
      </span>
      <span v-if="!task">
        {{ $t('tasks.open_first_task') }}
      </span>
    </b-button>

    <!-- Task modal -->
    <b-modal ref="task-modal"
             @hide="hideTaskModal"
             size="xl"
             modal-class="bottom-left task-modal">

      <template #modal-header="{ close }">
        <div class="" style="width: 100%;">
          <span @click="minimizeTask" class="btn btn-secondary cursor-pointer float-right">
            <font-awesome-icon icon="window-minimize"/>
          </span>
          <span @click="showEditQModal(selectedTask)" class="btn btn-secondary cursor-pointer float-right">
            <font-awesome-icon icon="cog"/>
          </span>
          <h5 class="mb-1 text-center"
              v-if="currentView != 'data_collection' && selectedTask && selectedTask.id && selectedTask.model && selectedTask.code != 'system_impact_assessment'">
            {{ selectedTask.full_title }}
          </h5>
          <h5 class="mb-1 text-center"
              v-if="currentView == 'data_collection' && selectedTask && selectedTask.id && selectedTask.model && selectedTask.code != 'system_impact_assessment'">
            {{ selectedTask.full_title }}
          </h5>
          <h5 class="mb-1 text-center"
              v-if="selectedTask && selectedTask.id && selectedTask.model && selectedTask.scope && selectedTask.code == 'system_impact_assessment'">
            {{ $t('system.assess_how') }}
            <span v-if="selectedTask.model.who_am_i == 'App\\System\\Models\\System'">
              {{ $t('system.the_system') }}
            </span>
            <span v-if="selectedTask.model.who_am_i == 'App\\Activity\\Models\\Activity'">
              {{ $t('system.the_activity') }}
            </span>
            <span v-if="selectedTask.model.who_am_i == 'App\\Process\\Models\\Process'">
              {{ $t('system.the_process') }}
            </span>
            <span v-if="selectedTask.model.who_am_i == 'App\\Maintenance\\ThirdParty\\Models\\ThirdParty'">
              {{ $t('system.the_contract') }}
            </span>
            <span v-if="selectedTask.model.who_am_i == 'App\\Maintenance\\Policy\\Models\\Policy'">
              {{ $t('system.the_policy') }}
            </span>
            <span class="breadcrumb-item">
              <a @click="navigateToTask(selectedTask)" :class="{'not-clickable': !isOwner(selectedTask.model)}">
                <font-awesome-icon icon="desktop" class="mr-1"
                                   v-if="selectedTask.model.who_am_i == 'App\\System\\Models\\System'"/>
                <font-awesome-icon icon="cubes" class="mr-1"
                                   v-if="selectedTask.model.who_am_i == 'App\\Activity\\Models\\Activity'"/>
                <font-awesome-icon icon="cube" class="mr-1"
                                   v-if="selectedTask.model.who_am_i == 'App\\Process\\Models\\Process'"/>
                <font-awesome-icon icon="file-signature" class="mr-1"
                                   v-if="selectedTask.model.who_am_i == 'App\\Maintenance\\ThirdParty\\Models\\ThirdParty'"/>
                <font-awesome-icon icon="file-invoice" class="mr-1"
                                   v-if="selectedTask.model.who_am_i == 'App\\Maintenance\\Policy\\Models\\Policy'"/>

                {{ selectedTask.model.name }}
              </a>
            </span>

            {{ $t('system.impacts') }}
            <span v-if="selectedTask.scope.who_am_i == 'App\\System\\Models\\System'">
              {{ $t('system.the_system') }}
            </span>
            <span v-if="selectedTask.scope.who_am_i == 'App\\Activity\\Models\\Activity'">
              {{ $t('system.the_activity') }}
            </span>
            <span v-if="selectedTask.scope.who_am_i == 'App\\Process\\Models\\Process'">
              {{ $t('system.the_process') }}
            </span>
            <span v-if="selectedTask.scope.who_am_i == 'App\\Maintenance\\ThirdParty\\Models\\ThirdParty'">
              {{ $t('system.the_contract') }}
            </span>
            <span v-if="selectedTask.scope.who_am_i == 'App\\Maintenance\\Policy\\Models\\Policy'">
              {{ $t('system.the_policy') }}
            </span>
            <span class="breadcrumb-item">
              <a @click="navigateToTaskScope(selectedTask)" :class="{'not-clickable': !isOwner(selectedTask.scope)}">
                <font-awesome-icon icon="desktop" class="mr-1"
                                   v-if="selectedTask.scope.who_am_i == 'App\\System\\Models\\System'"/>
                <font-awesome-icon icon="cubes" class="mr-1"
                                   v-if="selectedTask.scope.who_am_i == 'App\\Activity\\Models\\Activity'"/>
                <font-awesome-icon icon="cube" class="mr-1"
                                   v-if="selectedTask.scope.who_am_i == 'App\\Process\\Models\\Process'"/>
                <font-awesome-icon icon="file-signature" class="mr-1"
                                   v-if="selectedTask.scope.who_am_i == 'App\\Maintenance\\ThirdParty\\Models\\ThirdParty'"/>
                <font-awesome-icon icon="file-invoice" class="mr-1"
                                   v-if="selectedTask.scope.who_am_i == 'App\\Maintenance\\Policy\\Models\\Policy'"/>

                {{ selectedTask.scope.name }}
              </a>
            </span>
          </h5>
          <div class="row mt-3 pt-4 pb-1" v-if="selectedTask && selectedTask.id">
            <div class="col-sm-6"
                 v-if="selectedTask.scope.name"
            >
              <b-form-group>
                <div class="">
                  <div v-if="(isOwner(selectedTask.project) || isOwner(selectedTask)) && currentView == 'data_collection'">
                    <span class="text-success breadcrumb-item cursor-pointer" @click="switchCurrentView('default')">
                      <font-awesome-icon icon="angle-left" class="mr-1"/>
                      {{ $t('tasks.modal.back_to_main_task') }}
                    </span>
                  </div>

                  <label qid="task-name" v-if="selectedTask.code == 'system_impact_assessment'">
                    {{ $t('tasks.modal.system_impact_title') }}
                  </label>
                  <div v-if="selectedTask.code == 'system_impact_assessment'" class="text-formatted">
                    <span v-if="selectedTask.scope.who_am_i == 'App\\Process\\Models\\Process'">
                      {{ $t('tasks.modal.system_impact_description_process') }}
                    </span>
                    <span v-if="selectedTask.scope.who_am_i == 'App\\Activity\\Models\\Activity'">
                      {{ $t('tasks.modal.system_impact_description_activity') }}
                    </span>
                  </div>
                  <div v-if="selectedTask.code != 'system_impact_assessment' && currentView != 'data_collection'">
                    <div v-if="selectedTask.description && selectedTask.description.length > 300">
                      <p v-if="showFullDataCollectionDescription" class="text-formatted">
                        {{ selectedTask.description }}
                      </p>
                      <p v-if="!showFullDataCollectionDescription" class="text-formatted">
                        {{ selectedTask.description | truncate(300) }}
                      </p>
                      <div class="text-center mt-3"
                           @click="showFullDataCollectionDescription=!showFullDataCollectionDescription">
                        <span class="cursor-pointer" v-if="!showFullDataCollectionDescription">
                          <span class="mr-2">
                            {{ $t('system.view_more') }}
                          </span>
                          <font-awesome-icon icon="chevron-down"/>
                        </span>
                        <span class="cursor-pointer" v-if="showFullDataCollectionDescription">
                          <span class="mr-2">
                            {{ $t('system.view_less') }}
                          </span>
                          <font-awesome-icon icon="chevron-up"/>
                        </span>
                      </div>
                    </div>
                    <p v-if="selectedTask.description && selectedTask.description.length <= 300"
                       class="text-formatted"
                    >
                      {{ selectedTask.description }}
                    </p>
                  </div>

                  <div v-if="selectedTask.code != 'system_impact_assessment' && currentView == 'data_collection'">
                    <div v-if="selectedTask.data_collection_description && selectedTask.data_collection_description.length > 300">
                      <p v-if="showFullDataCollectionDescription" class="text-formatted">
                        {{ selectedTask.data_collection_description }}
                      </p>
                      <p v-if="!showFullDataCollectionDescription" class="text-formatted">
                        {{ selectedTask.data_collection_description | truncate(300) }}
                      </p>
                      <div class="text-center mt-3"
                           @click="showFullDataCollectionDescription=!showFullDataCollectionDescription">
                        <span class="cursor-pointer" v-if="!showFullDataCollectionDescription">
                          <span class="mr-2">
                            {{ $t('system.view_more') }}
                          </span>
                          <font-awesome-icon icon="chevron-down"/>
                        </span>
                        <span class="cursor-pointer" v-if="showFullDataCollectionDescription">
                          <span class="mr-2">
                            {{ $t('system.view_less') }}
                          </span>
                          <font-awesome-icon icon="chevron-up"/>
                        </span>
                      </div>
                    </div>
                    <p v-if="selectedTask.data_collection_description && selectedTask.data_collection_description.length <= 300"
                       class="text-formatted"
                    >
                      {{ selectedTask.data_collection_description }}
                    </p>
                  </div>

                </div>
                <div class="mt-2 mb-1" v-if="getMedia('question') && currentView != 'data_collection'">
                  <p v-for="(file, media_index) in getMedia('question')" :key="media_index">
                    <span class="mr-3 float-right text-muted cursor-pointer"
                          @click="downloadMedia(file)"
                          qid="download-media"
                    >
                      <font-awesome-icon icon="cloud-download-alt"/>
                    </span>
                    <a @click="downloadMedia(file)" qid="create-p-media-item">
                      {{ file.file_name }}
                    </a>
                  </p>
                </div>
                <div class="mt-2 mb-1" v-if="getMedia('data_collection') && currentView == 'data_collection'">
                  <p v-for="(file, media_index) in getMedia('data_collection')" :key="media_index">
                    <span class="mr-3 float-right text-muted cursor-pointer"
                          @click="downloadMedia(file)"
                          qid="download-media"
                    >
                      <font-awesome-icon icon="cloud-download-alt"/>
                    </span>
                    <a @click="downloadMedia(file)" qid="create-p-media-item">
                      {{ file.file_name }}
                    </a>
                  </p>
                </div>

                <div class="" v-if="getLinks('question') && currentView != 'data_collection'">
                  <p v-for="(link, link_index) in getLinks('question')" :key="link_index">
                    <a :href="link.url" target="_blank" qid="create-p-link-item">
                      {{ link.name ? link.name : link.url }}
                    </a>
                  </p>
                </div>
                <div class="" v-if="getLinks('data_collection') && currentView == 'data_collection'">
                  <p v-for="(link, link_index) in getLinks('data_collection')" :key="link_index">
                    <a :href="link.url" target="_blank" qid="create-p-link-item">
                      {{ link.name ? link.name : link.url }}
                    </a>
                  </p>
                </div>
              </b-form-group>

            </div>
            <div :class="{'col-sm-6': selectedTask.scope && selectedTask.scope.name, 'col-sm-12': !selectedTask.scope}">
              <b-form-group>
                <div class="">
                  <span class="btn btn-secondary btn-xs float-right cursor-pointer" @click="openWelcomePage()"
                        v-if="welcomeGuide && welcomeGuide.id">
                    <font-awesome-icon icon="eye"/>
                    {{ $t('system.welcome_page_button') }}
                  </span>
                  <div v-if="selectedTask.code != 'system_impact_assessment' && selectedTask.project.project_type == 'type two' && currentView != 'data_collection'">
                    <div v-if="selectedTask.description_two && selectedTask.description_two.length > 300">
                      <p v-if="showFullDataCollectionTwoDescription" class="text-formatted">
                        {{ selectedTask.description_two }}
                      </p>
                      <p v-if="!showFullDataCollectionTwoDescription" class="text-formatted">
                        {{ selectedTask.description_two | truncate(300) }}
                      </p>
                      <div class="text-center mt-3"
                           @click="showFullDataCollectionTwoDescription=!showFullDataCollectionTwoDescription">
                        <span class="cursor-pointer" v-if="!showFullDataCollectionTwoDescription">
                          <span class="mr-2">
                            {{ $t('system.view_more') }}
                          </span>
                          <font-awesome-icon icon="chevron-down"/>
                        </span>
                        <span class="cursor-pointer" v-if="showFullDataCollectionTwoDescription">
                          <span class="mr-2">
                            {{ $t('system.view_less') }}
                          </span>
                          <font-awesome-icon icon="chevron-up"/>
                        </span>
                      </div>
                    </div>
                    <p v-if="selectedTask.description_two && selectedTask.description_two.length <= 300"
                       class="text-formatted"
                    >
                      {{ selectedTask.description_two }}
                    </p>
                  </div>
                  <div v-if="selectedTask.code != 'system_impact_assessment' && selectedTask.project.project_type == 'type two' && currentView == 'data_collection'">
                    <div  style="margin-top:15px;" v-if="selectedTask.data_collection_description_two && selectedTask.data_collection_description_two.length > 300">
                      <p v-if="showFullDataCollectionTwoDescription" class="text-formatted">
                        {{ selectedTask.data_collection_description_two }}
                      </p>
                      <p v-if="!showFullDataCollectionTwoDescription" class="text-formatted">
                        {{ selectedTask.data_collection_description_two | truncate(300) }}
                      </p>
                      <div class="text-center mt-3"
                           @click="showFullDataCollectionTwoDescription=!showFullDataCollectionTwoDescription">
                        <span class="cursor-pointer" v-if="!showFullDataCollectionTwoDescription">
                          <span class="mr-2">
                            {{ $t('system.view_more') }}
                          </span>
                          <font-awesome-icon icon="chevron-down"/>
                        </span>
                        <span class="cursor-pointer" v-if="showFullDataCollectionTwoDescription">
                          <span class="mr-2">
                            {{ $t('system.view_less') }}
                          </span>
                          <font-awesome-icon icon="chevron-up"/>
                        </span>
                      </div>
                    </div>
                    <p  style="margin-top:15px;" v-if="selectedTask.data_collection_description_two && selectedTask.data_collection_description_two.length <= 300"
                       class="text-formatted"
                    >
                      {{ selectedTask.data_collection_description_two }}
                    </p>
                  </div>
                  <div v-if="getGuide('assessment_task') && currentView == 'default' && selectedTask.code != 'update_measures'"
                       style="margin-top:20px;">
                    <span class="btn btn-secondary btn-xs float-right cursor-pointer" @click="openGuide()">
                      <font-awesome-icon icon="eye"/>
                      {{ $t('administration.guide.modal.button') }}
                    </span>

                    <label qid="create-p-users-label"
                           v-if="selectedTask.project && selectedTask.project.schedule_message">
                      {{ $t('tasks.modal.project_message_label') }}
                    </label>
                    <div class="" v-if="selectedTask.project && selectedTask.project.schedule_message">
                      <p>
                        {{ selectedTask.project.schedule_message }}
                      </p>
                    </div>

                    <label qid="create-p-users-label">
                      {{ $t('system.help_center_label') }}
                    </label>
                    <div v-if="wpGuide && wpGuide.id" class="guide-content-container">
                      <p v-if="!showFullGuideDescription" v-html="wpGuide.excerpt.rendered"></p>
                      <p v-if="showFullGuideDescription" v-html="wpGuide.content.rendered"></p>

                      <div class="text-center mt-3" @click="showFullGuideDescription=!showFullGuideDescription">
                        <span class="cursor-pointer" v-if="!showFullGuideDescription">
                          <span class="mr-2">
                            {{ $t('system.view_more') }}
                          </span>
                          <font-awesome-icon icon="chevron-down"/>
                        </span>
                        <span class="cursor-pointer" v-if="showFullGuideDescription">
                          <span class="mr-2">
                            {{ $t('system.view_less') }}
                          </span>
                          <font-awesome-icon icon="chevron-up"/>
                        </span>
                      </div>
                    </div>
                  </div>

                <!-- Data collection guide -->
                  <div v-if="getGuide('data_collection_task') && currentView == 'data_collection'"
                       style="margin-top:20px;">
                    <span class="btn btn-secondary btn-xs float-right cursor-pointer" @click="openGuide()">
                      <font-awesome-icon icon="eye"/>
                      {{ $t('administration.guide.modal.button') }}
                    </span>

                    <label qid="create-p-users-label"
                           v-if="selectedTask.project && selectedTask.project.schedule_message">
                      {{ $t('tasks.modal.project_message_label') }}
                    </label>
                    <div class="" v-if="selectedTask.project && selectedTask.project.schedule_message">
                      <p>
                        {{ selectedTask.project.schedule_message }}
                      </p>
                    </div>

                    <label qid="create-p-users-label">
                      {{ $t('system.help_center_label') }}
                    </label>
                    <div v-if="wpGuideData && wpGuideData.id" class="guide-content-container">
                      <p v-if="!showFullGuideDescription" v-html="wpGuideData.excerpt.rendered"></p>
                      <p v-if="showFullGuideDescription" v-html="wpGuideData.content.rendered"></p>

                      <div class="text-center mt-3" @click="showFullGuideDescription=!showFullGuideDescription">
                        <span class="cursor-pointer" v-if="!showFullGuideDescription">
                          <span class="mr-2">
                            {{ $t('system.view_more') }}
                          </span>
                          <font-awesome-icon icon="chevron-down"/>
                        </span>
                        <span class="cursor-pointer" v-if="showFullGuideDescription">
                          <span class="mr-2">
                            {{ $t('system.view_less') }}
                          </span>
                          <font-awesome-icon icon="chevron-up"/>
                        </span>
                      </div>
                    </div>
                  </div>

                <!-- System description guide -->
                  <div v-if="getGuide('system_description_task') && currentView == 'default' && selectedTask.code == 'update_measures'"
                       style="margin-top:20px;">
                    <span class="btn btn-secondary btn-xs float-right cursor-pointer" @click="openGuide()">
                      <font-awesome-icon icon="eye"/>
                      {{ $t('administration.guide.modal.button') }}
                    </span>

                    <label qid="create-p-users-label"
                           v-if="selectedTask.project && selectedTask.project.schedule_message">
                      {{ $t('tasks.modal.project_message_label') }}
                    </label>
                    <div class="" v-if="selectedTask.project && selectedTask.project.schedule_message">
                      <p>
                        {{ selectedTask.project.schedule_message }}
                      </p>
                    </div>

                    <label qid="create-p-users-label">
                      {{ $t('system.help_center_label') }}
                    </label>
                    <div v-if="wpGuideSystem && wpGuideSystem.id" class="guide-content-container">
                      <p v-if="!showFullGuideDescription" v-html="wpGuideSystem.excerpt.rendered"></p>
                      <p v-if="showFullGuideDescription" v-html="wpGuideSystem.content.rendered"></p>

                      <div class="text-center mt-3" @click="showFullGuideDescription=!showFullGuideDescription">
                        <span class="cursor-pointer" v-if="!showFullGuideDescription">
                          <span class="mr-2">
                            {{ $t('system.view_more') }}
                          </span>
                          <font-awesome-icon icon="chevron-down"/>
                        </span>
                        <span class="cursor-pointer" v-if="showFullGuideDescription">
                          <span class="mr-2">
                            {{ $t('system.view_less') }}
                          </span>
                          <font-awesome-icon icon="chevron-up"/>
                        </span>
                      </div>
                    </div>
                  </div>

                </div>
                <div class="mt-2 mb-1" v-if="getReportMedia && getReportMedia.length">
                  <p v-for="(file, media_index) in getReportMedia" :key="media_index">
                    <span class="mr-3 float-right text-muted cursor-pointer"
                          @click="downloadMedia(file)"
                          qid="download-media"
                    >
                      <font-awesome-icon icon="cloud-download-alt"/>
                    </span>
                    <a @click="downloadMedia(file)" qid="create-p-media-item">
                      {{ file.file_name }}
                    </a>
                  </p>
                </div>

                <div class="" v-if="getReportLinks && getReportLinks.length">
                  <p v-for="(link, link_index) in getReportLinks" :key="link_index">
                    <a :href="link.url" target="_blank" qid="create-p-link-item">
                      {{ link.name ? link.name : link.url }}
                    </a>
                  </p>
                </div>
              </b-form-group>
            </div>
          </div>
        </div>
      </template>

      <b-alert v-if="error.length"
               show
               variant="danger"
               qid="create-p-error"
      >
        {{ error }}
      </b-alert>

      <b-form qid="create-p-form" @submit.prevent="() => {}" v-if="selectedTask && selectedTask.model && currentView == 'default'">
        <div class="form-container mt-0 mb-2">
          <div class="row mt-1">
            <div class="col-sm-6" v-if="selectedTask.code != 'system_impact_assessment'">

              <!--Assessment tasks-->
              <div v-if="selectedTask && selectedTask.add_response && selectedTask.add_response != 'Optional' && selectedTask.add_response != 'Mandatory' && selectedTask.add_notes == 'Optional' && selectedTask.add_notes == 'Mandatory'">
                <h5>
                  {{ $t('tasks.modal.assessment_task_title') }}
                </h5>
                <hr>
              </div>

              <b-form-group
                      v-if="selectedTask && selectedTask.add_response && selectedTask.add_response == 'Optional' || selectedTask.add_response == 'Mandatory'">
                <label qid="assessment-form-response-label" style="font-size: medium;" class="mt-3">
                  {{ $t('tasks.modal.response_label') }}
                  <span class="text-red" v-if="selectedTask && selectedTask.add_response == 'Mandatory'">*</span>
                </label>

                <span class="btn btn-edit float-right"
                      @click="editModeResponse = !editModeResponse"
                      v-if="!editModeResponse && selectedTask.status != 'completed' && ((selectedTask.response_type != 'Datepicker' && selectedAnswer) || (selectedTask.response_type == 'Datepicker' && selectedDate))"
                >
                  <font-awesome-icon icon="pen"/>
                </span>

                <div v-if="selectedTask.response_type != 'Datepicker'">
                  <multiselect
                          v-if="editModeResponse && selectedTask.status != 'completed'"
                          v-model="selectedAnswer"
                          qid="assessment-form-response-drop-down"
                          :options="allAnswers"
                          :multiple="false"
                          :taggable="false"
                          label="title"
                          :select-label="$t('system.dropdown_select_label')"
                          :selected-label="$t('system.dropdown_selected_label')"
                          :deselect-label="$t('system.dropdown_deselect_label')"
                          :placeholder="$t('tasks.modal.response_placeholder')"
                  ></multiselect>

                  <p v-if="(!editModeResponse && selectedAnswer && selectedAnswer.title)  ||  selectedTask.status == 'completed'" class="">
                    <font-awesome-icon icon="check-circle" class="icon-success"
                                       v-if="getIcon(selectedTask) == 'Positive'"/>
                    <font-awesome-icon icon="times-circle" class="icon-danger"
                                       v-if="getIcon(selectedTask) == 'Negative'"/>
                    <font-awesome-icon icon="info-circle" class="icon-info" v-if="getIcon(selectedTask) == 'Neutral'"/>
                    <font-awesome-icon icon="exclamation-triangle" :class="'risk-1'"
                                       v-if="getIcon(selectedTask) == 'Very low'"/>
                    <font-awesome-icon icon="exclamation-triangle" :class="'risk-3'"
                                       v-if="getIcon(selectedTask) == 'Low'"/>
                    <font-awesome-icon icon="exclamation-triangle" :class="'risk-9'"
                                       v-if="getIcon(selectedTask) == 'High'"/>
                    <font-awesome-icon icon="exclamation-triangle" :class="'risk-16'"
                                       v-if="getIcon(selectedTask) == 'Very high'"/>
                    {{ selectedAnswer.title }}
                  </p>
                </div>

                <div v-if="selectedTask.response_type == 'Datepicker'">
                  <datepicker
                          v-if="editModeResponse"
                          class="date-picker"
                          qid="assessment-form-response-datepicker"
                          calendar-class="right"
                          v-model="selectedDate"
                          :clear-button="true"
                          :placeholder="$t('tasks.modal.response_label')"
                  ></datepicker>

                  <p v-if="!editModeResponse && selectedDate && selectedTask.status != 'completed'" class="">
                    <font-awesome-icon icon="calendar-alt" class="mr-1"/>
                    {{ selectedDate | formatDate }}
                  </p>
                </div>
              </b-form-group>


              <!--Notes-->
              <b-form-group
                      v-if="selectedTask && selectedTask.add_notes && selectedTask.add_notes == 'Optional' || selectedTask.add_notes == 'Mandatory'"
                      class="mb-3">
                <label qid="assessment-form-notes-label" style="font-size: medium;" class="mt-3">
                  {{ $t('tasks.modal.notes_label') }}
                  <span class="text-red" v-if="selectedTask && selectedTask.add_notes == 'Mandatory'">*</span>
                </label>

                <span
                        class="btn btn-edit float-right"
                        @click="editModeNotes = !editModeNotes"
                        v-if="!editModeNotes && taskForm.notes && selectedTask.status != 'completed'">
                  <font-awesome-icon icon="pen"/>
                </span>

                <div v-if="(!taskForm || !taskForm.notes) && !editModeNotes">
                  <font-awesome-icon icon="spinner" size="sm" class="fa-spin"/>
                </div>

                <b-form-textarea
                        qid="assessment-form-notes"
                        v-model="taskForm.notes"
                        v-if="editModeNotes && selectedTask.status != 'completed'"
                        rows="6"
                        max-rows="6"
                        :required="selectedTask.add_notes == 'Mandatory'"
                        :placeholder="$t('tasks.modal.notes_placeholder')"
                ></b-form-textarea>

                <div v-if="!editModeNotes ||  selectedTask.status == 'completed'" style="margin-top: -20px;">
                  <div v-if="taskForm.notes && taskForm.notes.length > 300">
                    <p v-if="showFullTextNotes" class="text-formatted">
                      {{ taskForm.notes }}
                    </p>

                    <p v-if="!showFullTextNotes" class="text-formatted">
                      {{ taskForm.notes | truncate(300) }}
                    </p>

                    <div class="text-center mt-3" @click="showFullTextNotes=!showFullTextNotes">
                      <span class="cursor-pointer" v-if="!showFullTextNotes">
                        <span class="mr-2">
                          {{ $t('system.view_more') }}
                        </span>
                        <font-awesome-icon icon="chevron-down"/>
                      </span>
                      <span class="cursor-pointer" v-if="showFullTextNotes">
                        <span class="mr-2">
                          {{ $t('system.view_less') }}
                        </span>
                        <font-awesome-icon icon="chevron-up"/>
                      </span>
                    </div>
                  </div>

                  <p v-if="taskForm.notes && taskForm.notes.length <= 300"
                     class="text-formatted"
                  >
                    {{ taskForm.notes }}
                  </p>
                </div>
              </b-form-group>

              <!--Audit reporting-->
              <h5>
                {{ $t('tasks.modal.audit_reporting_title') }}
              </h5>

              <hr>

              <!--Control Description-->
              <b-form-group
                      v-if="selectedTask && selectedTask.add_control_description && selectedTask.add_control_description == 'Optional' || selectedTask.add_control_description == 'Mandatory'">
                <label qid="assessment-form-control-description-label" style="font-size: medium;" class="mt-3">
                  {{ $t('tasks.modal.control_description_label') }}
                  <span class="text-red"
                        v-if="selectedTask && selectedTask.add_control_description == 'Mandatory'">*</span>
                </label>

                <span v-if="editModeControlDescription  && selectedTask.status != 'completed'"
                      class="float-right text-success cursor-pointer pt-3"
                      @click="taskForm.control_description = selectedTask.data_remarks"
                >
                  {{ $t('tasks.modal.copy_data_collection_text_label') }}
                </span>

                <span
                        class="btn btn-edit float-right"
                        @click="editModeControlDescription = !editModeControlDescription"
                        v-if="!editModeControlDescription && taskForm.control_description  && selectedTask.status != 'completed'">
                  <font-awesome-icon icon="pen"/>
                </span>

                <div v-if="(!taskForm || !taskForm.control_description) && !editModeControlDescription">
                  <font-awesome-icon icon="spinner" size="sm" class="fa-spin"/>
                </div>

                <b-form-textarea
                        qid="assessment-form-control-description"
                        v-model="taskForm.control_description"
                        v-if="editModeControlDescription && selectedTask.status != 'completed'"
                        rows="6"
                        max-rows="6"
                        :required="selectedTask.add_control_description == 'Mandatory'"
                        :placeholder="$t('tasks.modal.control_description_placeholder')"
                ></b-form-textarea>

                <div v-if="!editModeControlDescription || selectedTask.status == 'completed'" style="margin-top: -20px;">
                  <div v-if="taskForm.control_description && taskForm.control_description.length > 300">
                    <p v-if="showFullTextControlDescription" class="text-formatted">
                      {{ taskForm.control_description }}
                    </p>

                    <p v-if="!showFullTextControlDescription" class="text-formatted">
                      {{ taskForm.control_description | truncate(300) }}
                    </p>

                    <div class="text-center mt-3"
                         @click="showFullTextControlDescription=!showFullTextControlDescription">
                      <span class="cursor-pointer" v-if="!showFullTextControlDescription">
                        <span class="mr-2">
                          {{ $t('system.view_more') }}
                        </span>
                        <font-awesome-icon icon="chevron-down"/>
                      </span>
                      <span class="cursor-pointer" v-if="showFullTextControlDescription">
                        <span class="mr-2">
                          {{ $t('system.view_less') }}
                        </span>
                        <font-awesome-icon icon="chevron-up"/>
                      </span>
                    </div>
                  </div>

                  <p v-if="taskForm.control_description && taskForm.control_description.length <= 300"
                     class="text-formatted"
                  >
                    {{ taskForm.control_description }}
                  </p>
                </div>
              </b-form-group>


              <!--Performed test-->
              <b-form-group
                      v-if="selectedTask && selectedTask.add_performed_text && selectedTask.add_performed_text == 'Optional' || selectedTask.add_performed_text == 'Mandatory'">
                <label qid="assessment-form-performed-test-label" style="font-size: medium;" class="mt-3">
                  {{ $t('tasks.modal.performed_test_label') }}
                  <span class="text-red" v-if="selectedTask && selectedTask.add_performed_text == 'Mandatory'">*</span>
                </label>

                <span
                        class="btn btn-edit float-right"
                        @click="editModePerformedTest = !editModePerformedTest"
                        v-if="!editModePerformedTest && taskForm.performed_text && selectedTask.status != 'completed'">
                  <font-awesome-icon icon="pen"/>
                </span>

                <div v-if="(!taskForm || !taskForm.performed_text) && !editModePerformedTest">
                  <font-awesome-icon icon="spinner" size="sm" class="fa-spin"/>
                </div>

                <b-form-textarea
                        qid="assessment-form-performed-test"
                        v-model="taskForm.performed_text"
                        v-if="editModePerformedTest  && selectedTask.status != 'completed'"
                        rows="6"
                        max-rows="6"
                        :required="selectedTask.add_performed_text == 'Mandatory'"
                        :placeholder="$t('tasks.modal.performed_test_placeholder')"
                ></b-form-textarea>

                <div v-if="!editModePerformedTest ||  selectedTask.status == 'completed'" style="margin-top: -20px;">
                  <div v-if="taskForm.performed_text && taskForm.performed_text.length > 300">
                    <p v-if="showFullTextPerformedTest" class="text-formatted">
                      {{ taskForm.performed_text }}
                    </p>

                    <p v-if="!showFullTextPerformedTest" class="text-formatted">
                      {{ taskForm.performed_text | truncate(300) }}
                    </p>

                    <div class="text-center mt-3" @click="showFullTextPerformedTest=!showFullTextPerformedTest">
                      <span class="cursor-pointer" v-if="!showFullTextPerformedTest">
                        <span class="mr-2">
                          {{ $t('system.view_more') }}
                        </span>
                        <font-awesome-icon icon="chevron-down"/>
                      </span>
                      <span class="cursor-pointer" v-if="showFullTextPerformedTest">
                        <span class="mr-2">
                          {{ $t('system.view_less') }}
                        </span>
                        <font-awesome-icon icon="chevron-up"/>
                      </span>
                    </div>
                  </div>

                  <p v-if="taskForm.performed_text && taskForm.performed_text.length <= 300"
                     class="text-formatted"
                  >
                    {{ taskForm.performed_text }}
                  </p>
                </div>
              </b-form-group>


              <!--Conclusion-->
              <b-form-group
                      v-if="selectedTask && selectedTask.add_description && selectedTask.add_description == 'Optional' || selectedTask.add_description == 'Mandatory'">
                <label qid="assessment-form-conclusion-label" style="font-size: medium;" class="mt-3">
                  {{ $t('tasks.modal.remarks_label') }}
                  <span class="text-red" v-if="selectedTask && selectedTask.add_description == 'Mandatory'">*</span>
                </label>

                <span
                        class="btn btn-edit float-right"
                        @click="editModeConclusion = !editModeConclusion"
                        v-if="!editModeConclusion && taskForm.remarks && selectedTask.status != 'completed'">
                  <font-awesome-icon icon="pen"/>
                </span>

                <div v-if="(!taskForm || !taskForm.remarks) && !editModeConclusion">
                  <font-awesome-icon icon="spinner" size="sm" class="fa-spin"/>
                </div>

                <b-form-textarea
                        qid="assessment-form-conclusion"
                        v-model="taskForm.remarks"
                        v-if="editModeConclusion && selectedTask.status != 'completed'"
                        rows="6"
                        max-rows="6"
                        :placeholder="$t('tasks.modal.remarks_placeholder')"
                ></b-form-textarea>

                <div v-if="!editModeConclusion ||  selectedTask.status == 'completed'" style="margin-top: -20px;">
                  <div v-if="taskForm.remarks && taskForm.remarks.length > 300">
                    <p v-if="showFullTextConclusion" class="text-formatted">
                      {{ taskForm.remarks }}
                    </p>

                    <p v-if="!showFullTextConclusion" class="text-formatted">
                      {{ taskForm.remarks | truncate(300) }}
                    </p>

                    <div class="text-center mt-3" @click="showFullTextConclusion=!showFullTextConclusion">
                      <span class="cursor-pointer" v-if="!showFullTextConclusion">
                        <span class="mr-2">
                          {{ $t('system.view_more') }}
                        </span>
                        <font-awesome-icon icon="chevron-down"/>
                      </span>
                      <span class="cursor-pointer" v-if="showFullTextConclusion">
                        <span class="mr-2">
                          {{ $t('system.view_less') }}
                        </span>
                        <font-awesome-icon icon="chevron-up"/>
                      </span>
                    </div>
                  </div>

                  <p v-if="taskForm.remarks && taskForm.remarks.length <= 300"
                     class="text-formatted"
                  >
                    {{ taskForm.remarks }}
                  </p>
                </div>
              </b-form-group>

              <div class="w-100" @dragover.prevent @drop.prevent="handleDrop">
                <div v-show="showDropActive" class="drop-active">
                  <h3>Drop files to upload</h3>
                </div>
                 <label qid="assessment-form-conclusion-label" style="font-size: medium;" class="mt-3">
                  {{ $t('attachments.label') }}
                </label>
                <span class="float-right">
                    <file-upload
                            v-if="selectedTask.status != 'completed'"
                            ref="uploader"
                            v-model="files"
                            :drop="true"
                            :multiple="true"
                            input-id="task-file"
                            @input="onFileChangeEdit"
                            @dropActive="showDropActiveFunction"
                            @dropInactive="hideDropActiveFunction"
                            class="text-success cursor-pointer float:right"
                    >
                        {{ $t('tasks.modal.add_attachment_label') }}
                      </file-upload>
                </span>
              </div>
              <ul class="list-secondary" style="list-style-type: none; padding-left: 0;">
                <li v-for="(item, index) in getMedia('task')" :key="index" class="text-bold list-item mb-2">
                  <span>
                    <a :href="item.url" target="_blank" class="btn" qid="download-media">
                      {{ item.full_title }}
                    </a>
                  </span>
                  <span class="actions" style="float: right;">
                    <a :href="item.url" target="_blank" class="btn" qid="download-media">
                      <font-awesome-icon icon="eye"/>
                    </a>
                    <span class="btn" @click="downloadMedia(item)" qid="download-media">
                      <font-awesome-icon icon="cloud-download-alt"/>
                    </span>
                    <span class="btn" @click="openDeleteAttachmentModal(item)" qid="remove-media-item" v-if="selectedTask.status != 'completed'">
                      <font-awesome-icon icon="trash-alt"/>
                    </span>
                  </span>
                </li>
                <li v-if="!getMedia('task').length" qid="no-results-row-rr" class="text-center">
                  <h5 class="mt-3">{{ $t('system.no_results') }}</h5>
                </li>
              </ul>

              <div class="w-100">
                 <label qid="assessment-form-conclusion-label" style="font-size: medium;" class="mt-3">
                  {{ $t('projects.observations.title') }}
                </label>
                <span class="float-right">
                    <CreateObservationModal
                            :object="selectedTask.project"
                            :task="selectedTask"
                            type="global"
                            @updated="loadObservations(selectedTask.id)"
                            v-if="isOwner(selectedTask) && currentView != 'data_collection' && selectedTask.code != 'update_measures' && selectedTask.status != 'completed'"
                    ></CreateObservationModal>
                </span>
              </div>
              <ObservationViewModal
                      :object="selectedObservation"
                      :project="selectedTask.project"
                      :showModal="viewObservationModal"
                      @closeViewModal="handleCloseViewModal"
              ></ObservationViewModal>
              <ul class="list-secondary" style="list-style-type: none; padding-left: 0;">
                <li v-for="object in sTableItems" class="text-bold list-item mb-2">
                  <span>
                      <button v-if="object"
                              @click="openViewModal(object)"
                              qid="observation-name-link"
                              class="btn btn-link text-left"
                      >
                        <font-awesome-icon size="lg" icon="binoculars" class="mr-1"/>
                        {{ object.name }}
                      </button>

                  </span>
                  <span class="actions" style="float: right;">
                    <span class="btn"
                          @click="showEditModal(object)"
                          qid="delete-s-cancel-button"
                          v-if="selectedTask.status != 'completed' && (selectedTask.project.status != 'closed' && selectedTask.project.status != 'completed')"
                    >
                      <span>
                        <font-awesome-icon icon="pen"/>
                      </span>
                    </span>
                    <span class="btn"
                          @click="showSConfirmDeleteModal(object)"
                          v-if="selectedTask.status != 'completed' && (selectedTask.project.status != 'closed' && selectedTask.project.status != 'completed')"
                    >
                      <font-awesome-icon icon="trash-alt"/>
                    </span>
                  </span>
                </li>
              </ul>

            </div>

            <div class="col-sm-6" v-if="selectedTask.code != 'system_impact_assessment'">
              <div v-if="selectedTask.data_collection_on">
                <h5>
                  <span class="btn btn-secondary btn-sm float-right"
                        @click="switchCurrentView('data_collection')"
                  >
                    {{ $t('tasks.modal.open_data_collection') }}
                  </span>
                  {{ $t('tasks.modal.data_collection_title') }}
                </h5>

                <hr>

                <div>
                  <div class="mt-3 mb-3">
                    <span v-if="(selectedTask.data_status && selectedTask.data_status === 'completed') || selectedTask.status == 'completed'">
                      <font-awesome-icon icon="check-circle" class="icon-success"/>
                    </span>
                    <span v-if="selectedTask.data_status && selectedTask.data_status === 'pending' && selectedTask.status != 'completed'" class="pr-1">
                      <font-awesome-icon
                              icon="exclamation-triangle"
                              class="risk-6"
                      />
                    </span>
                    <span v-if="selectedTask.status == 'completed'">
                      {{ $t('system.' + $options.filters.slugify(selectedTask.status)) }}
                    </span>
                    <span v-else>
                      <span v-if="selectedTask.data_status">
                        {{ $t('system.' + $options.filters.slugify(selectedTask.data_status)) }}
                      </span>
                      <span v-if="!selectedTask.data_status">
                        <font-awesome-icon
                                icon="exclamation-triangle"
                                class="risk-6"
                        />
                        {{ $t('system.pending') }}
                      </span>
                    </span>

                  </div>

                  <label qid="assessment-form-documentation-collection-control-description-label"
                         style="font-size: medium;" class="mt-3">
                    {{ $t('tasks.modal.control_description_label') }}
                  </label>
                  <div style="margin-top: -20px;" v-if="selectedTask.data_remarks && selectedTask.data_remarks.length">
                    <div v-if="selectedTask.data_remarks && selectedTask.data_remarks.length > 300">
                      <p v-if="showFullDataCollectionDescription" class="text-formatted">
                        {{ selectedTask.data_remarks }}
                      </p>
                      <p v-if="!showFullDataCollectionDescription" class="text-formatted">
                        {{ selectedTask.data_remarks | truncate(300) }}
                      </p>
                      <div class="text-center mt-3"
                           @click="showFullDataCollectionDescription=!showFullDataCollectionDescription">
                        <span class="cursor-pointer" v-if="!showFullDataCollectionDescription">
                          <span class="mr-2">
                            {{ $t('system.view_more') }}
                          </span>
                          <font-awesome-icon icon="chevron-down"/>
                        </span>
                        <span class="cursor-pointer" v-if="showFullDataCollectionDescription">
                          <span class="mr-2">
                            {{ $t('system.view_less') }}
                          </span>
                          <font-awesome-icon icon="chevron-up"/>
                        </span>
                      </div>
                    </div>

                    <p v-if="selectedTask.data_remarks && selectedTask.data_remarks.length <= 300"
                       class="text-formatted"
                    >
                      {{ selectedTask.data_remarks }}
                    </p>
                  </div>
                  <div v-else class="text-muted">
                    {{ $t('system.no_results') }}
                  </div>
                </div>

                <h5 class="mt-4">
                  <span class="btn btn-secondary btn-sm float-right"
                        @click="reopenFormSubmit"
                        v-if="selectedTask.data_status && selectedTask.data_status === 'completed'"
                  >
                    {{ $t('tasks.modal.data_collection_reopen_button') }}
                  </span>
                  {{ $t('tasks.modal.data_collection_chat_title') }}
                </h5>

                <hr>

                <div class="mt-3 mb-3">
                  <div class="row" v-if="selectedTask.data_status != 'completed' && selectedTask.status != 'completed'">
                    <div class="col-sm-10 chat-box-col-text">
                      <b-form-textarea
                              qid="create-s-form-description"
                              v-model="comment"
                              class="chat-box-text"
                              rows="2"
                              max-rows="2"
                              :placeholder="$t('tasks.modal.question_to_recipient_label')"
                      ></b-form-textarea>
                    </div>
                    <div class="col-sm-2 chat-box-col-button cursor-pointer">
                      <div class="chat-box-button" @click="addCommentSubmit">
                        {{ $t('tasks.modal.send_button_label') }}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="mt-3 mb-3">
                  <div class="row">
                    <div class="col-sm-12">
                      <span class="text-bold">
                        {{ $t('tasks.table.data_collection_assignee') }}:
                      </span>
                      <span v-if="selectedTask.data_users && selectedTask.data_users.length">
                        <span class="mr-1 text-normal"
                              v-for="(user, user_index) in selectedTask.data_users"
                              :key="user_index">
                          <span qid="profile-photo">
                            <Avatar :object="user" size="xs"></Avatar>
                          </span>
                        </span>
                      </span>
                      <span v-if="!selectedTask.data_users.length" class="mr-1">
                        <font-awesome-icon icon="exclamation-triangle" class="risk-9 mr-1"/>
                        {{ $t('risk.missing_input')}}
                      </span>
                      <span class="pl-2 cursor-pointer" @click="showReopenDataCollectionModal" v-if="selectedTask.status != 'completed'">
                        <font-awesome-icon icon="pen"/>
                      </span>
                    </div>
                  </div>
                </div>

                <div class="form-control scrollable-height-260 pt-3" style="background-color: white;">
                  <div v-if="previousComments && previousComments.length">
                    <div v-for="(comment, comment_index) in previousComments"
                         class="mt-2 chat-message"
                         :key="comment_index">
                      <p>
                        <span class="cursor-pointer float-right"
                              @click="openDeleteCommentModal(comment)"
                              v-if="isCommentOwner(comment)  && selectedTask.status != 'completed'"
                              qid="view-p-remove-link"
                        >
                          <font-awesome-icon icon="trash-alt"/>
                        </span>
                        <Avatar :object="comment.user" size="xs"></Avatar>
                        <span class="ml-1">
                          {{ comment.user.name }}
                        </span>
                        {{$t('system.on')}}
                        <span class="text-normal">
                          {{ comment.updated_at | formatDateTime }}
                        </span>
                      </p>
                      <div v-if="comment.comment && comment.comment.length > 300">
                        <p v-if="comment.expanded" class="text-formatted">{{ comment.comment }}</p>

                        <p v-if="!comment.expanded" class="text-formatted">{{ comment.comment | truncate(300) }}</p>

                        <div class="text-center mt-3" @click="comment.expanded=!comment.expanded">
                          <span class="cursor-pointer" v-if="!comment.expanded">
                            <span class="mr-2">
                              {{ $t('system.view_more') }}
                            </span>
                            <font-awesome-icon icon="chevron-down"/>
                          </span>
                          <span class="cursor-pointer" v-if="comment.expanded">
                            <span class="mr-2">
                              {{ $t('system.view_less') }}
                            </span>
                            <font-awesome-icon icon="chevron-up"/>
                          </span>
                        </div>
                      </div>

                      <p v-if="comment.comment && comment.comment.length <= 300" class="text-formatted">{{ comment.comment }}</p>
                    </div>
                  </div>
                  <div v-if="!previousComments.length" class="text-center">
                    <h5 class="mt-3">
                      {{ $t('system.no_results') }}
                    </h5>
                  </div>
                </div>

              </div>
            </div>

            <!-- System risk task form -->
            <div class="col-sm-6" v-if="selectedTask.code == 'system_impact_assessment'">
              <b-form-group>
                <label qid="create-s-tags-label">
                  {{ $t('processes.confidentiality.title') }}
                  <span class="text-red">*</span>
                </label>
                <multiselect
                        v-model="selectedConfidentialityImpact"
                        :options="impactItems"
                        :placeholder="$t('processes.risk.impact_placeholder')"
                        label="name"
                        :select-label="$t('system.dropdown_select_label')"
                        :selected-label="$t('system.dropdown_selected_label')"
                        :deselect-label="$t('system.dropdown_deselect_label')"
                        qid="confidentiality-form-impact"
                ></multiselect>
              </b-form-group>
              <b-form-group>
                <label qid="create-s-tags-label">
                  {{ $t('processes.integrity.title') }}
                  <span class="text-red">*</span>
                </label>
                <multiselect
                        v-model="selectedIntegrityImpact"
                        :options="impactItems"
                        :placeholder="$t('processes.risk.impact_placeholder')"
                        label="name"
                        :select-label="$t('system.dropdown_select_label')"
                        :selected-label="$t('system.dropdown_selected_label')"
                        :deselect-label="$t('system.dropdown_deselect_label')"
                        qid="confidentiality-form-impact"
                ></multiselect>
              </b-form-group>
              <b-form-group>
                <label qid="create-s-tags-label">
                  {{ $t('processes.availability.title') }}
                  <span class="text-red">*</span>
                </label>
                <multiselect
                        v-model="selectedAvailabilityImpact"
                        :options="impactItems"
                        :placeholder="$t('processes.risk.impact_placeholder')"
                        label="name"
                        :select-label="$t('system.dropdown_select_label')"
                        :selected-label="$t('system.dropdown_selected_label')"
                        :deselect-label="$t('system.dropdown_deselect_label')"
                        qid="confidentiality-form-impact"
                ></multiselect>
              </b-form-group>
              <b-form-group>
                <label qid="create-p-users-label">
                  {{ $t('tasks.modal.rto_label') }}
                  <span class="text-red">*</span>
                </label>
                <div class="row">
                  <div class="col-sm-6">
                    <b-form-input
                            qid="create-s-form-name"
                            type="number"
                            min="0"
                            :placeholder="$t('projects.schedule.every_placeholder')"
                            v-model="taskForm.rto"
                    ></b-form-input>
                  </div>
                  <div class="col-sm-6">
                    <multiselect
                            v-model="rtoInterval"
                            :options="intervalOptions"
                            :placeholder="$t('projects.schedule.interval_placeholder')"
                            label="text"
                            :select-label="$t('system.dropdown_select_label')"
                            :selected-label="$t('system.dropdown_selected_label')"
                            :deselect-label="$t('system.dropdown_deselect_label')"
                            qid="create-p-form-pg"
                    ></multiselect>
                  </div>
                </div>
              </b-form-group>
              <b-form-group>
                <label qid="create-p-users-label">
                  {{ $t('tasks.modal.rpo_label') }}
                  <span class="text-red">*</span>
                </label>
                <div class="row">
                  <div class="col-sm-6">
                    <b-form-input
                            qid="create-s-form-name"
                            type="number"
                            min="0"
                            :placeholder="$t('projects.schedule.every_placeholder')"
                            v-model="taskForm.rpo"
                    ></b-form-input>
                  </div>
                  <div class="col-sm-6">
                    <multiselect
                            v-model="rpoInterval"
                            :options="intervalOptions"
                            :placeholder="$t('projects.schedule.interval_placeholder')"
                            label="text"
                            :select-label="$t('system.dropdown_select_label')"
                            :selected-label="$t('system.dropdown_selected_label')"
                            :deselect-label="$t('system.dropdown_deselect_label')"
                            qid="create-p-form-pg"
                    ></multiselect>
                  </div>
                </div>
              </b-form-group>
              <b-form-group>
                <label qid="create-p-users-label">
                  {{ $t('tasks.modal.remarks_label') }}
                  <span class="text-red">*</span>
                </label>
                <b-form-textarea
                        qid="create-s-form-description"
                        v-model="taskForm.remarks"
                        rows="6"
                        max-rows="6"
                        :required="selectedTask.add_description == 'Mandatory'"
                        :placeholder="$t('tasks.modal.remarks_placeholder')"
                ></b-form-textarea>
              </b-form-group>
            </div>

          </div>
        </div>
      </b-form>


      <!-- Documentation collection view -->
      <b-form qid="create-p-form" v-if="selectedTask && selectedTask.model && currentView == 'data_collection'">
        <div class="form-container mt-0 mb-2">
          <div class="row mt-1">
            <div class="col-sm-6" v-if="selectedTask.code != 'system_impact_assessment'">
              <h5>
                {{ $t('tasks.modal.data_collection_task_title') }}
              </h5>
              <hr>
              <br>

              <b-form-group
                      v-if="selectedTask && selectedTask.data_collection_add_description && selectedTask.data_collection_add_description == 'Optional' || selectedTask.data_collection_add_description == 'Mandatory'">
                <label qid="assessment-form-documentation-collection-control-description-label"
                       style="font-size: medium;" class="mt-3">
                  {{ $t('tasks.modal.control_description_label') }}
                  <span class="text-red"
                        v-if="selectedTask && selectedTask.data_collection_add_description == 'Mandatory'">*</span>
                </label>

                <span
                        class="btn btn-edit float-right"
                        @click="editModeControlDescriptionDocumentationCollection = !editModeControlDescriptionDocumentationCollection"
                        v-if="!editModeControlDescriptionDocumentationCollection && taskForm.data_remarks">
                  <font-awesome-icon icon="pen"/>
                </span>

                <div v-if="(!taskForm || !taskForm.data_remarks) && !editModeControlDescriptionDocumentationCollection">
                  <font-awesome-icon icon="spinner" size="sm" class="fa-spin"/>
                </div>

                <b-form-textarea
                        qid="assessment-form-documentation-collection-control-description"
                        v-model="taskForm.data_remarks"
                        v-if="editModeControlDescriptionDocumentationCollection && selectedTask.status != 'completed'"
                        rows="6"
                        max-rows="6"
                        :placeholder="$t('tasks.modal.control_description_label')"
                ></b-form-textarea>

                <div v-if="!editModeControlDescriptionDocumentationCollection" style="margin-top: -20px;">
                  <div v-if="taskForm.data_remarks && taskForm.data_remarks.length > 300">
                    <p v-if="showFullTextControlDescriptionDocumentationCollection" class="text-formatted">
                      {{ taskForm.data_remarks }}
                    </p>

                    <p v-if="!showFullTextControlDescriptionDocumentationCollection" class="text-formatted">
                      {{ taskForm.data_remarks | truncate(300) }}
                    </p>

                    <div class="text-center mt-3"
                         @click="showFullTextControlDescriptionDocumentationCollection=!showFullTextControlDescriptionDocumentationCollection">
                      <span class="cursor-pointer" v-if="!showFullTextControlDescriptionDocumentationCollection">
                        <span class="mr-2">
                          {{ $t('system.view_more') }}
                        </span>
                        <font-awesome-icon icon="chevron-down"/>
                      </span>
                      <span class="cursor-pointer" v-if="showFullTextControlDescriptionDocumentationCollection">
                        <span class="mr-2">
                          {{ $t('system.view_less') }}
                        </span>
                        <font-awesome-icon icon="chevron-up"/>
                      </span>
                    </div>
                  </div>

                  <p v-if="taskForm.data_remarks && taskForm.data_remarks.length <= 300"
                     class="text-formatted"
                  >
                    {{ taskForm.data_remarks }}
                  </p>
                </div>
              </b-form-group>


              <div class="w-100" @dragover.prevent @drop.prevent="handleDropDocumentation">
                <div v-show="showDropActive" class="drop-active">
                  <h3>Drop files to upload</h3>
                </div>

                 <label qid="assessment-form-conclusion-label" style="font-size: medium;" class="mt-3">
                  {{ $t('attachments.label') }}
                </label>
                <span class="float-right">
                    <file-upload
                            ref="uploader"
                            v-model="data_collection_files"
                            :drop="true"
                            :multiple="true"
                            input-id="task-file"
                            @input="onDataFileChange"
                            @dropActive="showDropActiveFunction"
                            @dropInactive="hideDropActiveFunction"
                            v-if="selectedTask.status != 'completed'"
                            class="text-success cursor-pointer float:right"
                    >
                        {{ $t('tasks.modal.add_attachment_label') }}
                      </file-upload>
                </span>
              </div>
              <ul class="list-secondary" style="list-style-type: none; padding-left: 0;">
                <li v-for="(item, index) in getMedia('data_documents')" :key="index" class="text-bold list-item mb-2">
                  <span>
                    <a :href="item.url" target="_blank" class="btn" qid="download-media">
                      {{ item.full_title }}
                    </a>
                  </span>
                  <span class="actions" style="float: right;">
                    <a :href="item.url" target="_blank" class="btn" qid="download-media">
                      <font-awesome-icon icon="eye"/>
                    </a>
                    <span class="btn" @click="downloadMedia(item)" qid="download-media">
                      <font-awesome-icon icon="cloud-download-alt"/>
                    </span>
                    <span class="btn" @click="openDeleteAttachmentModal(item)" qid="remove-media-item">
                      <font-awesome-icon icon="trash-alt"/>
                    </span>
                  </span>
                </li>
                <li v-if="!getMedia('data_documents').length" qid="no-results-row-rr" class="text-center">
                  <h5 class="mt-3">{{ $t('system.no_results') }}</h5>
                </li>
              </ul>

              <!--</b-form-group>-->
            </div>
            <div class="col-sm-6" v-if="selectedTask.code != 'system_impact_assessment'">

              <h5>
                {{ $t('tasks.modal.data_collection_chat_title') }}
              </h5>
              <hr>
              <br>

              <div class="mt-3 mb-3" v-if="selectedTask.status != 'completed'">
                <div class="row">
                  <div class="col-sm-10 chat-box-col-text">
                    <b-form-textarea
                            qid="create-s-form-description"
                            v-model="comment"
                            class="chat-box-text"
                            rows="2"
                            max-rows="2"
                            :placeholder="$t('tasks.modal.question_to_recipient_label')"
                    ></b-form-textarea>
                  </div>
                  <div class="col-sm-2 chat-box-col-button cursor-pointer">
                    <div class="chat-box-button" @click="addCommentSubmit">
                      {{ $t('tasks.modal.send_button_label') }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-control scrollable-height-260 pt-3" style="background-color: white;">
                <div v-if="previousComments && previousComments.length">
                  <div v-for="(comment, comment_index) in previousComments"
                       :key="comment_index"
                       class="mt-2 chat-message">
                    <p>
                      <span class="cursor-pointer float-right"
                            @click="openDeleteCommentModal(comment)"
                            v-if="isCommentOwner(comment) && selectedTask.status != 'completed'"
                            qid="view-p-remove-link"
                      >
                        <font-awesome-icon icon="trash-alt"/>
                      </span>
                      <Avatar :object="comment.user" size="xs"></Avatar>
                      <span class="ml-1">
                        {{ comment.user.name }}
                      </span>
                      {{$t('system.on')}}
                      <span class="text-normal">
                        {{ comment.updated_at | formatDateTime }}
                      </span>
                    </p>
                    <div v-if="comment.comment && comment.comment.length > 300">
                      <p v-if="comment.expanded" class="text-formatted">{{ comment.comment }}</p>

                      <p v-if="!comment.expanded" class="text-formatted">{{ comment.comment | truncate(300) }}</p>

                      <div class="text-center mt-3" @click="comment.expanded=!comment.expanded">
                        <span class="cursor-pointer" v-if="!comment.expanded">
                          <span class="mr-2">
                            {{ $t('system.view_more') }}
                          </span>
                          <font-awesome-icon icon="chevron-down"/>
                        </span>
                        <span class="cursor-pointer" v-if="comment.expanded">
                          <span class="mr-2">
                            {{ $t('system.view_less') }}
                          </span>
                          <font-awesome-icon icon="chevron-up"/>
                        </span>
                      </div>
                    </div>

                    <p v-if="comment.comment && comment.comment.length <= 300" class="text-formatted">{{ comment.comment }}</p>
                  </div>
                </div>
                <div v-if="!previousComments.length" class="text-center">
                  <h5 class="mt-3">
                    {{ $t('system.no_results') }}
                  </h5>
                </div>
              </div>
            </div>

          </div>
        </div>
      </b-form>

      <template #modal-footer="{ close }">
        <div class="" style="width: 100%;">
          <div class="task-modal-info" v-if="selectedTask && selectedTask.id">
            <div class="row">
              <div class="col text-center pt-3 pb-3" v-if="currentView != 'data_collection'">
                <div class="mb-3">
                  <strong>{{ $t('projects.create.task_assignees') }}</strong>
                </div>

                <span v-if="selectedTask.users && selectedTask.users.length">
                  <span class="mr-1 text-normal"
                        v-for="(user, user_index) in getReducedUsers(selectedTask.users)"
                        :key="user_index">
                    <span qid="profile-photo">
                      <Avatar :object="user" size="xs"></Avatar>
                    </span>
                  </span>
                </span>
                <span v-if="selectedTask.users && selectedTask.users.length && selectedTask.users.length > 3"
                      class="badge cursor-pointer"
                      @click="openUsersModal(selectedTask)"
                >
                  <font-awesome-icon icon="users"/>
                  + {{ selectedTask.users.length - 3 }}
                </span>
              </div>
              <div class="col text-center pt-3 pb-3" v-if="currentView == 'data_collection'">
                <div class="mb-3">
                  <strong>{{ $t('projects.create.task_assignees') }}</strong>
                </div>
                <span v-if="selectedTask.data_users && selectedTask.data_users.length">
                <span class="mr-1 text-normal"
                      v-for="(user, user_index) in getReducedUsers(selectedTask.data_users)"
                      :key="user_index">
                  <span qid="profile-photo">
                    <Avatar :object="user" size="xs"></Avatar>
                  </span>
                </span>
                </span>
                <span v-if="selectedTask.data_users && selectedTask.data_users.length && selectedTask.data_users.length > 3"
                      class="badge cursor-pointer"
                      @click="openUsersModal(selectedTask)"
                >
                  <font-awesome-icon icon="users"/>
                  + {{ selectedTask.data_users.length - 3 }}
                </span>
              </div>
              <div class="col text-center pt-3 pb-3">
                <div class="mb-3">
                  <strong>{{ $t('table.due_date') }}</strong>
                </div>
                <span class="text-normal" v-if="currentView != 'data_collection'">
                  <span v-if="getDifference(selectedTask.project.end_date) > 3">
                    <font-awesome-icon icon="exclamation-triangle" class="risk-1"/>
                    {{ $t('system.due_in') }}
                    {{ getDifference(selectedTask.project.end_date) }}
                    {{ $t('system.days') }}
                  </span>
                  <span v-if="getDifference(selectedTask.project.end_date) <= 3 && getDifference(selectedTask.project.end_date) >= 0">
                    <font-awesome-icon icon="exclamation-triangle" class="risk-6"/>
                    {{ $t('system.due_in') }}
                    {{ getDifference(selectedTask.project.end_date) }}
                    {{ $t('system.days') }}
                  </span>
                  <span v-if="getDifference(selectedTask.project.end_date) < 0">
                    <font-awesome-icon icon="exclamation-triangle" class="risk-12"/>
                    {{ Math.abs(getDifference(selectedTask.project.end_date)) }}
                    {{ $t('system.days') }}
                    {{ $t('system.overdue') }}
                  </span>
                </span>
                <span class="text-normal" v-if="currentView == 'data_collection'">
                  <span v-if="getDifference(selectedTask.project.data_collection_end_date) > 3">
                    <font-awesome-icon icon="exclamation-triangle" class="risk-1"/>
                    {{ $t('system.due_in') }}
                    {{ getDifference(selectedTask.project.data_collection_end_date) }}
                    {{ $t('system.days') }}
                  </span>
                  <span v-if="getDifference(selectedTask.project.data_collection_end_date) <= 3 && getDifference(selectedTask.project.data_collection_end_date) >= 0">
                    <font-awesome-icon icon="exclamation-triangle" class="risk-6"/>
                    {{ $t('system.due_in') }}
                    {{ getDifference(selectedTask.project.data_collection_end_date) }}
                    {{ $t('system.days') }}
                  </span>
                  <span v-if="getDifference(selectedTask.project.data_collection_end_date) < 0">
                    <font-awesome-icon icon="exclamation-triangle" class="risk-12"/>
                    {{ Math.abs(getDifference(selectedTask.project.data_collection_end_date)) }}
                    {{ $t('system.days') }}
                    {{ $t('system.overdue') }}
                  </span>
                  <span v-if="!selectedTask.project.data_collection_end_date">
                    {{ $t('system.n_a') }}
                  </span>
                </span>
              </div>
              <div class="col text-center pt-3 pb-3" v-if="currentView != 'data_collection'">
                <div class="mb-3">
                  <strong>{{ $t('system.task_status') }}</strong>
                </div>
                <span v-if="selectedTask.status === 'completed'">
                  <span class="text-normal" v-if="selectedTask.qa_status === 'accepted'">
                    <font-awesome-icon icon="check-circle" class="icon-success"/>
                  </span>
                  <span class="text-normal" v-else-if="selectedTask.qa_status === 'rejected'">
                    <font-awesome-icon icon="times-circle" class="icon-danger"/>
                  </span>
                  <span class="text-normal" v-else>
                    <font-awesome-icon icon="check-circle" class="icon-success"/>
                  </span>

                  </span>
                <span v-if="selectedTask.status === 'pending'" class="pr-1">
                  <font-awesome-icon
                          icon="exclamation-triangle"
                          class="risk-6"
                  />
                </span>
                <span class="text-normal"
                      v-if="selectedTask.status === 'completed' && selectedTask.qa_status === 'accepted'">
                  {{ $t('system.accepted') }}
                </span>
                <span class="text-normal"
                      v-else-if="selectedTask.status === 'completed' && selectedTask.qa_status === 'rejected'">
                  {{ $t('system.rejected') }}
                </span>
                <span class="text-normal" v-else>
                  {{ $t('system.' + $options.filters.slugify(selectedTask.status)) }}
                </span>
              </div>
              <div class="col text-center pt-3 pb-3" v-if="currentView == 'data_collection'">
                <div class="mb-3">
                  <strong>{{ $t('tasks.table.data_collection_status') }}</strong>
                </div>
                <span v-if="selectedTask.data_status && selectedTask.data_status === 'completed'">
                  <font-awesome-icon icon="check-circle" class="icon-success"/>
                </span>
                <span v-if="selectedTask.data_status && selectedTask.data_status === 'pending'" class="pr-1">
                  <font-awesome-icon
                          icon="exclamation-triangle"
                          class="risk-6"
                  />
                </span>
                <span v-if="selectedTask.data_status">
                  {{ $t('system.' + $options.filters.slugify(selectedTask.data_status)) }}
                </span>
                <span v-if="!selectedTask.data_status">
                  <font-awesome-icon
                          icon="exclamation-triangle"
                          class="risk-6"
                  />
                  {{ $t('system.pending') }}
                </span>
              </div>
              <div class="col text-center pt-3 pb-3">
                <div class="mb-3">
                  <strong>{{ $t('table.project') }}</strong>
                </div>
                <span v-if="isOwner(selectedTask.project)" class="text-success breadcrumb-item cursor-pointer"
                      @click="navigateToProject(selectedTask)">
                  <font-awesome-icon icon="calendar-alt" class="mr-1"/>
                  {{ selectedTask.project.title }}
                </span>
                <span v-if="!isOwner(selectedTask.project)" class="text-success breadcrumb-item"
                      style="pointer-events:none;">
                  <font-awesome-icon icon="calendar-alt" class="mr-1"/>
                  {{ selectedTask.project.title }}
                </span>
              </div>
              <div class="col text-center pt-3 pb-3">
                <div class="mb-3">
                  <strong>{{ $t('projects.create.owners_label') }}</strong>
                </div>
                <span class="mr-1 text-normal"
                      v-for="(user, user_index) in getReducedUsers(selectedTask.project.users)"
                      :key="user_index">
                  <span qid="profile-photo">
                    <Avatar :object="user" size="xs"></Avatar>
                  </span>
                </span>
                <span v-if="selectedTask.project.users.length > 3"
                      class="badge cursor-pointer"
                      @click="openUsersModal(selectedTask.project)"
                >
                  <font-awesome-icon icon="users"/>
                  + {{ selectedTask.project.users.length - 3 }}
                </span>
              </div>
            </div>
          </div>
          <div class="alert alert-menu-color color-secondary mb-0" role="alert">
            <div class="row">
              <div class="col-sm-6">
                <!--<span>-->
                  <!--<vm-progress-->
                          <!--type="circle"-->
                          <!--:percentage="project_progress"-->
                          <!--style="vertical-align: middle;"-->
                          <!--:width="50"-->
                          <!--:stroke-width="2"-->
                          <!--stroke-color="#00c29e"-->
                  <!--&gt;-->
                    <!--{{project_progress}}%-->
                  <!--</vm-progress>-->
                <!--</span>-->
                <!--<CreateObservationModal-->
                        <!--:object="selectedTask.project"-->
                        <!--:task="selectedTask"-->
                        <!--type="secondary"-->
                        <!--v-if="isOwner(selectedTask) && currentView != 'data_collection' && selectedTask.code != 'update_measures'"-->
                <!--&gt;</CreateObservationModal>-->
              </div>
              <div class="col-sm-6 text-right">



                <!--Completed task with QA-->
                <b-button class="ml-3 mt-2"
                          variant="link"
                          v-if="selectedTask.qa_status != '' && selectedTask.code != 'system_impact_assessment' && currentView != 'data_collection'"
                          @click="showQaLog"
                          :disabled="buttonDisabled"
                          qid="task-modal-save_without_complete-button"
                >
                {{ $t('system.quality_control_log') }}
              </b-button>

                <b-modal ref="qa-log" modal-class="task-modal">
                  <template #modal-header="{ close }">
                    <div class="" style="width: 100%;">
                      <span @click="hideQaLog" class="btn btn-secondary cursor-pointer float-right ml-2 mr-0">
                        <font-awesome-icon icon="times"/>
                      </span>
                      <h5 class="mb-1 text-center" qid="update-user-title">
                        {{ $t('system.quality_control_log') }}
                      </h5>
                    </div>
                  </template>
                  <div class="pt-3">
                    <div v-if="qaComments && qaComments.length">
                      <div v-for="(comment, comment_index) in qaComments"
                           :key="comment_index"
                           class="mb-4">
                        <p>
                          <span v-if="comment.type == 'accepted'"><font-awesome-icon icon="check-circle"
                                                                                     class="icon-success"/> {{ $t('system.accepted') }}</span>
                          <span v-if="comment.type == 'rejected'"><font-awesome-icon icon="times-circle"
                                                                                     class="icon-danger"/> {{ $t('system.rejected') }}</span>
                          {{$t('system.by')}}
                          <span class="">
                            {{ comment.user.name }}
                          </span>
                          {{$t('system.on')}}
                          <span class="text-normal">
                            {{ comment.updated_at | formatDateTime }}
                          </span>
                        </p>
                        <div v-if="comment.comment && comment.comment != 'accepted' && comment.comment.length > 0"
                             class="text-formatted chat-message pb-3">{{ comment.comment }}</div>
                      </div>
                    </div>
                  </div>
                  <template #modal-footer="{ close }">
                    <div class="" style="width: 100%;">
                      <div class="alert alert-menu-color color-secondary mb-0" role="alert">
                        <div class="text-right">
                          <b-button class=""
                                    variant="secondary"
                                    @click="hideQaLog"
                                    qid="edit-p-cancel-button"
                          >
                            {{ $t('system.close') }}
                          </b-button>
                        </div>
                      </div>
                    </div>
                  </template>
                </b-modal>

              <b-button class="ml-3 mt-2"
                        variant="danger"
                        v-if="selectedTask.status == 'completed' && selectedTask.qa_status != 'rejected' && selectedTask.code != 'system_impact_assessment' && currentView != 'data_collection'"
                        @click="showReopenQaTask"
                        :disabled="buttonDisabled"
                        qid="task-modal-save_without_complete-button"
              >
                {{ $t('system.reject_and_reopen') }}
              </b-button>
                <b-modal ref="reopen-qa-task" modal-class="task-modal">
                  <template #modal-header="{ close }">
                    <div class="" style="width: 100%;">
                      <span @click="hideReopenQaTask" class="btn btn-secondary cursor-pointer float-right ml-2 mr-0">
                        <font-awesome-icon icon="times"/>
                      </span>
                      <h5 class="mb-1 text-center" qid="update-user-title">
                        {{ $t('system.reject_and_reopen') }}
                      </h5>
                    </div>
                  </template>
                  <b-form @submit="reOpenQaTask" qid="create-framework-control-form">
                    <div class="form-container">
                      <b-form-group>
                        <label qid="create-framewor-control-pg-label">
                          {{ $t('system.rejection_comment') }}
                        </label>
                      </b-form-group>
                      <b-form-textarea
                              qid="create-q-data_collection-form-description"
                              v-model="qa_comment"
                              rows="6"
                              max-rows="6"
                              required
                      ></b-form-textarea>
                    </div>
                  </b-form>
                  <template #modal-footer="{ close }">
                    <div class="" style="width: 100%;">
                      <div class="alert alert-menu-color color-secondary mb-0" role="alert">
                        <div class="text-right">
                          <b-button class=""
                                    variant="secondary"
                                    @click="hideReopenQaTask"
                                    qid="edit-p-cancel-button"
                          >
                            {{ $t('maintenance.measures.create.cancel') }}
                          </b-button>
                          <b-button
                                  class="ml-3"
                                  variant="danger"
                                  :disabled="buttonDisabled"
                                  @click="reOpenQaTask()"
                                  qid="edit-p-submit-button"
                          >
                            <span v-if="buttonDisabled" class="mr-1">
                              <font-awesome-icon icon="spinner" size="sm" class="fa-spin"/>
                            </span>
                            {{ $t('system.reject_and_reopen') }}
                          </b-button>
                        </div>
                      </div>
                    </div>
                  </template>
                </b-modal>
              <b-button class="ml-3 mt-2"
                        variant="success"
                        v-if="selectedTask.status == 'completed' && selectedTask.qa_status != 'accepted'  && selectedTask.code != 'system_impact_assessment' && currentView != 'data_collection'"
                        @click="acceptQaTask"
                        :disabled="buttonDisabled"
                        qid="task-modal-save_without_complete-button"
              >
                {{ $t('system.accept_and_complete') }}
              </b-button>


              <b-button class="ml-3 mt-2"
                        variant="secondary"
                        v-if="selectedTask.status != 'completed' && selectedTask.code != 'system_impact_assessment' && currentView != 'data_collection'"
                        @click="saveTask()"
                        :disabled="buttonDisabled"
                        qid="task-modal-save_without_complete-button"
              >
                {{ $t('system.save_without_complete') }}
              </b-button>
                <b-button class="ml-3 mt-2"
                          variant="success"
                          @click="completeTask()"
                          :disabled="buttonDisabled"
                          qid="task-modal-complete_task-button"
                          v-if="selectedTask.status !== 'completed' && selectedTask.code !== 'system_impact_assessment' && currentView !== 'data_collection'"
                >
                  {{ $t('tasks.modal.complete') }}
                </b-button>

                <!--<b-button class="ml-3 mt-2"-->
                <!--variant="secondary"-->
                <!--v-if="selectedTask.status === 'completed' && selectedTask.code !== 'system_impact_assessment' && currentView !== 'data_collection'"-->
                <!--@click="reOpenTask()"-->
                <!--:disabled="buttonDisabled"-->
                <!--qid="task-modal-cancel-button"-->
                <!--&gt;-->
                <!--{{ $t('tasks.modal.task_reopen_button') }}-->
                <!--</b-button>-->
                <!--<b-button class="ml-3 mt-2"-->
                <!--variant="success"-->
                <!--v-if="selectedTask.status === 'completed' && selectedTask.code !== 'system_impact_assessment' && currentView !== 'data_collection'"-->
                <!--@click="saveTask()"-->
                <!--:disabled="buttonDisabled"-->
                <!--qid="task-modal-save-button"-->
                <!--&gt;-->
                <!--{{ $t('system.save') }}-->
                <!--</b-button>-->


                <b-button class="ml-3 mt-2"
                          variant="secondary"
                          v-if="selectedTask.status !== 'completed' && selectedTask.code !== 'system_impact_assessment' && currentView === 'data_collection'"
                          @click="saveDataTask()"
                          :disabled="buttonDisabled"
                          qid="task-modal-save_without_complete-button"
                >
                  {{ $t('system.save_without_complete') }}
                </b-button>
                <b-button class="ml-3 mt-2"
                          variant="success"
                          @click="completeDataTask()"
                          :disabled="buttonDisabled"
                          qid="task-modal-complete_data_task-button"
                          v-if="selectedTask.data_status !== 'completed' && selectedTask.code !== 'system_impact_assessment' && currentView === 'data_collection'"
                >
                  {{ $t('tasks.modal.complete') }}
                </b-button>

                <b-button class="ml-3 mt-2"
                          variant="secondary"
                          v-if="selectedTask.data_status === 'completed' && selectedTask.code !== 'system_impact_assessment' && currentView === 'data_collection'"
                          @click="reOpenDataTask()"
                          :disabled="buttonDisabled"
                          qid="task-modal-cancel-button"
                >
                  {{ $t('tasks.modal.data_collection_reopen_button') }}
                </b-button>
                <b-button class="ml-3 mt-2"
                          variant="success"
                          v-if="selectedTask.status === 'completed' && selectedTask.code !== 'system_impact_assessment' && currentView === 'data_collection'"
                          @click="saveTask()"
                          :disabled="buttonDisabled"
                          qid="task-modal-save-button"
                >
                  {{ $t('system.save') }}
                </b-button>


                <b-button class="ml-3 mt-2"
                          variant="secondary"
                          v-if="selectedTask.status != 'completed' && selectedTask.code == 'system_impact_assessment'"
                          @click="saveTask()"
                          :disabled="buttonDisabled"
                          qid="task-modal-save_without_complete-button"
                >
                  {{ $t('system.save_without_complete') }}
                </b-button>
                <b-button class="ml-3 mt-2"
                          variant="success"
                          v-if="selectedTask.status != 'completed' && selectedTask.code == 'system_impact_assessment'"
                          @click="completeSystemRiskTask()"
                          :disabled="buttonDisabled"
                          qid="task-modal-complete_system_risk-button"
                >
                  {{ $t('tasks.modal.complete') }}
                </b-button>

                <b-button class="ml-3 mt-2"
                          variant="secondary"
                          v-if="selectedTask.status == 'completed' && selectedTask.code == 'system_impact_assessment'"
                          @click="reOpenTask()"
                          :disabled="buttonDisabled"
                          qid="task-modal-cancel-button"
                >
                  {{ $t('tasks.modal.task_reopen_button') }}
                </b-button>
                <b-button class="ml-3 mt-2"
                          variant="success"
                          v-if="selectedTask.status == 'completed' && selectedTask.code == 'system_impact_assessment'"
                          @click="saveTask()"
                          :disabled="buttonDisabled"
                          qid="task-modal-save-button"
                >
                  {{ $t('system.save') }}
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </template>
    </b-modal>


    <!-- Add new link modal -->
    <b-modal ref="add-link"
             @hide="hideCreateLinkModal"
             modal-class="task-modal"
             hide-footer>
      <template #modal-header="{ close }">
        <div class="" style="width: 100%;">
          <span @click="hideCreateLinkModal" class="btn btn-secondary cursor-pointer float-right ml-2 mr-0">
            <font-awesome-icon icon="times"/>
          </span>
          <h5 class="mb-1 text-center" qid="update-user-title">
            {{ $t('tasks.modal.add_links_button') }}
          </h5>
        </div>
      </template>

      <b-alert v-if="error.length"
               show
               variant="danger"
               qid="edit-user-error"
      >
        {{ error }}
      </b-alert>

      <div class="form-container">
        <b-form-group>
          <div>
            <label class="mt-2">{{ $t('system.link') }}</label>
            <b-form-input
                    type="text"
                    aria-describedby="inputGroup"
                    v-model="inputLink"
                    :placeholder="$t('maintenance.third_parties.create.links_placeholder')"
                    qid="create-p-links"
            ></b-form-input>
            <label class="mt-2">{{ $t('table.name') }}</label>
            <b-form-input
                    type="text"
                    aria-describedby="inputGroup"
                    v-model="inputLinkName"
                    :placeholder="inputLink ? inputLink : $t('table.name')"
                    qid="create-p-links"
            ></b-form-input>
          </div>
        </b-form-group>
      </div>
      <div class="proxy-footer">
        <div class="alert alert-menu-color color-secondary mb-0" role="alert">
          <div class="row">
            <div class="col-sm-6">

            </div>
            <div class="col-sm-6">
              <div class="text-right">
                <b-button class=""
                          variant="secondary"
                          @click="hideCreateLinkModal"
                          qid="edit-user-cancel-button"
                >
                  {{ $t('administration.users.create.cancel') }}
                </b-button>
                <b-button @click="handleLink"
                          class="ml-3"
                          variant="success"
                          :disabled="buttonDisabled"
                          qid="edit-user-submit-button"
                >
                  <span v-if="buttonDisabled" class="mr-1">
                    <font-awesome-icon icon="spinner" size="sm" class="fa-spin"/>
                  </span>
                  {{ $t('maintenance.users.merge_users_submit') }}
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>


    <!-- Change user modal template -->
    <b-modal ref="reopen-task" modal-class="task-modal">
      <template #modal-header="{ close }">
        <div class="" style="width: 100%;">
          <span @click="hideReopenDataCollectionModal" class="btn btn-secondary cursor-pointer float-right">
            <font-awesome-icon icon="times"/>
          </span>
          <h5 class="mb-1 text-center">
            {{ $t('tasks.table.data_collection_assignee') }}
          </h5>
        </div>
      </template>

      <div class="form-container">

        <b-alert v-if="reopenError.length"
                 show
                 variant="danger"
                 qid="create-p-error"
        >
          {{ reopenError }}
        </b-alert>

        <b-form-group>
          <label qid="create-p-users-label">
            {{ $t('tasks.modal.recipient_label') }}
          </label>

          <div class="multiselect-row">
            <multiselect
                    v-model="selectedAssignUsers"
                    :options="userList"
                    :multiple="true"
                    :taggable="false"
                    :disabled="buttonDisabled"
                    track-by="slug"
                    :select-label="$t('system.dropdown_select_label')"
                    :selected-label="$t('system.dropdown_selected_label')"
                    :deselect-label="$t('system.dropdown_deselect_label')"
                    :placeholder="$t('tasks.modal.recipient_label')"
                    label="name"
                    qid="create-p-users-select"
            >
              <template slot="tag" slot-scope="props">
                <span class="mr-3 user-tag">
                  <Avatar :object="props.option" size="xs"></Avatar>
                  <span class="ml-2">{{ props.option.name }}</span>
                </span>
              </template>
              <template slot="option" slot-scope="props">
                <Avatar :object="props.option" size="xs"></Avatar>
                <span class="ml-2">
                  {{ props.option.name }}
                </span>
              </template>
            </multiselect>

            <UserInviteModal
                    :buttonType="'small'"
                    :buttonDisabled=buttonDisabled
                    @reloadUserList="reloadUserList"
            ></UserInviteModal>
          </div>
        </b-form-group>
      </div>

      <template #modal-footer="{ close }">
        <div class="" style="width: 100%;">
          <div class="alert alert-menu-color color-secondary mb-0" role="alert">
            <div class="text-right">
              <b-button class=""
                        variant="secondary"
                        @click="hideReopenDataCollectionModal"
                        qid="edit-p-cancel-button"
              >
                {{ $t('maintenance.measures.create.cancel') }}
              </b-button>
              <b-button
                      class="ml-3"
                      variant="success"
                      :disabled="buttonDisabled"
                      @click="changeUsersSubmit()"
                      qid="edit-p-submit-button"
              >
                <span v-if="buttonDisabled" class="mr-1">
                  <font-awesome-icon icon="spinner" size="sm" class="fa-spin"/>
                </span>
                {{ $t('maintenance.measure_categories.create.submit') }}
              </b-button>
            </div>
          </div>
        </div>
      </template>
    </b-modal>


    <!-- Edit Assessment Control modals -->
    <b-modal ref="edit-question-1"
             @hide="hideEditQModal"
             modal-class="task-modal"
             hide-footer>
      <template #modal-header="{ close }">
        <div class="" style="width: 100%;">
          <span @click="hideEditQModal" class="btn btn-secondary cursor-pointer float-right ml-2 mr-0">
            <font-awesome-icon icon="times"/>
          </span>
          <h5 class="mb-1 text-center" qid="create-q-title">
            {{ $t('maintenance.questions.update.title') }}
          </h5>
        </div>
      </template>

      <b-alert v-if="error.length"
               show
               variant="danger"
               qid="create-q-error"
      >
        {{ error }}
      </b-alert>

      <div class="form-container">
        <div class="mb-3">
          <h5 class="mb-2">
            {{ $t('maintenance.questions.create.question_title') }}
          </h5>
          <span class="text-muted">
            {{ $t('maintenance.questions.create.question_description') }}
          </span>
        </div>

        <b-form-group>
          <label qid="create-q-name-label">
            {{ $t('maintenance.questions.create.name_label') }}
            <span class="text-red">*</span>
          </label>
          <b-form-input
                  qid="create-q-form-name"
                  type="text"
                  v-model="form.name"
                  required
                  :maxLength="255"
                  :placeholder="$t('maintenance.questions.create.name_placeholder')"
          ></b-form-input>
        </b-form-group>

        <b-form-group>
          <label qid="create-q-reference-label">
            {{ $t('maintenance.questions.create.reference_label') }}
          </label>
          <b-form-input
                  qid="create-q-form-reference"
                  type="text"
                  v-model="form.reference"
                  :maxLength="255"
                  :class="{'is-invalid': referenceInvalid}"
                  :placeholder="$t('maintenance.questions.create.reference_placeholder')"
          ></b-form-input>
          <b-form-invalid-feedback
                  v-if="referenceInvalid"
                  qid="pasword-requirement-notice"
                  class="d-block"
          >
            {{ $t('maintenance.questions.create.reference_error') }}
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group>
          <label qid="create-assessment-control-objective-label">
            {{ $t('maintenance.control_objective.title') }}<span class="mandatory"> *</span>
          </label>
          <div class="multiselect-row">
            <multiselect
                    v-model="selectedControlObjective"
                    :options="controlObjectives"
                    :placeholder="$t('maintenance.control_objective.title_placeholder')"
                    label="name"
                    :select-label="$t('system.dropdown_select_label')"
                    :selected-label="$t('system.dropdown_selected_label')"
                    :deselect-label="$t('system.dropdown_deselect_label')"
                    required
                    :allow-empty="false"
                    @search-change="loadCO"
                    track-by="id"
                    qid="create-assessment-control-form-control-objective"
            >
              <template slot="singleLabel" slot-scope="props">
                <font-awesome-icon icon="book"/>
                <span class="ml-2">
                  {{ props.option.name }}
                </span>
              </template>
              <template slot="option" slot-scope="props">
                <font-awesome-icon icon="book"/>
                <span class="ml-2">
                  {{ props.option.name }}
                </span>
              </template>
            </multiselect>
            <!-- <b-button
                class="btn btn-secondary float-right"
                @click="showCreateControlObjectiveModal()"
                qid="control-objective-create-button-fc-modal"
                v-if="isGranted(permissions.CONTROL_OBJECTIVE_CREATE) && (!getClient.parent || (getClient.parent && object.partition))"
                v-b-tooltip.hover
                :title="$t('maintenance.control_objective.add_new')"
            >
              <font-awesome-icon icon="plus-circle"/>
            </b-button> -->
          </div>
          <p v-if="form.control_objective && form.control_objective.name">
            <font-awesome-icon icon="book"/>
            {{ form.control_objective.name }}
          </p>
        </b-form-group>
      </div>
      <div class="proxy-footer">
        <div class="progress-indicator-footer col text-center pt-2 pb-1">
          <ul class="progress-indicator pr-5 pl-5 mb-1">
            <li class="progress-active">1</li>
            <li class="progress-uncompleted">2</li>
            <li class="progress-uncompleted">3</li>
            <li class="progress-uncompleted">4</li>
            <li class="progress-uncompleted">5</li>
          </ul>

          <ul class="progress-indicator-text pr-4 pl-4">
            <li class="text-center progress-completed">{{ $t('maintenance.questions.create.naming') }}</li>
            <li class="text-center progress-uncompleted">{{ $t('maintenance.questions.create.framework_controls') }}</li>
            <li class="text-center progress-uncompleted">{{ $t('maintenance.questions.create.documentation_collection_task') }}</li>
            <li class="text-center progress-uncompleted">{{ $t('maintenance.questions.create.assessment_task') }}</li>
            <li class="text-center progress-uncompleted">{{ $t('maintenance.questions.create.complete') }}</li>
          </ul>
        </div>
        <div class="alert alert-menu-color color-secondary mb-0" role="alert">
          <div class="row">
            <div class="col-sm-6">

            </div>
            <div class="col-sm-6">
              <div class="text-right">
                <b-button class=""
                          variant="secondary"
                          @click="hideEditQModal"
                          qid="create-q-cancel-button"
                >
                  {{ $t('maintenance.questions.create.cancel') }}
                </b-button>
                <b-button
                        class="ml-3"
                        variant="success"
                        :disabled="buttonDisabled"
                        @click="showEditQForm2('forward')"
                        qid="create-q-submit-button"
                >
                  <span v-if="buttonDisabled" class="mr-1">
                    <font-awesome-icon icon="spinner" size="sm" class="fa-spin"/>
                  </span>
                  {{ $t('maintenance.questions.create.next') }}
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal ref="edit-question-2"
             @hide="hideEditQModal"
             modal-class="task-modal"
             hide-footer>
      <template #modal-header="{ close }">
        <div class="" style="width: 100%;">
          <span @click="hideEditQModal" class="btn btn-secondary cursor-pointer float-right ml-2 mr-0">
            <font-awesome-icon icon="times"/>
          </span>
          <h5 class="mb-1 text-center" qid="create-q-title">
            {{ $t('maintenance.questions.update.title') }}
          </h5>
        </div>
      </template>

      <b-alert v-if="error.length"
               show
               variant="danger"
               qid="create-q-error"
      >
        {{ error }}
      </b-alert>

        <div class="form-container">
          <div class="mb-3">
            <h5 class="mb-2">
              {{ $t('maintenance.questions.create.framework_controls_title') }}
            </h5>
            <span class="text-muted">
              {{ $t('maintenance.questions.create.framework_controls_description') }}
            </span>
          </div>

          <div class="">
            <div class="row">
              <div class="col-sm-6">
              </div>
              <div class="col-sm-6 text-right">
                <button class="btn btn-success"
                        qid="frameworkControl-create-button"
                        @click="showAddFrameworkControlModal"
                >
                  <font-awesome-icon icon="plus"/> {{ $t('generic_controls.framework_controls.create_button') }}
                </button>
              </div>
            </div>
            <table class="table table-responsive-md table-list-secondary">
              <thead>
                <tr>
                  <th scope="col">
                    {{ $t('generic_controls.framework_controls.name') }}
                  </th>
                  <th width="100"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in selectedFrameworkControls" :key="'table-row-' + index"
                    :id="'table-row-' + index">
                  <b-popover
                          :target="'table-row-' + index"
                          boundary="viewport"
                          triggers="hover"
                          placement="topleft"
                          :delay="{ show: 500, hide: 0 }"
                  >
                    <div v-if="item.active_framework && item.active_framework.name" class="mt-2">
                      <span class="text-bold">
                        {{ $t('maintenance.questions.create.active_framework_name_label') }}:
                      </span>
                      <br>
                      {{ item.active_framework.name }}
                      <br>
                    </div>
                    <div v-if="item.control_objective && item.control_objective.name" class="mt-2">
                      <span class="text-bold">
                        {{ $t('maintenance.questions.create.control_objective_name_label') }}:
                      </span>
                      <br>
                      {{ item.control_objective.name }}
                      <br>
                    </div>
                    <div v-if="item.control_objective && item.control_objective.description" class="mt-2">
                      <span class="text-bold">
                        {{ $t('maintenance.questions.create.control_objective_description_label') }}:
                      </span>
                      <br>
                      {{ item.control_objective.description | truncate(200)}}
                      <br>
                    </div>
                    <div v-if="item && item.name" class="mt-2">
                      <span class="text-bold">
                        {{ $t('maintenance.framework_control.table.name') }}:
                      </span>
                      <br>
                      {{ item.name }}
                      <br>
                    </div>
                    <div v-if="item.description" class="mt-2">
                      <span class="text-bold">
                        {{ $t('maintenance.framework_control.create.description_label') }}:
                      </span>
                      <br>
                      {{ item.description | truncate(200)}}
                      <br>
                    </div>
                  </b-popover>
                  <td scope="row">
                    <span v-if="item.active_framework && item.active_framework.name" class="text-muted">
                      {{ item.active_framework.name }}
                    </span>
                    <br>
                    <span>
                      {{ item.full_name }}
                    </span>
                  </td>
                  <td>
                    <button
                            class="btn btn-delete float-right"
                            qid="delete-framework_control-modal"
                            @click="removeFrameworkControlItem(index)"
                    >
                      <font-awesome-icon icon="trash-alt"/>
                    </button>
                  </td>
                </tr>
                <tr v-if="!selectedFrameworkControls.length" qid="no-results-row" class="text-center">
                  <td colspan="10">
                    <h5 class="mt-3">
                      {{ $t('system.no_results') }}
                    </h5>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

        </div>
        <div class="proxy-footer">
          <div class="progress-indicator-footer col text-center pt-2 pb-1">
            <ul class="progress-indicator pr-5 pl-5 mb-1">
              <li class="progress-completed">1</li>
              <li class="progress-active">2</li>
              <li class="progress-uncompleted">3</li>
              <li class="progress-uncompleted">4</li>
              <li class="progress-uncompleted">5</li>
            </ul>

            <ul class="progress-indicator-text pr-4 pl-4">
              <li class="text-center progress-completed">{{ $t('maintenance.questions.create.naming') }}</li>
              <li class="text-center progress-completed">{{ $t('maintenance.questions.create.framework_controls') }}</li>
              <li class="text-center progress-uncompleted">{{ $t('maintenance.questions.create.documentation_collection_task') }}</li>
              <li class="text-center progress-uncompleted">{{ $t('maintenance.questions.create.assessment_task') }}</li>
              <li class="text-center progress-uncompleted">{{ $t('maintenance.questions.create.complete') }}</li>
            </ul>
          </div>
          <div class="alert alert-menu-color color-secondary mb-0" role="alert">
            <div class="row">
              <div class="col-sm-6">

              </div>
              <div class="col-sm-6">
                <div class="text-right">
                  <b-button class=""
                            variant="secondary"
                            @click="showEditQForm1"
                            qid="create-q-cancel-button"
                  >
                    {{ $t('maintenance.questions.create.back') }}
                  </b-button>
                  <b-button @click="showEditQForm3('forward')"
                            class="ml-3"
                            variant="success"
                            :disabled="buttonDisabled"
                            qid="create-q-submit-button"
                  >
                    <span v-if="buttonDisabled" class="mr-1">
                      <font-awesome-icon icon="spinner" size="sm" class="fa-spin"/>
                    </span>
                    {{ $t('maintenance.questions.create.next') }}
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
    </b-modal>

    <b-modal ref="edit-question-3"
             @hide="hideEditQModal"
             modal-class="task-modal"
             hide-footer>
      <template #modal-header="{ close }">
        <div class="" style="width: 100%;">
          <span @click="hideEditQModal" class="btn btn-secondary cursor-pointer float-right ml-2 mr-0">
            <font-awesome-icon icon="times"/>
          </span>
          <h5 class="mb-1 text-center" qid="create-q-title">
            {{ $t('maintenance.questions.update.title') }}
          </h5>
        </div>
      </template>

      <b-alert v-if="error.length"
               show
               variant="danger"
               qid="create-q-error"
      >
        {{ error }}
      </b-alert>

      <div class="form-container">
        <div class="mb-3">
          <h5 class="mb-2">
            {{ $t('maintenance.questions.create.question_data_collection_title') }}
          </h5>
          <span class="text-muted">
            {{ $t('maintenance.questions.create.question_data_collection_description') }}
          </span>
        </div>

        <div v-if="selectedTask.project && selectedTask.project.assessment.type == 'risk'">
          <h5>
            Data collection step is available only for global assessments. Press next to continue.
          </h5>
        </div>

        <div class="form-group" v-if="selectedTask.project && selectedTask.project.assessment.type != 'risk'">
          <label qid="create-q-data_collection-radio-label">
            {{ $t('maintenance.questions.create.data_collection_label') }}
          </label>
          <b-form-radio-group
                  v-model="data_collection_on"
                  :options="dataCollectionOptions"
                  @change="lockSwitch(data_collection_on)"
                  qid="create-q-data_collection-radio-option"
          ></b-form-radio-group>
        </div>

        <div v-if="data_collection_on && selectedTask.project && selectedTask.project.assessment.type != 'risk'">
          <b-form-group>
            <label qid="create-q-data_collection-description-label">
              {{ $t('maintenance.questions.create.data_collection_description_label') }}
              <span class="text-red">*</span>
            </label>
            <b-form-textarea
                    qid="create-q-data_collection-form-description"
                    v-model="form.data_collection_description"
                    rows="6"
                    max-rows="6"
                    required
                    :placeholder="$t('maintenance.questions.create.data_collection_description_placeholder')"
            ></b-form-textarea>
          </b-form-group>

          <b-form-group>
            <div v-show="showDropActive" class="drop-active">
              <h3>Drop files to upload</h3>
            </div>

            <label> {{ $t('maintenance.questions.create.data_collection_attachment_label') }}</label>

            <div class="form-control auto-height pt-3 pb-3">
              <div class="text-center">
                <p>
                  Drag and drop files in here or
                  <file-upload
                          ref="uploader"
                          v-model="data_collection_files"
                          :drop="true"
                          :multiple="true"
                          input-id="uploader"
                          name="uploader"
                          @input="onFileChangeDataCollectionEdit"
                          @dropActive="showDropActiveFunction"
                          class="text-success cursor-pointer"
                  >
                    click here
                  </file-upload>
                  to upload files you wish to display together with the description. You may also
                  <span class="text-success cursor-pointer" @click="showLinksSection = true">add links</span>
                </p>
              </div>

              <p v-for="(file, media_index) in selectedTask.media" :key="media_index">
                <span v-if="file.collection_name == 'data_collection'">
                  <span class="float-right text-muted cursor-pointer"
                        @click="openDeleteAttachmentModal(file)"
                        qid="remove-media-item"
                  >
                    <font-awesome-icon icon="trash-alt"/>
                  </span>
                  <span class="mr-3 float-right text-muted cursor-pointer"
                        @click="downloadMedia(file)"
                        qid="download-media"
                  >
                    <font-awesome-icon icon="cloud-download-alt"/>
                  </span>
                  <a @click="downloadMedia(file)" qid="create-q-media-item">
                    {{ file.file_name }}
                  </a>
                </span>
              </p>

              <div v-if="selectedTask.links">
                <p v-for="(link, link_index) in getDataCollectionLinks" :key="link_index">
                  <span class="float-right text-muted cursor-pointer"
                        @click="openDeleteLinkModal(link)"
                        qid="view-q-remove-link"
                  >
                    <font-awesome-icon icon="trash-alt"/>
                  </span>
                  <a :href="link.url" target="_blank" qid="create-q-link-item">
                    {{ link.name ? link.name : link.url }}
                  </a>
                </p>
              </div>
            </div>
          </b-form-group>

          <b-form-group v-if="showLinksSection">
            <label qid="create-q-link-label">
              {{ $t('maintenance.third_parties.create.links_label') }}
            </label>

            <div>
              <label class="mt-2">{{ $t('system.link') }}</label>
              <b-form-input
                      type="text"
                      aria-describedby="inputGroup"
                      v-model="inputLinkDataCollection"
                      :placeholder="$t('system.link')"
                      qid="create-q-links"
              ></b-form-input>
              <label class="mt-2">{{ $t('table.name') }}</label>
              <b-form-input
                      type="text"
                      aria-describedby="inputGroup"
                      v-model="inputLinkNameDataCollection"
                      :placeholder="inputLinkDataCollection ? inputLinkDataCollection : $t('table.name')"
                      qid="create-q-links"
              ></b-form-input>
              <div class="text-right mt-2">
                <span class="btn btn-success cursor-pointer mr-0"
                      id="inputGroup1"
                      @click="handleEditDataCollectionLink"
                      qid="handle-link-save"
                >
                  {{ $t('system.save') }}
                </span>
              </div>
            </div>
          </b-form-group>

          <b-form-group>
            <label qid="create-q-data_collection-description-label">
              {{ $t('maintenance.questions.create.data_collection_description_two_label') }}
              <span class="text-red">*</span>
            </label>
            <b-form-textarea
                    qid="create-q-data_collection-form-description"
                    v-model="form.data_collection_description_two"
                    rows="6"
                    max-rows="6"
                    required
                    :placeholder="$t('maintenance.questions.create.data_collection_description_two_placeholder')"
            ></b-form-textarea>
          </b-form-group>

          <b-form-group>
            <div v-show="showDropActive" class="drop-active">
              <h3>Drop files to upload</h3>
            </div>

            <label> {{ $t('maintenance.questions.create.data_collection_two_attachment_label') }}</label>

            <div class="form-control auto-height pt-3 pb-3">
              <div class="text-center">
                <p>
                  Drag and drop files in here or
                  <file-upload
                          ref="uploaderTwo"
                          v-model="data_collection_two_files"
                          :drop="true"
                          :multiple="true"
                          input-id="uploaderTwo"
                          name="uploaderTwo"
                          @input="onFileChangeDataCollectionTwoEdit"
                          @dropActive="showDropActiveFunction"
                          class="text-success cursor-pointer"
                  >
                    click here
                  </file-upload>
                  to upload files you wish to display together with the description. You may also
                  <span class="text-success cursor-pointer" @click="showLinksSectionTwo = true">add links</span>
                </p>
              </div>

              <p v-for="(file, media_index) in selectedTask.media" :key="media_index">
                <span v-if="file.collection_name == 'data_collection_two'">
                  <span class="float-right text-muted cursor-pointer"
                        @click="openDeleteAttachmentModal(file)"
                        qid="remove-media-item"
                  >
                    <font-awesome-icon icon="trash-alt"/>
                  </span>
                  <span class="mr-3 float-right text-muted cursor-pointer"
                        @click="downloadMedia(file)"
                        qid="download-media"
                  >
                    <font-awesome-icon icon="cloud-download-alt"/>
                  </span>
                  <a @click="downloadMedia(file)" qid="create-q-media-item">
                    {{ file.file_name }}
                  </a>
                </span>
              </p>

              <div v-if="selectedTask.links">
                <p v-for="(link, link_index) in getDataCollectionTwoLinks" :key="link_index">
                  <span class="float-right text-muted cursor-pointer"
                        @click="openDeleteLinkModal(link)"
                        qid="view-q-remove-link"
                  >
                    <font-awesome-icon icon="trash-alt"/>
                  </span>
                  <a :href="link.url" target="_blank" qid="create-q-link-item">
                    {{ link.name ? link.name : link.url }}
                  </a>
                </p>
              </div>
            </div>
          </b-form-group>


          <b-form-group v-if="showLinksSectionTwo">
            <label qid="create-q-link-label">
              {{ $t('maintenance.third_parties.create.links_label') }}
            </label>

            <div>
              <label class="mt-2">{{ $t('system.link') }}</label>
              <b-form-input
                      type="text"
                      aria-describedby="inputGroup"
                      v-model="inputLinkDataCollection"
                      :placeholder="$t('system.link')"
                      qid="create-q-links"
              ></b-form-input>
              <label class="mt-2">{{ $t('table.name') }}</label>
              <b-form-input
                      type="text"
                      aria-describedby="inputGroup"
                      v-model="inputLinkNameDataCollection"
                      :placeholder="inputLinkDataCollection ? inputLinkDataCollection : $t('table.name')"
                      qid="create-q-links"
              ></b-form-input>
              <div class="text-right mt-2">
                <span class="btn btn-success cursor-pointer mr-0"
                      id="inputGroup2"
                      @click="handleEditDataCollectionTwoLink"
                      qid="handle-link-save"
                >
                  {{ $t('system.save') }}
                </span>
              </div>
            </div>
          </b-form-group>
          <b-form-group>
            <label qid="create-q-pg-label">
              {{ $t('maintenance.questions.create.add_comment_label') }}
              <span class="text-red">*</span>
            </label>
            <multiselect
                    v-model="dataCollectionAddDescription"
                    :options="allowTypesShort"
                    :placeholder="$t('maintenance.questions.create.add_comment_placeholder')"
                    label="name"
                    :select-label="$t('system.dropdown_select_label')"
                    :selected-label="$t('system.dropdown_selected_label')"
                    :deselect-label="$t('system.dropdown_deselect_label')"
                    qid="create-q-form-pg"
            ></multiselect>
          </b-form-group>
          <b-form-group>
            <label qid="create-q-pg-label">
              {{ $t('maintenance.questions.create.add_attachments_label') }}
              <span class="text-red">*</span>
            </label>
            <multiselect
                    v-model="dataCollectionAddAttachments"
                    :options="allowTypes"
                    :placeholder="$t('maintenance.questions.create.add_attachments_placeholder')"
                    label="name"
                    :select-label="$t('system.dropdown_select_label')"
                    :selected-label="$t('system.dropdown_selected_label')"
                    :deselect-label="$t('system.dropdown_deselect_label')"
                    qid="create-q-form-pg"
            ></multiselect>
          </b-form-group>
        </div>
      </div>

      <div class="proxy-footer">
        <div class="progress-indicator-footer col text-center pt-2 pb-1">
          <ul class="progress-indicator pr-5 pl-5 mb-1">
            <li class="progress-completed">1</li>
            <li class="progress-completed">2</li>
            <li class="progress-active">3</li>
            <li class="progress-uncompleted">4</li>
            <li class="progress-uncompleted">5</li>
          </ul>

          <ul class="progress-indicator-text pr-4 pl-4">
            <li class="text-center progress-completed">{{ $t('maintenance.questions.create.naming') }}</li>
            <li class="text-center progress-completed">{{ $t('maintenance.questions.create.framework_controls') }}</li>
            <li class="text-center progress-completed">{{ $t('maintenance.questions.create.documentation_collection_task') }}</li>
            <li class="text-center progress-uncompleted">{{ $t('maintenance.questions.create.assessment_task') }}</li>
            <li class="text-center progress-uncompleted">{{ $t('maintenance.questions.create.complete') }}</li>
          </ul>
        </div>
        <div class="alert alert-menu-color color-secondary mb-0" role="alert">
          <div class="row">
            <div class="col-sm-6">

            </div>
            <div class="col-sm-6">
              <div class="text-right">
                <b-button class=""
                          variant="secondary"
                          @click="showEditQForm2('back')"
                          qid="create-q-data_collection-back-button"
                >
                  {{ $t('maintenance.questions.create.back') }}
                </b-button>
                <b-button
                        class="ml-3"
                        variant="success"
                        @click="showEditQForm4('forward')"
                        :disabled="buttonDisabled"
                        qid="create-q-data_collection-submit-button"
                >
                  <span v-if="buttonDisabled" class="mr-1">
                    <font-awesome-icon icon="spinner" size="sm" class="fa-spin"/>
                  </span>
                  {{ $t('maintenance.questions.create.next') }}
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>


    <b-modal ref="edit-question-4"
             @hide="hideEditQModal"
             modal-class="task-modal"
             hide-footer>
      <template #modal-header="{ close }">
        <div class="" style="width: 100%;">
          <span @click="hideEditQModal" class="btn btn-secondary cursor-pointer float-right ml-2 mr-0">
            <font-awesome-icon icon="times"/>
          </span>
          <h5 class="mb-1 text-center" qid="create-q-title">
            {{ $t('maintenance.questions.update.title') }}
          </h5>
        </div>
      </template>

      <b-alert v-if="error.length"
               show
               variant="danger"
               qid="create-q-error"
      >
        {{ error }}
      </b-alert>

        <div class="form-container">
          <div class="mb-3">
            <h5 class="mb-2">
              {{ $t('maintenance.questions.create.question_description_title') }}
            </h5>
            <span class="text-muted">
              {{ $t('maintenance.questions.create.question_description_description') }}
            </span>
          </div>

          <b-form-group>
            <label qid="create-q-description-label">
              {{ $t('maintenance.questions.create.description_label') }}
              <span class="text-red">*</span>
            </label>
            <b-form-textarea
                    qid="create-q-form-description"
                    v-model="form.description"
                    rows="6"
                    max-rows="6"
                    required
                    :placeholder="$t('maintenance.questions.create.description_placeholder')"
            ></b-form-textarea>
          </b-form-group>
          <b-form-group>
            <div v-show="showDropActive" class="drop-active">
              <h3>
                  {{ $t('tasks.modal.drop_files_to_upload_label') }}</h3>
            </div>
            <label> {{ $t('maintenance.questions.create.data_collection_attachment_label') }}</label>
            <div class="form-control auto-height pt-3 pb-3">
              <div class="text-center">
                <p>
                  {{ $t('tasks.modal.drop_files_here_label') }}
                  <file-upload
                          ref="uploaderData"
                          v-model="files"
                          :drop="true"
                          :multiple="true"
                          input-id="uploaderData"
                          name="uploaderData"
                          @input="onFileChangeEdit"
                          @dropActive="showDropActiveFunction"
                          class="text-success cursor-pointer"
                  >
                    {{ $t('system.click_here') }}
                  </file-upload>
                  {{ $t('tasks.modal.upload_files_second_text') }}
                  <span class="text-success cursor-pointer" @click="showQuestionLinksSection = true">add links</span>
                </p>
              </div>

              <p v-for="(file, media_index) in selectedTask.media" :key="media_index">
                <span v-if="file.collection_name == 'question' || file.collection_name == 'documents'">
                  <span class="float-right text-muted cursor-pointer"
                        @click="openDeleteAttachmentModal(file)"
                        qid="remove-media-item"
                  >
                    <font-awesome-icon icon="trash-alt"/>
                  </span>
                  <span class="mr-3 float-right text-muted cursor-pointer"
                        @click="downloadMedia(file)"
                        qid="download-media"
                  >
                    <font-awesome-icon icon="cloud-download-alt"/>
                  </span>
                  <a @click="downloadMedia(file)" qid="create-q-media-item">
                    {{ file.file_name }}
                  </a>
                </span>
              </p>

              <div v-if="selectedTask.links">
                <p v-for="(link, link_index) in selectedTask.links" :key="link_index">
                  <span class="float-right text-muted cursor-pointer"
                        @click="openDeleteLinkModal(link)"
                        qid="view-q-remove-link"
                  >
                    <font-awesome-icon icon="trash-alt"/>
                  </span>
                  <a :href="link.url" target="_blank" qid="create-q-link-item">
                    {{ link.name ? link.name : link.url }}
                  </a>
                </p>
              </div>
            </div>
          </b-form-group>
          <b-form-group v-if="showQuestionLinksSection">
            <label qid="create-q-link-label">
              {{ $t('maintenance.third_parties.create.links_label') }}
            </label>

            <div>
              <label class="mt-2">{{ $t('system.link') }}</label>
              <b-form-input
                      type="text"
                      aria-describedby="inputGroup"
                      v-model="inputLink"
                      :placeholder="$t('system.link')"
                      qid="create-q-links"
              ></b-form-input>
              <label class="mt-2">{{ $t('table.name') }}</label>
              <b-form-input
                      type="text"
                      aria-describedby="inputGroup"
                      v-model="inputLinkName"
                      :placeholder="inputLink ? inputLink : $t('table.name')"
                      qid="create-q-links"
              ></b-form-input>
              <div class="text-right mt-2">
                <span class="btn btn-success cursor-pointer mr-0"
                      id="inputGroup3"
                      qid="handle-link-save"
                >
                  {{ $t('system.save') }}
                </span>
              </div>
            </div>
          </b-form-group>


          <b-form-group>
            <label qid="create-q-description-label">
              {{ $t('maintenance.questions.create.description_two_label') }}
              <span class="text-red">*</span>
            </label>
            <b-form-textarea
                    qid="create-q-form-description"
                    v-model="form.description_two"
                    rows="6"
                    max-rows="6"
                    required
                    :placeholder="$t('maintenance.questions.create.description_two_placeholder')"
            ></b-form-textarea>
          </b-form-group>
          <b-form-group>
            <div v-show="showDropActive" class="drop-active">
              <h3>
                  {{ $t('tasks.modal.drop_files_to_upload_label') }}</h3>
            </div>
            <label> {{ $t('maintenance.questions.create.data_collection_two_attachment_label') }}</label>
            <div class="form-control auto-height pt-3 pb-3">
              <div class="text-center">
                <p>
                  {{ $t('tasks.modal.drop_files_here_label') }}
                  <file-upload
                          ref="uploaderDataTwo"
                          v-model="files_two"
                          :drop="true"
                          :multiple="true"
                          input-id="uploaderDataTwo"
                          name="uploaderDataTwo"
                          @input="onFileChangeEdit"
                          @dropActive="showDropActiveFunction"
                          class="text-success cursor-pointer"
                  >
                    {{ $t('system.click_here') }}
                  </file-upload>
                  {{ $t('tasks.modal.upload_files_second_text') }}
                  <span class="text-success cursor-pointer" @click="showQuestionTwoLinksSection = true">add links</span>
                </p>
              </div>

              <p v-for="(file, media_index) in selectedTask.media" :key="media_index">
                <span v-if="file.collection_name == 'question_two'">
                  <span class="float-right text-muted cursor-pointer"
                        @click="openDeleteAttachmentModal(file)"
                        qid="remove-media-item"
                  >
                    <font-awesome-icon icon="trash-alt"/>
                  </span>
                  <span class="mr-3 float-right text-muted cursor-pointer"
                        @click="downloadMedia(file)"
                        qid="download-media"
                  >
                    <font-awesome-icon icon="cloud-download-alt"/>
                  </span>
                  <a @click="downloadMedia(file)" qid="create-q-media-item">
                    {{ file.file_name }}
                  </a>
                </span>
              </p>

              <div v-if="selectedTask.links">
                <p v-for="(link, link_index) in selectedTask.links_two" :key="link_index">
                  <span class="float-right text-muted cursor-pointer"
                        @click="openDeleteLinkModal(link)"
                        qid="view-q-remove-link"
                  >
                    <font-awesome-icon icon="trash-alt"/>
                  </span>
                  <a :href="link.url" target="_blank" qid="create-q-link-item">
                    {{ link.name ? link.name : link.url }}
                  </a>
                </p>
              </div>
            </div>
          </b-form-group>
          <b-form-group v-if="showQuestionTwoLinksSection">
            <label qid="create-q-link-label">
              {{ $t('maintenance.third_parties.create.links_label') }}
            </label>

            <div>
              <label class="mt-2">{{ $t('system.link') }}</label>
              <b-form-input
                      type="text"
                      aria-describedby="inputGroup"
                      v-model="inputLink"
                      :placeholder="$t('system.link')"
                      qid="create-q-links"
              ></b-form-input>
              <label class="mt-2">{{ $t('table.name') }}</label>
              <b-form-input
                      type="text"
                      aria-describedby="inputGroup"
                      v-model="inputLinkName"
                      :placeholder="inputLink ? inputLink : $t('table.name')"
                      qid="create-q-links"
              ></b-form-input>
              <div class="text-right mt-2">
                <span class="btn btn-success cursor-pointer mr-0"
                      id="inputGroup4"
                      qid="handle-link-save"
                >
                  {{ $t('system.save') }}
                </span>
              </div>
            </div>
          </b-form-group>





          <b-form-group>
            <label qid="create-q-pg-label">
              {{ $t('maintenance.questions.create.add_response_label') }}
              <span class="text-red">*</span>
            </label>
            <multiselect
                    v-model="addResponse"
                    :options="allowTypes"
                    :placeholder="$t('maintenance.questions.create.add_response_placeholder')"
                    label="name"
                    :select-label="$t('system.dropdown_select_label')"
                    :selected-label="$t('system.dropdown_selected_label')"
                    :deselect-label="$t('system.dropdown_deselect_label')"
                    qid="create-q-form-pg"
            ></multiselect>
          </b-form-group>

          <b-form-group v-if="addResponse && addResponse.value && addResponse.value != 'No'">
            <label qid="create-q-pg-label">
              {{ $t('maintenance.questions.create.type_label') }}
              <span class="text-red">*</span>
            </label>
            <multiselect
                    v-model="responseType"
                    :options="responseTypes"
                    group-values="items"
                    group-label="group"
                    :group-select="false"
                    :placeholder="$t('maintenance.questions.create.type_placeholder')"
                    label="name"
                    :select-label="$t('system.dropdown_select_label')"
                    :selected-label="$t('system.dropdown_selected_label')"
                    :deselect-label="$t('system.dropdown_deselect_label')"
                    qid="create-q-form-pg"
            ></multiselect>
          </b-form-group>

          <b-form-group
                  v-if="responseType && responseType.value == 'Dropdown' && addResponse && addResponse.value && addResponse.value != 'No'">
            <label qid="create-p-link-label">
              {{ $t('maintenance.questions.create.options_label') }}
              <span class="text-red">*</span>
            </label>
            <p v-for="(option, index) in selectedOptions"
               class="mt-1 mb-1 pb-3 pt-3"
               :key="index"
            >
              <span class="float-right btn btn-delete"
                    @click="removeOptionItem(index, option)"
                    qid="create-p-link-remove"
              >
                <font-awesome-icon icon="trash-alt"/>
              </span>
              <multiselect
                      v-model="option.value"
                      :options="answerTypes"
                      group-values="items"
                      group-label="group"
                      :group-select="false"
                      :placeholder="$t('maintenance.questions.create.type_placeholder')"
                      label="name"
                      :select-label="''"
                      :selected-label="''"
                      :deselect-label="''"
                      class="float-right mr-2"
                      style="width:220px;"
                      :allow-empty="false"
                      qid="create-p-form-pg"
              >
                <template slot="singleLabel" slot-scope="props">
                  <font-awesome-icon icon="check-circle"
                                     class="icon-success"
                                     v-if="props.option.value == 'Positive'"
                                     style="background:white;border-radius:50%;"/>
                  <font-awesome-icon icon="times-circle"
                                     class="icon-danger"
                                     v-if="props.option.value == 'Negative'"
                                     style="background:white;border-radius:50%;"/>
                  <font-awesome-icon icon="info-circle"
                                     class="icon-info"
                                     v-if="props.option.value == 'Neutral'"
                                     style="background:white;border-radius:50%;"/>
                  <font-awesome-icon icon="exclamation-triangle"
                                     :class="'risk-1'"
                                     v-if="props.option.value == 'Very low'"/>
                  <font-awesome-icon icon="exclamation-triangle"
                                     :class="'risk-3'"
                                     v-if="props.option.value == 'Low'"/>
                  <font-awesome-icon icon="exclamation-triangle"
                                     :class="'risk-9'"
                                     v-if="props.option.value == 'High'"/>
                  <font-awesome-icon icon="exclamation-triangle"
                                     :class="'risk-16'"
                                     v-if="props.option.value == 'Very high'"/>
                  <span class="ml-2">
                    {{ props.option.name }}
                  </span>
                </template>
                <template slot="option" slot-scope="props">
                  <font-awesome-icon icon="check-circle"
                                     class="icon-success"
                                     v-if="props.option.value == 'Positive'"
                                     style="background:white;border-radius:50%;"/>
                  <font-awesome-icon icon="times-circle"
                                     class="icon-danger"
                                     v-if="props.option.value == 'Negative'"
                                     style="background:white;border-radius:50%;"/>
                  <font-awesome-icon icon="info-circle"
                                     class="icon-info"
                                     v-if="props.option.value == 'Neutral'"
                                     style="background:white;border-radius:50%;"/>
                  <font-awesome-icon icon="exclamation-triangle"
                                     :class="'risk-1'"
                                     v-if="props.option.value == 'Very low'"/>
                  <font-awesome-icon icon="exclamation-triangle"
                                     :class="'risk-3'"
                                     v-if="props.option.value == 'Low'"/>
                  <font-awesome-icon icon="exclamation-triangle"
                                     :class="'risk-9'"
                                     v-if="props.option.value == 'High'"/>
                  <font-awesome-icon icon="exclamation-triangle"
                                     :class="'risk-16'"
                                     v-if="props.option.value == 'Very high'"/>
                  <span class="ml-2">
                    {{ props.option.name }}
                  </span>
                  <span v-if="props.option.$groupLabel">
                    {{ props.option.$groupLabel }}
                  </span>
                </template>
              </multiselect>
              <span class="pt-2">{{ option.title }}</span>
            </p>
            <div class="input-group pt-2">
              <b-form-input
                      qid="create-p-form-link"
                      type="text"
                      v-if="showInput"
                      v-on:keydown.enter="handleOption"
                      v-model="inputOption"
                      :placeholder="$t('maintenance.questions.create.options_placeholder')"
              ></b-form-input>
              <div class="input-group-append" v-if="showInput">
                <span class="input-group-text cursor-pointer"
                      id="inputGroup5"
                      @click="handleOption"
                      qid="handle-link-save"
                >
                  {{ $t('system.save') }}
                </span>
              </div>
            </div>
            <span class="btn btn-link cursor-pointer float-right"
                  @click="showInput = true"
                  v-if="!showInput"
                  qid="create-p-add-link"
            >
              <font-awesome-icon icon="plus"/> {{ $t('maintenance.questions.create.add_option_label') }}
            </span>
          </b-form-group>

          <b-form-group
                  v-if="responseType && responseType.value == 'Yes/No' && addResponse && addResponse.value && addResponse.value != 'No'">
            <label qid="create-p-link-label">
              {{ $t('maintenance.questions.create.options_label') }}
              <span class="text-red">*</span>
            </label>
            <p class="mt-1 mb-1 pb-3 pt-3">
              <multiselect
                      v-model="yesValue"
                      :options="answerTypes"
                      group-values="items"
                      group-label="group"
                      :group-select="false"
                      :placeholder="$t('maintenance.questions.create.type_placeholder')"
                      label="name"
                      :select-label="''"
                      :selected-label="''"
                      :deselect-label="''"
                      class="float-right mr-2"
                      style="width:220px;"
                      :allow-empty="false"
                      qid="create-p-form-pg"
              >
                <template slot="singleLabel" slot-scope="props">
                  <font-awesome-icon icon="check-circle"
                                     class="icon-success"
                                     v-if="props.option.value == 'Positive'"
                                     style="background:white;border-radius:50%;"/>
                  <font-awesome-icon icon="times-circle"
                                     class="icon-danger"
                                     v-if="props.option.value == 'Negative'"
                                     style="background:white;border-radius:50%;"/>
                  <font-awesome-icon icon="info-circle"
                                     class="icon-info"
                                     v-if="props.option.value == 'Neutral'"
                                     style="background:white;border-radius:50%;"/>
                  <font-awesome-icon icon="exclamation-triangle"
                                     :class="'risk-1'"
                                     v-if="props.option.value == 'Very low'"/>
                  <font-awesome-icon icon="exclamation-triangle"
                                     :class="'risk-3'"
                                     v-if="props.option.value == 'Low'"/>
                  <font-awesome-icon icon="exclamation-triangle"
                                     :class="'risk-9'"
                                     v-if="props.option.value == 'High'"/>
                  <font-awesome-icon icon="exclamation-triangle"
                                     :class="'risk-16'"
                                     v-if="props.option.value == 'Very high'"/>
                  <span class="ml-2">
                    {{ props.option.name }}
                  </span>
                </template>
                <template slot="option" slot-scope="props">
                  <font-awesome-icon icon="check-circle"
                                     class="icon-success"
                                     v-if="props.option.value == 'Positive'"
                                     style="background:white;border-radius:50%;"/>
                  <font-awesome-icon icon="times-circle"
                                     class="icon-danger"
                                     v-if="props.option.value == 'Negative'"
                                     style="background:white;border-radius:50%;"/>
                  <font-awesome-icon icon="info-circle"
                                     class="icon-info"
                                     v-if="props.option.value == 'Neutral'"
                                     style="background:white;border-radius:50%;"/>
                  <font-awesome-icon icon="exclamation-triangle"
                                     :class="'risk-1'"
                                     v-if="props.option.value == 'Very low'"/>
                  <font-awesome-icon icon="exclamation-triangle"
                                     :class="'risk-3'"
                                     v-if="props.option.value == 'Low'"/>
                  <font-awesome-icon icon="exclamation-triangle"
                                     :class="'risk-9'"
                                     v-if="props.option.value == 'High'"/>
                  <font-awesome-icon icon="exclamation-triangle"
                                     :class="'risk-16'"
                                     v-if="props.option.value == 'Very high'"/>
                  <span class="ml-2">
                    {{ props.option.name }}
                  </span>
                  <span v-if="props.option.$groupLabel">
                    {{ props.option.$groupLabel }}
                  </span>
                </template>
              </multiselect>
              <span class="mt-2">Yes</span>
            </p>
            <p class="mt-1 mb-1 pb-3 pt-3">
              <multiselect
                      v-model="noValue"
                      :options="answerTypes"
                      group-values="items"
                      group-label="group"
                      :group-select="false"
                      :placeholder="$t('maintenance.questions.create.type_placeholder')"
                      label="name"
                      :select-label="''"
                      :selected-label="''"
                      :deselect-label="''"
                      class="float-right mr-2"
                      style="width:220px;"
                      :allow-empty="false"
                      qid="create-p-form-pg"
              >
                <template slot="singleLabel" slot-scope="props">
                  <font-awesome-icon icon="check-circle"
                                     class="icon-success"
                                     v-if="props.option.value == 'Positive'"
                                     style="background:white;border-radius:50%;"/>
                  <font-awesome-icon icon="times-circle"
                                     class="icon-danger"
                                     v-if="props.option.value == 'Negative'"
                                     style="background:white;border-radius:50%;"/>
                  <font-awesome-icon icon="info-circle"
                                     class="icon-info"
                                     v-if="props.option.value == 'Neutral'"
                                     style="background:white;border-radius:50%;"/>
                  <font-awesome-icon icon="exclamation-triangle"
                                     :class="'risk-1'"
                                     v-if="props.option.value == 'Very low'"/>
                  <font-awesome-icon icon="exclamation-triangle"
                                     :class="'risk-3'"
                                     v-if="props.option.value == 'Low'"/>
                  <font-awesome-icon icon="exclamation-triangle"
                                     :class="'risk-9'"
                                     v-if="props.option.value == 'High'"/>
                  <font-awesome-icon icon="exclamation-triangle"
                                     :class="'risk-16'"
                                     v-if="props.option.value == 'Very high'"/>
                  <span class="ml-2">
                    {{ props.option.name }}
                  </span>
                </template>
                <template slot="option" slot-scope="props">
                  <font-awesome-icon icon="check-circle"
                                     class="icon-success"
                                     v-if="props.option.value == 'Positive'"
                                     style="background:white;border-radius:50%;"/>
                  <font-awesome-icon icon="times-circle"
                                     class="icon-danger"
                                     v-if="props.option.value == 'Negative'"
                                     style="background:white;border-radius:50%;"/>
                  <font-awesome-icon icon="info-circle"
                                     class="icon-info"
                                     v-if="props.option.value == 'Neutral'"
                                     style="background:white;border-radius:50%;"/>
                  <font-awesome-icon icon="exclamation-triangle"
                                     :class="'risk-1'"
                                     v-if="props.option.value == 'Very low'"/>
                  <font-awesome-icon icon="exclamation-triangle"
                                     :class="'risk-3'"
                                     v-if="props.option.value == 'Low'"/>
                  <font-awesome-icon icon="exclamation-triangle"
                                     :class="'risk-9'"
                                     v-if="props.option.value == 'High'"/>
                  <font-awesome-icon icon="exclamation-triangle"
                                     :class="'risk-16'"
                                     v-if="props.option.value == 'Very high'"/>
                  <span class="ml-2">
                    {{ props.option.name }}
                  </span>
                  <span v-if="props.option.$groupLabel">
                    {{ props.option.$groupLabel }}
                  </span>
                </template>
              </multiselect>
              <span class="mt-2">No</span>
            </p>
          </b-form-group>

          <div class="text-muted mt-2 mb-3"
               v-if="responseType && responseType.value == 'Dropdown' && addResponse && addResponse.value && addResponse.value != 'No'">
            * {{ $t('maintenance.questions.create.helper_text_label') }}
          </div>
          <div class="text-muted mt-2 mb-3"
               v-if="responseType && responseType.value != 'Dropdown' && addResponse && addResponse.value && addResponse.value != 'No'">
            * {{ $t('maintenance.questions.create.yes_no_helper_text_label') }}
          </div>

          <b-form-group>
            <label qid="create-q-pg-label">
              {{ $t('maintenance.questions.create.add_notes_label') }}
              <span class="text-red">*</span>
            </label>
            <multiselect
                    v-model="addNotes"
                    :options="allowTypes"
                    :placeholder="$t('maintenance.questions.create.add_notes_placeholder')"
                    label="name"
                    :select-label="$t('system.dropdown_select_label')"
                    :selected-label="$t('system.dropdown_selected_label')"
                    :deselect-label="$t('system.dropdown_deselect_label')"
                    qid="create-q-form-pg"
            ></multiselect>
          </b-form-group>
          <b-form-group>
            <label qid="create-q-pg-label">
              {{ $t('maintenance.questions.create.add_control_description_label') }}
              <span class="text-red">*</span>
            </label>
            <multiselect
                    v-model="addControlDescription"
                    :options="allowTypes"
                    :placeholder="$t('maintenance.questions.create.add_control_description_placeholder')"
                    label="name"
                    :select-label="$t('system.dropdown_select_label')"
                    :selected-label="$t('system.dropdown_selected_label')"
                    :deselect-label="$t('system.dropdown_deselect_label')"
                    qid="create-q-form-pg"
            ></multiselect>
          </b-form-group>
          <b-form-group>
            <label qid="create-q-pg-label">
              {{ $t('maintenance.questions.create.add_performed_text_label') }}
              <span class="text-red">*</span>
            </label>
            <multiselect
                    v-model="addPerformedText"
                    :options="allowTypes"
                    :placeholder="$t('maintenance.questions.create.add_performed_text_placeholder')"
                    label="name"
                    :select-label="$t('system.dropdown_select_label')"
                    :selected-label="$t('system.dropdown_selected_label')"
                    :deselect-label="$t('system.dropdown_deselect_label')"
                    qid="create-q-form-pg"
            ></multiselect>
          </b-form-group>
          <b-form-group>
            <label qid="create-q-pg-label">
              {{ $t('maintenance.questions.create.add_description_label') }}
              <span class="text-red">*</span>
            </label>
            <multiselect
                    v-model="addDescription"
                    :options="allowTypes"
                    :placeholder="$t('maintenance.questions.create.add_description_placeholder')"
                    label="name"
                    :select-label="$t('system.dropdown_select_label')"
                    :selected-label="$t('system.dropdown_selected_label')"
                    :deselect-label="$t('system.dropdown_deselect_label')"
                    qid="create-q-form-pg"
            ></multiselect>
          </b-form-group>
          <b-form-group>
            <label qid="create-q-pg-label">
              {{ $t('maintenance.questions.create.add_attachments_label') }}
              <span class="text-red">*</span>
            </label>
            <multiselect
                    v-model="addAttachments"
                    :options="allowTypes"
                    :placeholder="$t('maintenance.questions.create.add_attachments_placeholder')"
                    label="name"
                    :select-label="$t('system.dropdown_select_label')"
                    :selected-label="$t('system.dropdown_selected_label')"
                    :deselect-label="$t('system.dropdown_deselect_label')"
                    qid="create-q-form-pg"
            ></multiselect>
          </b-form-group>
        </div>
        <div class="proxy-footer">
          <div class="progress-indicator-footer col text-center pt-2 pb-1">
            <ul class="progress-indicator pr-5 pl-5 mb-1">
              <li class="progress-completed">1</li>
              <li class="progress-completed">2</li>
              <li class="progress-completed">3</li>
              <li class="progress-active">4</li>
              <li class="progress-uncompleted">5</li>
            </ul>

            <ul class="progress-indicator-text pr-4 pl-4">
              <li class="text-center progress-completed">{{ $t('maintenance.questions.create.naming') }}</li>
              <li class="text-center progress-completed">{{ $t('maintenance.questions.create.framework_controls') }}</li>
              <li class="text-center progress-completed">{{ $t('maintenance.questions.create.documentation_collection_task') }}</li>
              <li class="text-center progress-completed">{{ $t('maintenance.questions.create.assessment_task') }}</li>
              <li class="text-center progress-uncompleted">{{ $t('maintenance.questions.create.complete') }}</li>
            </ul>
          </div>
          <div class="alert alert-menu-color color-secondary mb-0" role="alert">
            <div class="row">
              <div class="col-sm-6">

              </div>
              <div class="col-sm-6">
                <div class="text-right">
                  <b-button class=""
                            variant="secondary"
                            @click="showEditQForm3('back')"
                            qid="create-q-data_collection-back-button"
                  >
                    {{ $t('maintenance.questions.create.back') }}
                  </b-button>
                  <b-button
                          class="ml-3"
                          variant="success"
                          @click="showEditQForm5"
                          :disabled="buttonDisabled"
                          qid="create-q-data_collection-submit-button"
                  >
                    <span v-if="buttonDisabled" class="mr-1">
                      <font-awesome-icon icon="spinner" size="sm" class="fa-spin"/>
                    </span>
                    {{ $t('maintenance.questions.create.next') }}
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
    </b-modal>


    <b-modal ref="edit-question-5"
             @hide="hideEditQModal"
             modal-class="task-modal"
             hide-footer>
      <template #modal-header="{ close }">
        <div class="" style="width: 100%;">
          <span @click="hideEditQModal" class="btn btn-secondary cursor-pointer float-right ml-2 mr-0">
            <font-awesome-icon icon="times"/>
          </span>
          <h5 class="mb-1 text-center" qid="create-q-title">
            {{ $t('maintenance.questions.update.title') }}
          </h5>
        </div>
      </template>

      <b-alert v-if="error.length"
               show
               variant="danger"
               qid="create-q-error"
      >
        {{ error }}
      </b-alert>

      <b-form @submit="editFormSubmit" qid="create-q-form">
        <div class="form-container">
          <div class="mt-3 mb-3 text-center">
            <h5>{{ $t('maintenance.questions.create.submit_success_message') }}</h5>
          </div>
        </div>
        <div class="proxy-footer">
          <div class="progress-indicator-footer col text-center pt-2 pb-1">
            <ul class="progress-indicator pr-5 pl-5 mb-1">
              <li class="progress-completed">1</li>
              <li class="progress-completed">2</li>
              <li class="progress-completed">3</li>
              <li class="progress-completed">4</li>
              <li class="progress-active">5</li>
            </ul>

            <ul class="progress-indicator-text pr-4 pl-4">
              <li class="text-center progress-completed">{{ $t('maintenance.questions.create.naming') }}</li>
              <li class="text-center progress-completed">{{ $t('maintenance.questions.create.framework_controls') }}</li>
              <li class="text-center progress-completed">{{ $t('maintenance.questions.create.documentation_collection_task') }}</li>
              <li class="text-center progress-completed">{{ $t('maintenance.questions.create.assessment_task') }}</li>
              <li class="text-center progress-completed">{{ $t('maintenance.questions.create.complete') }}</li>
            </ul>
          </div>
          <div class="alert alert-menu-color color-secondary mb-0" role="alert">
            <div class="row">
              <div class="col-sm-6">

              </div>
              <div class="col-sm-6">
                <div class="text-right">
                  <b-button class=""
                            variant="secondary"
                            @click="showEditQForm4('back')"
                            qid="create-q-back-button"
                  >
                    {{ $t('maintenance.questions.create.back') }}
                  </b-button>
                  <b-button type="submit"
                            class="ml-3"
                            variant="success"
                            :disabled="buttonDisabled"
                            qid="create-q-submit-button"
                  >
                    <span v-if="buttonDisabled" class="mr-1">
                      <font-awesome-icon icon="spinner" size="sm" class="fa-spin"/>
                    </span>
                    {{ $t('maintenance.questions.create.submit') }}
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-form>
    </b-modal>

    <!-- Add new framework control -->
    <b-modal ref="add-framework-control" hide-footer @hide="hideAddFrameworkControlModal">
      <div class="d-block text-center">
        <h4 qid="add-io-title">
          {{ $t('generic_controls.framework_controls.create.title') }}
        </h4>
      </div>
      <b-alert v-if="error.length"
               show
               variant="danger"
               qid="create-io-error"
      >
        {{ error }}
      </b-alert>
      <b-form @submit="frameworkControlFormSubmit" qid="create-framework-control-form">
        <div class="form-container">
          <b-form-group>
            <label qid="create-framewor-control-pg-label">
              {{ $t('generic_controls.framework_controls.create.framework_label') }}
            </label>
            <multiselect
                    v-model="selectedFramework"
                    :options="frameworkItems"
                    :select-label="$t('system.dropdown_select_label')"
                    :selected-label="$t('system.dropdown_selected_label')"
                    :deselect-label="$t('system.dropdown_deselect_label')"
                    :placeholder="$t('generic_controls.framework_controls.create.framework_placeholder')"
                    label="name"
                    @search-change="loadFrameworkItems"
                    @select="handleFrameworkChange"
                    track-by="slug"
                    qid="create-lb-form-pg"
            ></multiselect>
          </b-form-group>
          <b-form-group v-if="selectedFramework && selectedFramework.id">
            <label qid="create-framewor-control-pg-label">
              {{ $t('generic_controls.framework_controls.create.framework_control_label') }}
            </label>
            <multiselect
                    v-model="selectedFrameworkControl"
                    :options="frameworkControlItems"
                    :select-label="$t('system.dropdown_select_label')"
                    :selected-label="$t('system.dropdown_selected_label')"
                    :deselect-label="$t('system.dropdown_deselect_label')"
                    :placeholder="$t('generic_controls.framework_controls.create.framework_control_placeholder')"
                    label="full_name"
                    @search-change="loadFrameworkControlItems"
                    track-by="id"
                    qid="create-lb-form-pg"
            ></multiselect>
          </b-form-group>
          <div class="text-muted mt-2 mb-3">
            {{ $t('generic_controls.framework_controls.create.framework_control_tip') }}
          </div>
        </div>
        <div class="text-right">
          <b-button class="mt-3"
                    variant="secondary"
                    @click="hideAddFrameworkControlModal"
                    qid="create-p-cancel-button"
          >
            {{ $t('generic_controls.framework_controls.create.cancel') }}
          </b-button>
          <b-button type="submit"
                    class="mt-3 ml-3"
                    variant="success"
                    :disabled="buttonDisabled"
                    qid="create-p-submit-button"
          >
            <span v-if="buttonDisabled" class="mr-1">
              <font-awesome-icon icon="spinner" size="sm" class="fa-spin"/>
            </span>
            {{ $t('generic_controls.framework_controls.create.submit') }}
          </b-button>
        </div>
      </b-form>
    </b-modal>

    <!-- Add new control objective -->
    <b-modal ref="add-control-objective"
             @hide="hideCreateControlObjectiveModal"
             modal-class="task-modal"
             hide-footer>

      <template #modal-header="{ close }">
        <div class="" style="width: 100%;">
          <span @click="hideCreateControlObjectiveModal" class="btn btn-secondary cursor-pointer float-right ml-2 mr-0">
            <font-awesome-icon icon="times"/>
          </span>
          <h5 class="mb-1 text-center" qid="add-co-title">
            {{ $t('maintenance.control_objective.create.title') }}
          </h5>
        </div>
      </template>

      <b-alert v-if="error.length"
               show
               variant="danger"
               qid="add-co-error"
      >
        {{ error }}
      </b-alert>

      <b-form @submit="coFormSubmit" qid="create-co-form">
        <div class="form-container">
          <b-form-group>
            <label qid="create-control-objective-name-label">
              {{ $t('maintenance.control_objective.create.name_label') }}<span class="mandatory"> *</span>
            </label>
            <b-form-input
                    qid="create-control-objective-form-name"
                    type="text"
                    v-model="coForm.name"
                    :maxLength="255"
                    required
                    :placeholder="$t('maintenance.control_objective.create.name_placeholder')"
            ></b-form-input>
          </b-form-group>
          <b-form-group>
            <label qid="create-control-objective-description-label">
              {{ $t('maintenance.control_objective.create.description_label') }}
            </label>
            <b-form-textarea
                    qid="create-control-objective-form-description"
                    v-model="coForm.description"
                    rows="6"
                    max-rows="6"
                    :placeholder="$t('maintenance.control_objective.create.description_placeholder')"
            ></b-form-textarea>
          </b-form-group>
        </div>

        <div class="proxy-footer">
          <div class="alert alert-menu-color color-secondary mb-0" role="alert">
            <div class="row">
              <div class="col-sm-6">

              </div>
              <div class="col-sm-6">
                <div class="text-right">
                  <b-button class=""
                            variant="secondary"
                            @click="hideCreateControlObjectiveModal"
                            qid="create-control-objective-cancel-button"
                  >
                    {{ $t('maintenance.control_objective.create.cancel') }}
                  </b-button>
                  <b-button type="submit"
                            class="ml-3"
                            variant="success"
                            :disabled="buttonDisabled"
                            qid="create-control-objective-submit-button"
                  >
                        <span v-if="buttonDisabled" class="mr-1">
                          <font-awesome-icon icon="spinner" size="sm" class="fa-spin"/>
                        </span>
                    {{ $t('maintenance.control_objective.create.submit') }}
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-form>
    </b-modal>

    <ConfirmRemoveAttachment
            :object="selectedFile"
            :show="showDeleteAttachmentModal"
            type="file"
            @closeConfirmModal="closeDeleteAttachmentModal"
    ></ConfirmRemoveAttachment>

    <ConfirmRemoveAttachment
            :object="selectedLink"
            :show="showDeleteLinkModal"
            type="link"
            @closeConfirmModal="closeDeleteLinkModal"
    ></ConfirmRemoveAttachment>

    <ConfirmRemoveAttachment
            :object="selectedComment"
            :task="selectedTask"
            :show="showDeleteCommentModal"
            type="comment"
            @closeConfirmModal="closeDeleteCommentModal"
    ></ConfirmRemoveAttachment>

    <GuideViewModal
            :guide="selectedGuide"
            :showModal="viewGuideModal"
            @closeViewModal="closeViewGuideModal"
    ></GuideViewModal>

    <ShowMoreModal
            :items="selectedItem.users"
            :show="showUsersModal"
            @closeViewModal="closeUsersModal"
            type="users"
    ></ShowMoreModal>

    <!-- Delete observation modal template -->
    <b-modal ref="delete-observation" hide-footer>
      <div class="d-block text-center">
        <h4 qid="delete-observation-title">
          {{ $t('activities.system.delete.title') }}
        </h4>
      </div>
      <h5 class="text-center">
        {{ $t('activities.system.delete.description') }}
        <span v-if="itemToDelete">{{ itemToDelete.name }}</span>
      </h5>
      <h5 class="text-center">
        {{ $t('activities.system.delete.are_you_sure') }}
      </h5>
      <div class="text-center">
        <b-button class="mt-3"
                  variant="secondary"
                  @click="hideSConfirmDeleteModal"
                  qid="delete-observation-cancel-button"
        >
          {{ $t('activities.system.delete.cancel') }}
        </b-button>
        <b-button @click="confirmDeleteS(itemToDelete)"
                  class="mt-3 ml-3"
                  variant="success"
                  qid="delete-observation-submit-button"
        >
          {{ $t('activities.system.delete.submit') }}
        </b-button>
      </div>
    </b-modal>


    <!-- Edit observation modal -->
    <b-modal ref="edit-observation" hide-footer @hide="hideEditModal">
      <div class="d-block text-center">
        <h4 qid="delete-tp-title">
          {{ $t('projects.observations.update.title') }}
        </h4>
      </div>
      <b-alert v-if="error.length"
               show
               variant="danger"
               qid="create-p-error"
      >
        {{ error }}
      </b-alert>
      <b-form @submit="editObservationFormSubmit" qid="create-p-form">
        <div class="form-container">
          <b-form-group v-if="selectedTask.project && selectedTask.project.type != 'control_assessment'">
            <label qid="create-s-tags-label">
              {{ $t('projects.observations.create.scope_label') }}
            </label>
            <multiselect
                    v-model="selectedScope"
                    :options="scopeItems"
                    :placeholder="$t('projects.observations.create.scope_placeholder')"
                    label="name"
                    :select-label="$t('system.dropdown_select_label')"
                    :selected-label="$t('system.dropdown_selected_label')"
                    :deselect-label="$t('system.dropdown_deselect_label')"
                    qid="confidentiality-form-impact"
            ></multiselect>
          </b-form-group>
          <b-form-group>
            <label qid="create-v-name-label">
              {{ $t('projects.observations.create.name_label') }}
              <span class="text-red">*</span>
            </label>
            <b-form-input
                    qid="create-v-form-name"
                    type="text"
                    v-model="editForm.name"
                    required
                    :maxLength="255"
                    :placeholder="$t('projects.observations.create.name_placeholder')"
            ></b-form-input>
          </b-form-group>
          <b-form-group>
            <label qid="create-v-description-label">
              {{ $t('projects.observations.create.description_label') }}
              <span class="text-red">*</span>
            </label>
            <b-form-textarea
                    qid="create-v-form-description"
                    v-model="editForm.description"
                    rows="6"
                    max-rows="6"
                    required
                    :placeholder="$t('projects.observations.create.description_placeholder')"
            ></b-form-textarea>
          </b-form-group>
          <b-form-group>
            <label qid="create-s-tags-label">
              {{ $t('projects.observations.create.impact_label') }}
              <span class="text-red">*</span>
            </label>
            <multiselect
                    v-model="selectedImpact"
                    :options="impactItems"
                    :placeholder="$t('projects.observations.create.impact_placeholder')"
                    label="name"
                    :select-label="$t('system.dropdown_select_label')"
                    :selected-label="$t('system.dropdown_selected_label')"
                    :deselect-label="$t('system.dropdown_deselect_label')"
                    qid="confidentiality-form-impact"
            ></multiselect>
          </b-form-group>
        </div>
        <div class="text-right">
          <b-button class="mt-3"
                    variant="secondary"
                    @click="hideEditModal"
                    qid="create-p-cancel-button"
          >
            {{ $t('projects.create.cancel') }}
          </b-button>
          <b-button type="submit"
                    class="mt-3 ml-3"
                    variant="success"
                    :disabled="buttonDisabled"
                    qid="create-p-submit-button"
          >
            <span v-if="buttonDisabled" class="mr-1">
              <font-awesome-icon icon="spinner" size="sm" class="fa-spin"/>
            </span>
            {{ $t('projects.create.submit') }}
          </b-button>
        </div>
      </b-form>

    </b-modal>

  </span>

</template>

<script>
  import piincHttp from '@/util/configureAxios.js';
  import Datepicker from 'vuejs-datepicker';
  import {config} from '@/config.js';
  import ConfirmRemoveAttachment from '@/components/ConfirmRemoveAttachment.vue';
  import GuideViewModal from '@/components/guide/GuideViewModal.vue';
  import roleCheck from '@/util/ACL/roleCheck';
  import Avatar from '@/components/Avatar.vue';
  import ShowMoreModal from '@/components/ShowMoreModal.vue';
  // import draggable from 'vuedraggable'
  import CreateObservationModal from '@/views/Projects/CreateObservationModal.vue';
  // import AssessmentGlobalExpandable from '@/components/tasks/AssessmentGlobalExpandable.vue';
  import UserInviteModal from '@/components/user/UserInviteModal.vue';
  import ObservationViewModal from '@/views/Projects/ObservationViewModal.vue';

  export default {
    name: 'AssessmentGlobalSingleTaskModal',
    data: function () {
      return {
        // Update Task variables
        taskForm: {
          remarks: '',
          notes: '',
          data_remarks: '',
          control_description: '',
          performed_text: '',
          rto: '',
          rpo: ''
        },
        selectedTask: [],

        // Update Question variables
        form: {
          name: '',
          description: '',
          description_two: '',
          reference: '',
          control_objective: {},
          links: [],
          data_collection_links: [],
          data_collection_description: '',
          data_collection_description_two: ''
        },
        referenceInvalid: false,
        addControlDescription: [],
        addNotes: [],
        addPerformedText: [],
        addDescription: [],
        addResponse: [],
        addAttachments: [],
        data_collection_on: 0,
        responseType: [],
        dataCollectionAddDescription: [],
        dataCollectionAddAttachments: [],
        showQuestionLinksSection: false,
        showQuestionTwoLinksSection: false,
        selectedTaskDependency: [],

        responseTypes: [
          {
            group: this.$t('maintenance.questions.answer_type.input_label'),
            items: [
              {name: this.$t('maintenance.questions.type.datepicker'), value: 'Datepicker'},
            ]
          },
          {
            group: this.$t('maintenance.questions.answer_type.dropdown_label'),
            items: [
              {name: this.$t('maintenance.questions.type.dropdown'), value: 'Dropdown'},
              {name: this.$t('maintenance.questions.type.yes/no'), value: 'Yes/No'}
            ]
          }
        ],
        allowTypes: [
          {name: this.$t('maintenance.questions.type.no'), value: 'No'},
          {name: this.$t('maintenance.questions.type.optional'), value: 'Optional'},
          {name: this.$t('maintenance.questions.type.mandatory'), value: 'Mandatory'},
        ],
        allowTypesShort: [
          {name: this.$t('maintenance.questions.type.optional'), value: 'Optional'},
          {name: this.$t('maintenance.questions.type.mandatory'), value: 'Mandatory'},
        ],
        answerTypes: [
          {
            group: this.$t('maintenance.questions.answer_type.positive_negative_label'),
            items: [
              {name: this.$t('maintenance.questions.answer_type.neutral'), value: 'Neutral'},
              {name: this.$t('maintenance.questions.answer_type.positive'), value: 'Positive'},
              {name: this.$t('maintenance.questions.answer_type.negative'), value: 'Negative'},
            ]
          },
          {
            group: this.$t('maintenance.questions.answer_type.impact_assessment_label'),
            items: [
              {name: this.$t('maintenance.questions.answer_type.very_low'), value: 'Very low'},
              {name: this.$t('maintenance.questions.answer_type.low'), value: 'Low'},
              {name: this.$t('maintenance.questions.answer_type.high'), value: 'High'},
              {name: this.$t('maintenance.questions.answer_type.very_high'), value: 'Very high'},
            ]
          },
        ],
        yesNoOptions: [
          'Yes',
          'No'
        ],
        yesValue: [],
        noValue: [],
        dataCollectionOptions: [
          {text: this.$t('system.yes'), value: 1},
          {text: this.$t('system.no'), value: 0},
        ],
        selectedOptions: [],
        inputOption: '',
        positiveAnswers: [],
        negativeAnswers: [],

        // Framework Control variables
        selectedFrameworkControls: [],
        selectedFramework: [],
        selectedFrameworkControl: [],
        frameworkControlItems: [],
        frameworkItems: [],

        // Control Objective variables
        controlObjectives: [],
        selectedControlObjective: {},
        assessmentControlOpen: false,
        coForm: {
          name: '',
          description: ''
        },
        sTableItems: [],
        perPage: '',
        selectedPerPage: "10",
        currentPage: 1,
        totalRows: 0,
        selectedObservation: {},
        viewObservationModal: false,
        itemToDelete: {},
        editForm: {
          name: '',
          description: '',
          scope_id: '',
          impact_assessment: ''
        },
        selectedImpact: [],
        scopeItems: [],
        selectedScope: [],
        // General variables
        buttonDisabled: false,
        selectedFile: {},
        selectedLink: {},
        selectedComment: {},
        showDeleteAttachmentModal: false,
        showDeleteLinkModal: false,
        showDeleteCommentModal: false,
        showInput: false,
        showSingleLinkLabel: true,
        inputLink: '',
        inputLinkName: '',
        descriptionLength: config.descriptionLength,
        showFullProjectMessage: false,
        showFullTaskDescription: false,
        showFullDataCollectionDescription: false,
        showFullDataCollectionTwoDescription: false,
        showFullGenericControlDescription: false,
        error: '',
        sizeError: '',
        taskList: [],
        selectedAnswer: [],
        selectedDate: '',
        selectedDataCollectionAnswer: [],
        allAnswers: [],
        impactItems: [
          {name: this.$t('impact.very_low'), value: 'Very low'},
          {name: this.$t('impact.low'), value: 'Low'},
          {name: this.$t('impact.high'), value: 'High'},
          {name: this.$t('impact.very_high'), value: 'Very high'}
        ],
        intervalOptions: [
          {text: this.$t('system.interval_seconds'), value: 'Seconds'},
          {text: this.$t('system.interval_minutes'), value: 'Minutes'},
          {text: this.$t('system.interval_hours'), value: 'Hours'},
          {text: this.$t('system.interval_days'), value: 'Days'},
          {text: this.$t('system.interval_months'), value: 'Months'},
          {text: this.$t('system.interval_years'), value: 'Years'},
        ],
        selectedConfidentialityImpact: [],
        selectedIntegrityImpact: [],
        selectedAvailabilityImpact: [],
        rtoInterval: [],
        rpoInterval: [],
        project_progress: 0,
        taskCompleted: false,
        welcomeGuide: {},
        selectedGuide: {},
        viewGuideModal: false,
        selectedItem: {},
        showUsersModal: false,
        currentView: '',
        files: [],
        files_two: [],
        data_collection_files: [],
        data_collection_two_files: [],
        showLinksSection: false,
        showLinksSectionTwo: false,
        showDataCollectionLinksSection: false,
        uploader: false,
        showDropActive: false,
        userList: [],
        comment: '',
        reopenError: '',
        selectedAssignUsers: [],
        previousComments: [],
        qaComments: [],
        showFullGuideDescription: false,
        wpGuide: {},
        wpGuideData: {},
        wpGuideSystem: {},
        myGuides: [],
        myGuidesData: [],
        myGuidesSystem: [],

        editModeResponse: false,
        editModeNotes: false,
        editModeControlDescription: false,
        editModePerformedTest: false,
        editModeConclusion: false,
        editModeControlDescriptionDocumentationCollection: false,

        showFullTextNotes: false,
        showFullTextControlDescription: false,
        showFullTextPerformedTest: false,
        showFullTextConclusion: false,
        showFullTextControlDescriptionDocumentationCollection: false,

        assessmentGlobalExpandableShow: false,
        qa_comment: ''
      }
    },
    props: {
      task: {},
      controlObjective: {},
    },
    components: {
      Datepicker,
      ConfirmRemoveAttachment,
      GuideViewModal,
      Avatar,
      ShowMoreModal,
      // draggable,
      CreateObservationModal,
      UserInviteModal,
      // AssessmentGlobalExpandable,
      ObservationViewModal,
    },
    computed: {
      getClient() {
        return this.$store.state.client;
      },
      getUser() {
        return this.$store.state.user;
      },
      getWordpressLink() {
        return this.$store.state.wordpressLink;
      },
      getReportMedia() {
        let media = [];

        media = _.filter(this.selectedTask.project.media, function (mediaItem) {
          return mediaItem.collection_name == 'documents' || mediaItem.collection_name == 'project';
        });

        return media;
      },
      getReportLinks() {
        let links = [];

        links = _.filter(this.selectedTask.project.links, function (link) {
          return link.collection == null;
        });

        return links;
      }
    },
    watch: {
      getClient: function () {

      },
      includeScheduled: function () {
        this.loadCA();
      }
    },
    methods: {
      loadQaComments() {
        let self = this;
        this.qaComments = []
        piincHttp.get('tasks/' + this.selectedTask.id + '/comments', {
          params:
            {
              client: this.getClient.id,
              per_page: 100,
              sort: 'created_at',
              order: 'asc',
              types: 'accepted,rejected'
            }
        }).then(function (response) {
          self.qaComments = []

          _.forEach(response.data.items, function (item) {
            self.qaComments.push(item);
          })

        }, function () {
        });
      },
      showQaLog() {
        let self = this;
        self.loadQaComments();
        self.$refs['qa-log'].show();
      },
      hideQaLog() {
        let self = this;
        self.$refs['qa-log'].hide();
      },
      showReopenQaTask() {
        let self = this;
        self.qa_comment = '';
        self.$refs['reopen-qa-task'].show();
      },
      hideReopenQaTask() {
        let self = this;
        self.$refs['reopen-qa-task'].hide();
      },
      reOpenQaTask() {
        this.buttonDisabled = true;
        // Keep the reference in a variable
        // to use it inside function called when promise returned
        let self = this;

        piincHttp.put('tasks/' + this.selectedTask.id, {
          status: 'pending',
          qa_status: 'rejected',
          qa_comment: self.qa_comment
        }).then(function (response) {
          self.selectedTask = response.data;
          self.buttonDisabled = false;
          self.$refs['reopen-qa-task'].hide();
        }, function (error) {
          if (error.status !== 200) {
            self.buttonDisabled = false;
            self.error = error.data.message;
          }
        })
      },
      acceptQaTask() {
        this.buttonDisabled = true;
        // Keep the reference in a variable
        // to use it inside function called when promise returned
        let self = this;

        piincHttp.put('tasks/' + this.selectedTask.id, {
          qa_status: 'accepted',
          qa_comment: 'accepted'
        }).then(function (response) {
          self.selectedTask = response.data;
          self.buttonDisabled = false;
          self.hideTaskModal();
        }, function (error) {
          if (error.status !== 200) {
            self.buttonDisabled = false;
            self.error = error.data.message;
          }
        })
      },
      // Edit modals
      showEditQForm1() {
        this.buttonDisabled = true;
        let self = this;
        self.$refs['edit-question-1'].show();
        self.$refs['edit-question-2'].hide();
        self.$refs['edit-question-3'].hide();
        self.$refs['edit-question-4'].hide();
        self.$refs['edit-question-5'].hide();
        self.buttonDisabled = false;
      },
      showEditQForm2(type) {
        this.buttonDisabled = true;
        let self = this;
        self.error = '';

        if (type && type == 'forward') {
          if (self.form.name && self.form.name.length && this.selectedControlObjective && this.selectedControlObjective.id && !this.referenceInvalid) {
            self.$refs['edit-question-1'].hide();
            self.$refs['edit-question-2'].show();
            self.$refs['edit-question-3'].hide();
            self.$refs['edit-question-4'].hide();
            self.$refs['edit-question-5'].hide();
          } else {
            self.error = ''
            if (!self.form.name.length) {
              this.error += ' ' + this.$t('maintenance.data_objects.create.name_error');
            }
            if (self.referenceInvalid) {
              this.error += ' ' + this.$t('maintenance.questions.create.reference_error_message');
            }
            if (!this.selectedControlObjective || (this.selectedControlObjective && !this.selectedControlObjective.id)) {
              this.error += ' ' + this.$t('maintenance.questions.create.control_objective_error_message');
            }
          }
        } else {
          self.$refs['edit-question-1'].hide();
          self.$refs['edit-question-2'].show();
          self.$refs['edit-question-3'].hide();
          self.$refs['edit-question-4'].hide();
          self.$refs['edit-question-5'].hide();
        }

        self.buttonDisabled = false;
      },
      showEditQForm3(type) {
        this.buttonDisabled = true;
        let self = this;
        self.error = '';

        self.$refs['edit-question-1'].hide();
        self.$refs['edit-question-2'].hide();
        self.$refs['edit-question-3'].show();
        self.$refs['edit-question-4'].hide();
        self.$refs['edit-question-5'].hide();

        self.buttonDisabled = false;
      },
      showEditQForm4(type) {
        this.buttonDisabled = true;
        let self = this;
        self.error = '';

        if (type && type == 'forward') {

          if ((self.data_collection_on && self.form.data_collection_description && self.form.data_collection_description.length && this.dataCollectionAddAttachments && this.dataCollectionAddDescription.value && this.dataCollectionAddAttachments && this.dataCollectionAddAttachments.value) || !self.data_collection_on) {
            self.$refs['edit-question-1'].hide();
            self.$refs['edit-question-2'].hide();
            self.$refs['edit-question-3'].hide();
            self.$refs['edit-question-4'].show();
            self.$refs['edit-question-5'].hide();
          } else {
            self.error = ''
            if (!self.form.data_collection_description.length) {
              this.error += ' ' + this.$t('maintenance.questions.create.data_collection_description_error_message');
            }
            if (!this.dataCollectionAddDescription || (this.dataCollectionAddDescription && !this.dataCollectionAddDescription.value)) {
              this.error += ' ' + this.$t('maintenance.questions.create.add_description_error_message');
            }
            if (!this.dataCollectionAddAttachments || (this.dataCollectionAddAttachments && !this.dataCollectionAddAttachments.value)) {
              this.error += ' ' + this.$t('maintenance.questions.create.add_attachments_error_message');
            }
          }
        } else {
          self.$refs['edit-question-1'].hide();
          self.$refs['edit-question-2'].hide();
          self.$refs['edit-question-3'].hide();
          self.$refs['edit-question-4'].show();
          self.$refs['edit-question-5'].hide();
        }

        self.buttonDisabled = false;
      },
      showEditQForm5() {
        this.assessmentControlOpen = true;
        this.buttonDisabled = true;
        let self = this;
        let sizeError = false;
        self.error = '';

        for (var i = 0; i < this.files.length; i++) {
          // Equal to 10 MB
          if (this.files[i].size > 31457280) {
            sizeError = true;
          }
        }

        if (!sizeError && ((this.responseType && this.responseType.value) || (this.addResponse && this.addResponse.value == 'No')) && this.form.description.length && this.addDescription && this.addDescription.value && this.addResponse && this.addResponse.value && this.addControlDescription && this.addControlDescription.value && this.addNotes && this.addNotes.value && this.addPerformedText && this.addPerformedText.value && this.addAttachments && this.addAttachments.value && ((this.addResponse && this.addResponse.value == 'No') || (this.responseType && (this.responseType.value == 'Datepicker' || this.responseType.value == 'Yes/No')) || (this.responseType && this.responseType.value == 'Dropdown' && this.selectedOptions && this.selectedOptions.length))) {
          self.$refs['edit-question-1'].hide();
          self.$refs['edit-question-2'].hide();
          self.$refs['edit-question-3'].hide();
          self.$refs['edit-question-4'].hide();
          self.$refs['edit-question-5'].show();
        } else {
          this.error = '';
          if (!self.form.description.length) {
            this.error += ' ' + this.$t('maintenance.questions.create.description_error_message');
          }
          if (sizeError) {
            this.error += ' ' + this.$t('system.file_size_30');
          }
          if (!this.responseType || (this.responseType && !this.responseType.value)) {
            this.error += ' ' + this.$t('maintenance.questions.create.response_type_error_message');
          }
          if (!this.addDescription || (this.addDescription && !this.addDescription.value)) {
            this.error += this.$t('maintenance.questions.create.add_description_error_message');
          }
          if (!this.addResponse || (this.addResponse && !this.addResponse.value)) {
            this.error += this.$t('maintenance.questions.create.add_response_error_message');
          }
          if (!this.addControlDescription || (this.addControlDescription && !this.addControlDescription.value)) {
            this.error += this.$t('maintenance.questions.create.add_control_description_error_message');
          }
          if (!this.addNotes || (this.addNotes && !this.addNotes.value)) {
            this.error += this.$t('maintenance.questions.create.add_notes_error_message');
          }
          if (!this.addPerformedText || (this.addPerformedText && !this.addPerformedText.value)) {
            this.error += this.$t('maintenance.questions.create.add_performed_text_error_message');
          }
          if (!this.addAttachments || (this.addAttachments && !this.addAttachments.value)) {
            this.error += this.$t('maintenance.questions.create.add_attachments_error_message');
          }
          if ((this.responseType && this.responseType.value == 'Select') && this.selectedOptions && !this.selectedOptions.length) {
            this.error += this.$t('maintenance.questions.create.selected_options_error_message');
          }
        }

        self.buttonDisabled = false;
      },
      getDataCollectionTwoLinks() {
        let links = []

        links = _.filter(this.selectedQuestion.links, function (link) {
          return link.collection === 'data_collection_two';
        });

        return links;
      },
      handleEditDataCollectionLink() {
        let self = this;
        let formData = new FormData();

        if (!this.inputLinkDataCollection.startsWith('http')) {
          this.inputLinkDataCollection = 'https://' + this.inputLinkDataCollection;
        }

        formData.append("url", this.inputLinkDataCollection);
        formData.append("name", this.inputLinkNameDataCollection);
        formData.append("collection", "data_collection");
        formData.append("model_type", this.selectedQuestion.who_am_i);
        formData.append("model_id", this.selectedQuestion.id);

        piincHttp.post('links', formData).then(function () {
          self.$toastr('success', self.$t('maintenance.third_parties.link_add_success'));
          self.loadQuestion();
          self.inputLinkDataCollection = '';
          self.inputLinkNameDataCollection = '';
          self.showInput = false;
        }, function () {
        })
      },
      handleEditDataCollectionTwoLink() {
        let self = this;
        let formData = new FormData();

        if (!this.inputLinkDataCollection.startsWith('http')) {
          this.inputLinkDataCollection = 'https://' + this.inputLinkDataCollection;
        }

        formData.append("url", this.inputLinkDataCollection);
        formData.append("name", this.inputLinkNameDataCollection);
        formData.append("collection", "data_collection_two");
        formData.append("model_type", this.selectedQuestion.who_am_i);
        formData.append("model_id", this.selectedQuestion.id);

        piincHttp.post('links', formData).then(function () {
          self.$toastr('success', self.$t('maintenance.third_parties.link_add_success'));
          self.loadQuestion();
          self.inputLinkDataCollection = '';
          self.inputLinkNameDataCollection = '';
          self.showInput = false;
        }, function () {
        })
      },
      onFileChangeDataCollectionTwoEdit(files) {
        let self = this;
        let formData = new FormData();
        let sizeError = false;

        // Append list of attachments to FormData
        for (let i = 0; i < this.data_collection_files.length; i++) {
          // Equal to 10 MB
          if (this.data_collection_files[i].size > 31457280) {
            sizeError = true;
          }
          formData.append("document[]", this.data_collection_files[i].file);
        }

        formData.append("name", "documents");
        formData.append("collection", "data_collection_two");
        formData.append("model_type", this.selectedQuestion.who_am_i);
        formData.append("model_id", this.selectedQuestion.id);

        if (sizeError) {
          this.error = this.$t('system.file_size_30')
          this.data_collection_files = []
        } else {
          piincHttp.post('documents', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }).then(function () {
            self.$toastr('success', self.$t('maintenance.third_parties.media_add_success'));
            self.error = '';
            self.loadQuestion()
            // reset the model array
            self.data_collection_files = [];
          }, function (error) {
            self.error = error.data.message;
          })
        }
      },
      onFileChangeDataCollectionEdit(files) {
        let self = this;
        let formData = new FormData();
        let sizeError = false;

        // Append list of attachments to FormData
        for (let i = 0; i < this.data_collection_files.length; i++) {
          // Equal to 10 MB
          if (this.data_collection_files[i].size > 31457280) {
            sizeError = true;
          }
          formData.append("document[]", this.data_collection_files[i].file);
        }

        formData.append("name", "documents");
        formData.append("collection", "data_collection");
        formData.append("model_type", this.selectedQuestion.who_am_i);
        formData.append("model_id", this.selectedQuestion.id);

        if (sizeError) {
          this.error = this.$t('system.file_size_30')
          this.data_collection_files = []
        } else {
          piincHttp.post('documents', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }).then(function () {
            self.$toastr('success', self.$t('maintenance.third_parties.media_add_success'));
            self.error = '';
            self.loadQuestion()
            // reset the model array
            self.data_collection_files = [];
          }, function (error) {
            self.error = error.data.message;
          })
        }
      },
      getDataCollectionLinks() {
        let links = []

        links = _.filter(this.selectedQuestion.links, function (link) {
          return link.collection === 'data_collection';
        });

        return links;
      },
      hideEditQModal() {
        this.assessmentControlOpen = false;
        this.$refs['edit-question-1'].hide();
        this.$refs['edit-question-2'].hide();
        this.$refs['edit-question-3'].hide();
        this.$refs['edit-question-4'].hide();
        this.$refs['edit-question-5'].hide();
      },
      showEditQModal(object) {
        let self = this;

        this.error = '';
        this.selectedOptions = [];
        this.inputOption = '';
        this.positiveAnswers = [];
        this.negativeAnswers = [];
        this.allAnswers = [];
        this.selectedControlObjective = [];
        this.responseType = [];
        this.selectedFiles = [];
        this.selectedFrameworkControls = [];
        this.form.links = [];
        this.inputLink = '';
        this.inputLinkName = '';
        this.inputLinkDataCollection = '';
        this.inputLinkNameDataCollection = '';
        this.data_collection_on = 0;
        this.showInput = true;
        this.showOptionInput = true;
        this.referenceInvalid = false;

        this.form.name = '';
        this.form.description = '';
        this.form.data_collection_description = '';
        this.form.data_collection_description_two = '';
        this.form.reference = '';

        this.form.links = [];
        this.form.data_collection_links = [];

        this.files = [];
        this.data_collection_files = [];
        this.data_collection_two_files = [];
        this.addDescription = [];
        this.addResponse = [];
        this.addControlDescription = [];
        this.addNotes = [];
        this.addPerformedText = [];
        this.addAttachments = [];
        this.dataCollectionAddDescription = [];
        this.dataCollectionAddAttachments = [];
        this.showLinksSection = false;
        this.showLinksSectionTwo = false;
        this.showQuestionLinksSection = false;
        this.showQuestionTwoLinksSection = false;
        this.selectedTaskDependency = [];
        this.selectedAnswers = [];

        // this.dependantQuestionItems = []
        // _.forEach(self.filteredTableItems, function (question) {
        //   if (question.id != self.selectedTask.id) {
        //     self.dependantQuestionItems.push(question)
        //   }
        // })

        if (object.framework_controls && object.framework_controls.length) {
          this.selectedFrameworkControls = _.cloneDeep(object.framework_controls);
        }

        this.responseType = [];
        if (object.response_type) {
          if (object.response_type == 'Datepicker') {
            this.responseType = _.find(this.responseTypes[0].items, function (item) {
              return item.value === object.response_type;
            });
          } else {
            this.responseType = _.find(this.responseTypes[1].items, function (item) {
              return item.value === object.response_type;
            });
          }
        }

        this.addDescription = [];
        if (object.add_description) {
          this.addDescription = _.find(this.allowTypes, function (item) {
            return item.value === object.add_description;
          });
        }

        this.addResponse = [];
        if (object.add_response) {
          this.addResponse = _.find(this.allowTypes, function (item) {
            return item.value === object.add_response;
          });
        }

        this.addNotes = [];
        if (object.add_control_description) {
          this.addNotes = _.find(this.allowTypes, function (item) {
            return item.value === object.add_notes;
          });
        }

        this.addControlDescription = [];
        if (object.add_control_description) {
          this.addControlDescription = _.find(this.allowTypes, function (item) {
            return item.value === object.add_control_description;
          });
        }

        this.addPerformedText = [];
        if (object.add_performed_text) {
          this.addPerformedText = _.find(this.allowTypes, function (item) {
            return item.value === object.add_performed_text;
          });
        }

        this.addAttachments = [];
        if (object.add_attachments) {
          this.addAttachments = _.find(this.allowTypes, function (item) {
            return item.value === object.add_attachments;
          });
        }

        this.dataCollectionAddDescription = [];
        if (object.data_collection_add_description) {
          this.dataCollectionAddDescription = _.find(this.allowTypes, function (item) {
            return item.value === object.data_collection_add_description;
          });
        } else {
          this.dataCollectionAddDescription = this.allowTypes[1];
        }

        this.dataCollectionAddAttachments = [];
        if (object.data_collection_add_attachments) {
          this.dataCollectionAddAttachments = _.find(this.allowTypes, function (item) {
            return item.value === object.data_collection_add_attachments;
          });
        } else {
          this.dataCollectionAddAttachments = this.allowTypes[1];
        }

        if (this.selectedTask.response_type == 'Dropdown') {
          this.selectedOptions = [];
          if (this.selectedTask.response_type_options) {
            let array = JSON.parse(this.selectedTask.response_type_options);
            this.selectedOptions.push(...array);
          }
        }

        if (this.selectedTask.response_type == 'Yes/No') {
          this.yesValue = [];
          this.noValue = [];
          if (this.selectedTask.response_type_options) {
            let array = JSON.parse(this.selectedTask.response_type_options);
            this.yesValue = array[0].value;
            this.noValue = array[1].value;
          }
        }

        // this.selectedTaskDependency = []
        // if (object.dependency_question_id) {
        //
        //   this.selectedTaskDependency = _.find(this.tableItems, function(item) {
        //     return item.id === object.dependency_question_id;
        //   });
        //
        //   this.handleAnswers(this.selectedTaskDependency)
        //
        //   if (object.dependency_question_answer) {
        //     _.forEach(object.dependency_question_answer, function (item, index) {
        //       self.selectedAnswers.push({
        //         title: item,
        //         value: item,
        //       });
        //     })
        //   }
        // }

        if (this.controlObjective && this.controlObjective.name) {
          this.selectedControlObjective = this.controlObjective;
        }

        this.form.slug = object.slug;
        this.form.name = object.title;
        this.form.description = object.description;
        this.form.description_two = object.description_two;
        this.form.data_collection_description = object.data_collection_description ? object.data_collection_description : '';
        this.form.data_collection_description_two = object.data_collection_description_two ? object.data_collection_description_two : '';
        this.form.reference = object.reference;
        this.data_collection_on = object.data_collection_on;

        this.showEditQForm1();
      },
      editFormSubmit(evt) {
        this.buttonDisabled = true;

        // Keep the reference in a variable
        // to use it inside function called when promise returned
        let self = this;
        evt.preventDefault();

        let form = {};

        form.title = this.form.name;
        form.reference = this.form.reference;
        form.description = this.form.description;
        form.description_two = this.form.description_two;

        // Data collection formData
        if (this.data_collection_on) {

          form.data_collection_on = this.data_collection_on;
          form.data_collection_description = this.form.data_collection_description;
          form.data_collection_description_two = this.form.data_collection_description_two;

          if (this.dataCollectionAddDescription && this.dataCollectionAddDescription.value) {
            form.data_collection_add_description = this.dataCollectionAddDescription.value;
          }

          if (this.dataCollectionAddAttachments && this.dataCollectionAddAttachments.value) {
            form.data_collection_add_attachments = this.dataCollectionAddAttachments.value;
          }

        } else {
          form.data_collection_on = 0;
        }

        if (this.responseType && this.responseType.value) {
          form.response_type = this.responseType.value;
        }

        if (this.addDescription && this.addDescription.value) {
          form.add_description = this.addDescription.value;
        }
        if (this.addResponse && this.addResponse.value) {
          form.add_response = this.addResponse.value;
        }
        if (this.addControlDescription && this.addControlDescription.value) {
          form.add_control_description = this.addControlDescription.value;
        }
        if (this.addNotes && this.addNotes.value) {
          form.add_notes = this.addNotes.value;
        }
        if (this.addPerformedText && this.addPerformedText.value) {
          form.add_performed_text = this.addPerformedText.value;
        }

        if (this.selectedControlObjective && this.selectedControlObjective.id) {
          form.control_objective_id = this.selectedControlObjective.id;
        }

        if (this.addAttachments && this.addAttachments.value) {
          form.add_attachments = this.addAttachments.value;
        }

        if (this.responseType && this.responseType.value && this.responseType.value == 'Yes/No') {
          let array = [
            {title: 'Yes', value: this.yesValue},
            {title: 'No', value: this.noValue},
          ]
          form.response_type_options = JSON.stringify(array);
        }

        if (this.responseType && this.responseType.value && this.responseType.value == 'Dropdown') {
          if (this.selectedOptions && this.selectedOptions.length) {
            form.response_type_options = JSON.stringify(this.selectedOptions);
          } else {
            form.response_type_options = '[]';
          }
        }

        if (this.selectedFrameworkControls && this.selectedFrameworkControls.length) {
          form.framework_controls = [];
          for (var i = 0; i < this.selectedFrameworkControls.length; i++) {
            form.framework_controls.push(this.selectedFrameworkControls[i].id);
          }
        }

        if (this.selectedTaskDependency && this.selectedTaskDependency.id) {
          form.dependency_question_id = this.selectedTaskDependency.id;
          form.dependency_question_answer = [];
          for (var i = 0; i < this.selectedAnswers.length; i++) {
            form.dependency_question_answer.push(this.selectedAnswers[i].title);
          }
        } else {
          form.dependency_question_id = '';
        }

        if (((this.responseType && this.responseType.value) || (this.addResponse && this.addResponse.value == 'No')) && this.form.description.length && this.addDescription && this.addDescription.value && this.addResponse && this.addResponse.value && this.addControlDescription && this.addControlDescription.value && this.addNotes && this.addNotes.value && this.addPerformedText && this.addPerformedText.value && this.addAttachments && this.addAttachments.value && ((this.addResponse && this.addResponse.value == 'No') || (this.responseType && (this.responseType.value == 'Datepicker' || this.responseType.value == 'Yes/No')) || (this.responseType && this.responseType.value == 'Dropdown' && this.selectedOptions && this.selectedOptions.length))) {
          piincHttp.put('tasks/' + this.selectedTask.id, form).then(function (response) {
            self.$toastr('success', self.$t('maintenance.questions.update.success'));
            self.$emit('reloadContent');

            self.selectedTask = response.data;
            self.allAnswers = JSON.parse(self.selectedTask.response_type_options);

            self.hideEditQModal();
            self.buttonDisabled = false;
          }, function (error) {
            if (error.status !== 200) {
              self.buttonDisabled = false;
              self.error = error.data.message;
            }
          })
        } else {
          this.error = '';
          self.buttonDisabled = false;
          if (!this.responseType || (this.responseType && !this.responseType.value)) {
            this.error += ' ' + this.$t('maintenance.questions.create.response_type_error_message');
          }
          if (!this.addDescription || (this.addDescription && !this.addDescription.value)) {
            this.error += this.$t('maintenance.questions.create.add_description_error_message');
          }
          if (!this.addResponse || (this.addResponse && !this.addResponse.value)) {
            this.error += this.$t('maintenance.questions.create.add_response_error_message');
          }
          if (!this.addAttachments || (this.addAttachments && !this.addAttachments.value)) {
            this.error += this.$t('maintenance.questions.create.add_attachments_error_message');
          }
          if ((this.responseType && this.responseType.value == 'Select') && this.selectedOptions && !this.selectedOptions.length) {
            this.error += this.$t('maintenance.questions.create.selected_options_error_message');
          }
        }
      },
      showAddFrameworkControlModal() {
        this.assessmentControlOpen = true;
        this.selectedFramework = [];
        this.error = '';
        this.selectedFrameworkControl = [];
        this.loadFrameworkItems();
        this.$refs['add-framework-control'].show();
      },
      hideAddFrameworkControlModal() {
        this.assessmentControlOpen = false;
        this.error = '';
        this.selectedFramework = [];
        this.selectedFrameworkControl = [];
        this.$refs['add-framework-control'].hide();
      },
      handleFrameworkChange(item) {
        this.selectedFramework = item;
        this.loadFrameworkControlItems();
      },
      loadFrameworkItems(query) {
        let self = this;
        piincHttp.get('frameworks', {
          params:
            {
              client: this.getClient.slug,
              per_page: 100,
              keyword: query ? query : undefined
            }
        }).then(function (response) {
          self.frameworkItems = response.data.items;
          if (self.frameworkItems && !self.frameworkItems.length && !query) {
            self.frameworkItems.push({name: self.$t('system.no_results'), $isDisabled: true});
          }
        }, function () {
        });
      },
      loadFrameworkControlItems(query) {
        let self = this;
        piincHttp.get('framework-controls', {
          params:
            {
              client: this.getClient.slug,
              per_page: 100,
              framework: this.selectedFramework.id,
              keyword: query ? query : undefined
            }
        }).then(function (response) {
          self.frameworkControlItems = response.data.items;
          if (self.frameworkControlItems && !self.frameworkControlItems.length && !query) {
            self.frameworkControlItems.push({name: self.$t('system.no_results'), $isDisabled: true});
          }
        }, function () {
        });
      },
      frameworkControlFormSubmit(evt) {
        let self = this
        evt.preventDefault();

        let addToArray = false;

        if (self.selectedFrameworkControl && self.selectedFrameworkControl.id) {
          if (this.selectedFrameworkControls.length) {
            _.forEach(this.selectedFrameworkControls, function (item, index) {
              if (item.id != self.selectedFrameworkControl.id) {
                addToArray = true;
              }
            })
          } else {
            addToArray = true;
          }

          if (addToArray) {
            this.selectedFrameworkControls.push(this.selectedFrameworkControl);
          }

          this.hideAddFrameworkControlModal();
        } else {
          this.error = this.$t('maintenance.questions.create.framework_control_error');
        }

      },
      showCreateControlObjectiveModal() {
        this.coForm.name = '';
        this.coForm.description = '';
        this.error = '';
        this.$refs['add-control-objective'].show();
      },
      hideCreateControlObjectiveModal() {
        this.coForm.name = '';
        this.coForm.description = '';
        this.error = '';
        this.loadCO();
        this.$refs['add-control-objective'].hide();
      },
      coFormSubmit(evt) {
        this.buttonDisabled = true;
        // let formData = new FormData();

        // Keep the reference in a variable
        // to use it inside function called when promise returned
        let self = this;
        evt.preventDefault();

        this.coForm.client = this.getClient.slug;
        this.coForm.assessment_id = this.selectedTask.project.id;

        piincHttp.post('/assessment-control-objectives', this.coForm).then(function (response) {
          self.hideCreateControlObjectiveModal();
          // self.loadQuestions();

          self.selectedControlObjective = response.data;

          // self.$emit('updated');
          self.$emit('reloadContent');
          self.buttonDisabled = false;

          self.$toastr('success', self.$t('maintenance.control_objective.create.success'))
        }, function (error) {
          if (error.status !== 200) {
            self.buttonDisabled = false;
            self.error = error.data.message;
          }
        })
      },
      loadCO() {
        let self = this;
        piincHttp.get('/assessment-control-objectives', {
          params:
            {
              client: this.getClient.slug,
              assessment: this.selectedTask.project.assessment.id,
              per_page: 100,
              // keyword: query ? query : undefined
            }
        }).then(function (response) {
          self.controlObjectives = response.data.items;

          if (self.controlObjectives && !self.controlObjectives.length && !query) {
            self.controlObjectives.push({name: self.$t('system.no_results'), $isDisabled: true});
          }
        }, function () {
        });
      },
      lockSwitch(lockStatus) {
        let self = this;
        if (lockStatus) {
          this.data_collection_on = 0;
        } else {
          this.data_collection_on = 1;
        }
      },
      handleOption(evt) {
        evt.preventDefault();
        // If there's a link in input, add it to links array
        if (this.inputOption !== '') {
          this.selectedOptions.push({title: this.inputOption, value: this.answerTypes[0].items[0]})
          // Reset link input form
          this.inputOption = ''
        }
        this.showOptionInput = false;
      },
      getMedia(type) {
        let media = [];
        if (type == 'task') {
          media = _.filter(this.selectedTask.media, function (mediaItem) {
            return mediaItem.collection_name == 'documents';
          });
        }

        if (type == 'data_documents') {
          media = _.filter(this.selectedTask.media, function (mediaItem) {
            return mediaItem.collection_name == 'data_documents';
          });
        }

        if (type == 'task_and_data_collection') {
          media = _.filter(this.selectedTask.media, function (mediaItem) {
            return mediaItem.collection_name == 'documents' || mediaItem.collection_name == 'data_documents';
          });
        }

        if (type == 'question') {
          media = _.filter(this.selectedTask.media, function (mediaItem) {
            return mediaItem.collection_name == 'question';
          });
        }

        if (type == 'data_collection') {
          media = _.filter(this.selectedTask.media, function (mediaItem) {
            return mediaItem.collection_name == 'data_collection';
          });
        }
        return media;
      },
      getDifference(endDate) {
        var moment = require('moment');
        let currentDate = moment();
        endDate = moment(endDate);
        let difference = endDate.diff(currentDate, 'days');
        return difference;
      },
      openGuide() {
        if (this.wpGuide && this.wpGuide.link) {
          window.open(this.wpGuide.link, '_blank');
        }
      },
      getGuide(code) {
        let existingGuide = _.find(this.myGuides, function (object) {
          return object.scope == code && object.type == 'Task' && object.enabled;
        });
        return existingGuide;
      },
      loadGuides(code) {
        let self = this;
        let language = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en';

        piincHttp.get('guides', {
          params:
            {
              per_page: 1,
              language: 'en',
              type: 'Task',
              scope: code,
              client: this.getClient.slug
            }
        }).then(function (response) {
          if (response.data.items.length) {
            self.myGuides.push(response.data.items[0]);
            piincHttp.get(self.getWordpressLink + response.data.items[0].external_id).then(function (response) {
              self.wpGuide = response.data;
            }, function () {
            });
          }
        }, function () {
        });
      },
      loadGuidesData(code) {
        let self = this;
        let language = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en';

        piincHttp.get('guides', {
          params:
            {
              per_page: 1,
              language: 'en',
              type: 'Task',
              scope: code,
              client: this.getClient.slug
            }
        }).then(function (response) {
          if (response.data.items.length) {
            self.myGuides.push(response.data.items[0]);
            piincHttp.get(self.getWordpressLink + response.data.items[0].external_id).then(function (response) {
              self.wpGuideData = response.data;
            }, function () {
            });
          }
        }, function () {
        });
      },
      loadGuidesSystem(code) {
        let self = this;
        let language = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en';

        piincHttp.get('guides', {
          params:
            {
              per_page: 1,
              language: 'en',
              type: 'Task',
              scope: code,
              client: this.getClient.slug
            }
        }).then(function (response) {
          if (response.data.items.length) {
            self.myGuides.push(response.data.items[0]);
            piincHttp.get(self.getWordpressLink + response.data.items[0].external_id).then(function (response) {
              self.wpGuideSystem = response.data;
            }, function () {
            });
          }
        }, function () {
        });
      },
      getLinks(type) {
        let links = [];
        if (type == 'task') {
          links = _.filter(this.selectedTask.links, function (link) {
            return link.collection == 'task';
          });
        }

        if (type == 'question') {
          links = _.filter(this.selectedTask.links, function (link) {
            return link.collection == 'question' || link.collection == null;
          });
        }

        if (type == 'data_documents') {
          links = _.filter(this.selectedTask.links, function (link) {
            return link.collection == 'data_documents';
          });
        }

        if (type == 'task_and_data_collection') {
          links = _.filter(this.selectedTask.links, function (link) {
            return link.collection == 'data_documents' || link.collection == 'task';
          });
        }

        if (type == 'data_collection') {
          links = _.filter(this.selectedTask.links, function (link) {
            return link.collection == 'data_collection';
          });
        }
        return links;
      },
      getReducedUsers(users) {
        let reducedUsers = [];
        if (users) {
          if (users.length > 3) {
            reducedUsers = _.cloneDeep(users);
            reducedUsers = reducedUsers.slice(0, 3);
          } else {
            reducedUsers = users;
          }
        }
        return reducedUsers;
      },
      getIcon(object) {
        let foundObject = {};
        let responseOptions = JSON.parse(object.response_type_options);

        foundObject = _.find(responseOptions, function (item) {
          return item.title === object.response;
        });

        if (foundObject) {
          return foundObject.value.value;
        } else {
          return '';
        }

      },
      showCreateLinkModal(object) {
        let self = this;
        this.error = '';
        this.buttonDisabled = false;

        this.$refs['add-link'].show();
      },
      hideCreateLinkModal() {
        this.$refs['add-link'].hide();
      },
      reloadUserList() {
        this.loadUsers();
      },
      loadUsers(query) {
        let self = this;

        this.buttonDisabled = true;

        piincHttp.get('users', {
          params:
            {
              client: this.getClient.id,
              keyword: query ? query : undefined,
              per_page: 100
            }
        }).then(function (response) {
          self.userList = response.data.items;
          self.buttonDisabled = false;
        }, function () {
        });
      },
      showReopenDataCollectionModal(object) {
        let self = this;
        this.comment = '';
        this.reopenError = '';

        this.selectedAssignUsers = [];

        this.loadUsers();

        _.forEach(self.selectedTask.data_users, function (user) {
          self.selectedAssignUsers.push(user);
        })

        this.$refs['reopen-task'].show();
      },
      hideReopenDataCollectionModal() {
        this.selectedAssignUsers = [];
        this.$refs['reopen-task'].hide();
      },
      loadComments(taskId) {
        let self = this;
        let taskActiveId = '';
        if(taskId){
          taskActiveId = taskId;
        }else{
          taskActiveId = self.task.id;
        }
        this.previousComments = []
        piincHttp.get('tasks/' + taskActiveId + '/comments', {
          params:
            {
              client: this.getClient.id,
              per_page: 100,
              sort: 'created_at',
              order: 'desc'
            }
        }).then(function (response) {
          self.previousComments = []

          _.forEach(response.data.items, function (item) {
            item.expanded = false;
            self.previousComments.push(item);
          })

        }, function () {
        });
      },
      openUsersModal(object) {
        this.selectedItem = object;
        this.showUsersModal = true;
      },
      closeUsersModal() {
        this.selectedItem = {};
        this.showUsersModal = false;
      },
      switchCurrentView(type) {
        this.showLinksSection = false;
        this.showDataCollectionLinksSection = false;
        this.loadComments();
        this.currentView = type;
        if (type == 'default') {
          this.loadGuides('assessment_task');
        } else {
          this.loadGuidesData('data_collection_task');
        }
      },
      showTaskModal(object) {
        let self = this;


        this.error = '';
        this.showLinksSection = false;
        this.showDataCollectionLinksSection = false;

        this.loadTaskById(object.id);

        if (object == null && this.tasks && this.tasks.length) {
          self.selectedTask = this.tasks[0];
        }

        this.loadObservations(object.id);
        // Checking the type of the users so the right view is displayed
        if (this.isOwner(self.selectedTask.project)) {
          // if the user is owner on the project od admin

          this.currentView = 'default';
        } else {
          // Checking the type of the users so the right view is displayed
          if (self.isOwner(self.selectedTask)) {
            self.switchCurrentView('default');
            if (self.selectedTask.code == 'update_measures') {
              self.loadGuidesSystem('system_description_task');
            } else {
              self.loadGuides('assessment_task');
            }
          } else {
            if (self.isDataOwner(self.selectedTask)) {
              self.switchCurrentView('data_collection');
              self.loadGuidesData('data_collection_task');
            }
          }
        }

        this.loadComments(object.id);

        this.$refs['task-modal'].show();
        this.loadTaskGuide();
      },
      hideTaskModal() {
        let self = this;
        this.error = '';

        this.editModeResponse = false;
        this.editModeNotes = false;
        this.editModeControlDescription = false;
        this.editModePerformedTest = false;
        this.editModeConclusion = false;
        this.editModeControlDescriptionDocumentationCollection = false;

        this.taskForm.response = '';
        this.taskForm.notes = '';
        this.taskForm.control_description = '';
        this.taskForm.performed_text = '';
        this.taskForm.remarks = '';
        this.taskForm.data_remarks = '';
        this.taskForm.rto = '';
        this.taskForm.rpo = '';
        this.selectedAnswer = [];
        this.selectedDate = '';
        this.selectedDataCollectionAnswer = [];

        setTimeout(function () {
          self.$emit('reloadContent');
          self.selectedTask = {};
        }, 500);

        if (this.taskCompleted) {
          self.$store.commit('storeReloadTasks');
        }

        this.$refs['task-modal'].hide();
      },
      navigateToProject(task) {
        this.$store.commit('storeTaskToolbarState', true);
        this.$store.commit('storeActiveTask', task);
        this.$router.push({name: 'projects.view', params: {slug: task.project.slug}});
      },
      populateForm() {
        let self = this;

        self.taskForm.remarks = self.selectedTask.remarks;
        self.taskForm.notes = self.selectedTask.notes;
        self.taskForm.control_description = self.selectedTask.control_description;
        self.taskForm.performed_text = self.selectedTask.performed_text;
        self.taskForm.data_remarks = self.selectedTask.data_remarks;

        self.allAnswers = JSON.parse(self.selectedTask.response_type_options);

        self.selectedAnswer = [];
        if (self.selectedTask.response) {
          if (self.selectedTask.response_type != 'Datepicker') {
            self.selectedAnswer = _.find(self.allAnswers, function (item) {
              if (item.title === self.selectedTask.response) {
                return self.selectedTask.response;
              }
            });
          } else {
            self.selectedDate = self.selectedTask.response;
          }
        }

        self.selectedDataCollectionAnswer = [];
        if (self.selectedTask.data_response) {
          self.selectedDataCollectionAnswer = _.find(self.allAnswers, function (item) {
            if (item === self.selectedTask.data_response) {
              return self.selectedTask.data_response;
            }
          });
        }

        if (!self.selectedAnswer.title && !self.selectedDate.length) {
          self.editModeResponse = true;
        }
        if (!self.taskForm.notes || !self.taskForm.notes.length) {
          self.editModeNotes = true;
        }
        if (!self.taskForm.control_description || !self.taskForm.control_description.length) {
          self.editModeControlDescription = true;
        }
        if (!self.taskForm.performed_text || !self.taskForm.performed_text.length) {
          self.editModePerformedTest = true;
        }
        if (!self.taskForm.remarks || !self.taskForm.remarks.length) {
          self.editModeConclusion = true;
        }
        if (!self.taskForm.data_remarks || !self.taskForm.data_remarks.length) {
          self.editModeControlDescriptionDocumentationCollection = true;
        }

        this.selectedConfidentialityImpact = [];
        if (self.selectedTask.confidentiality) {
          self.selectedConfidentialityImpact = _.find(self.impactItems, function (item) {
            if (item.value === self.selectedTask.confidentiality) {
              return item;
            }
          });
        }

        this.selectedIntegrityImpact = [];
        if (self.selectedTask.integrity) {
          self.selectedIntegrityImpact = _.find(self.impactItems, function (item) {
            if (item.value === self.selectedTask.integrity) {
              return item;
            }
          });
        }

        this.selectedAvailabilityImpact = []
        if (self.selectedTask.availability) {
          self.selectedAvailabilityImpact = _.find(self.impactItems, function (item) {
            if (item.value === self.selectedTask.availability) {
              return item;
            }
          });
        }

        this.rtoInterval = []
        if (self.selectedTask.rto_type) {
          self.rtoInterval = _.find(self.intervalOptions, function (item) {
            if (item.value === self.selectedTask.rto_type) {
              return item;
            }
          });
        }

        this.rpoInterval = []
        if (self.selectedTask.rpo_type) {
          self.rpoInterval = _.find(self.intervalOptions, function (item) {
            if (item.value === self.selectedTask.rpo_type) {
              return item;
            }
          });
        }

        this.taskForm.rto = this.selectedTask.rto_number;
        this.taskForm.rpo = this.selectedTask.rpo_number;

      },
      showViewGuideModal(object) {
        this.selectedGuide = object;
        this.viewGuideModal = true;
      },
      closeViewGuideModal() {
        this.selectedGuide = '';
        this.viewGuideModal = false;
      },
      openWelcomePage() {
        this.showViewGuideModal(this.welcomeGuide);
      },
      showDropActiveFunction(value) {
        this.showDropActive = value;
      },
      loadTaskGuide() {
        let language = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en';
        let self = this;
        piincHttp.get('guides', {
          params:
            {
              per_page: 1,
              language: 'en',
              type: 'Task',
              scope: 'assessment_task',
              client: this.getClient.slug
            }
        }).then(function (response) {
          if (response.data.items.length) {
            self.welcomeGuide = response.data.items[0];
          }
        }, function () {
        });
      },
      navigateToTask(object) {
        if (this.isOwner(object.model)) {
          this.$store.commit('storeTaskToolbarState', true);
          this.$store.commit('storeActiveTask', object);
          if (object.model_type == 'App\\Activity\\Models\\Activity') {
            this.$router.push({name: 'activities.view', params: {slug: object.model.slug}, query: {task: object.id}});
          }
          if (object.model_type == 'App\\Process\\Models\\Process') {
            this.$router.push({name: 'processes.view', params: {slug: object.model.slug}, query: {task: object.id}});
          }
          if (object.model_type == 'App\\System\\Models\\System') {
            this.$router.push({name: 'systems.view', params: {slug: object.model.slug,}, query: {task: object.id}});
          }
          if (object.model_type == 'App\\Maintenance\\ThirdParty\\Models\\ThirdParty') {
            this.$router.push({name: 'contracts.view', params: {slug: object.model.slug,}, query: {task: object.id}});
          }
        }
      },
      isOwner(object) {
        if (!object || !object.users) {

          return false;
        }
        let self = this;
        let allow = false;
        let existingUser = _.find(object.users, function (user) {
          return user.id == self.getUser.id;
        });

        if (existingUser) {
          allow = true;
        } else {
          if (roleCheck('Client admin') || roleCheck('Partition admin') || roleCheck('Validator') || this.getUser.isAdmin) {
            allow = true;
          }
        }

        return allow;
      },
      isDataOwner(object) {
        let self = this;
        let allow = false;
        let existingUser = _.find(object.data_users, function (user) {
          return user.id == self.getUser.id;
        });

        if (existingUser) {
          allow = true;
        } else {
          if (roleCheck('Client admin') || roleCheck('Partition admin') || roleCheck('Validator') || this.getUser.isAdmin) {
            allow = true;
          }
        }

        return allow;
      },
      isCommentOwner(object) {
        let self = this;
        let allow = false;
        let existingUser = object.user.id == this.getUser.id;

        if (existingUser) {
          allow = true;
        } else {
          if (this.getUser.isAdmin) {
            allow = true;
          }
        }

        return allow;
      },
      minimizeTask() {
        this.saveTask();
        var element = document.getElementsByClassName('modal');
        element[0].classList.add("bottom-left");
        // this.submitForm = true
        this.$store.commit('storeActiveTask', this.selectedTask);
        this.$store.commit('storeTaskToolbarState', true);
      },
      addCommentSubmit() {
        let self = this;
        self.buttonDisabled = false;

        let formData = new FormData();

        formData.append("comment", self.comment);

        piincHttp.post('tasks/' + self.selectedTask.id + '/comments', formData).then(function () {
          self.loadTaskById(self.selectedTask.id, true);
          self.loadComments();
          self.showInput = false
          self.comment = '';
        }, function () {
        })
      },
      changeUsersSubmit() {
        let self = this;
        let userIds = [];

        if (this.selectedAssignUsers && this.selectedAssignUsers.length) {
          for (var i = 0; i < this.selectedAssignUsers.length; i++) {
            userIds.push(this.selectedAssignUsers[i].id);
          }
        }

        if (this.selectedAssignUsers && this.selectedAssignUsers.length) {
          piincHttp.put('tasks/' + self.selectedTask.id, {
            data_users: userIds
          }).then(function (response) {
            self.buttonDisabled = false;
            self.loadTaskById(self.selectedTask.id, true);
            self.hideReopenDataCollectionModal();
          }, function (error) {
            if (error.status !== 200) {
              self.buttonDisabled = false;
              self.error = error.data.message;
            }
          })
        } else {
          this.reopenError = '';
          self.buttonDisabled = false;
          if (this.selectedAssignUsers && !this.selectedAssignUsers.length) {
            this.reopenError += ' ' + this.$t('tasks.modal.recipient_error_message');
          }
        }
      },
      reopenFormSubmit() {
        this.buttonDisabled = true;
        let self = this;

        piincHttp.put('tasks/' + self.selectedTask.id, {
          data_status: 'pending',
        }).then(function (response) {
          self.buttonDisabled = false;
          self.loadTaskById(self.selectedTask.id, true);
        }, function (error) {
          if (error.status !== 200) {
            self.buttonDisabled = false;
            self.error = error.data.message;
          }
        })

      },
      completeTask() {
        this.buttonDisabled = true;
        // Keep the reference in a variable
        // to use it inside function called when promise returned
        let self = this;
        var moment = require('moment');
        let responseAnswer = '';

        if (
          ((this.selectedTask && this.selectedTask.add_attachments == 'Mandatory' && ((this.selectedTask.media && this.selectedTask.media.length) || (this.selectedTask.links && this.selectedTask.links.length))) ||
            (this.selectedTask && this.selectedTask.add_attachments != 'Mandatory')) &&
          ((this.selectedTask && this.selectedTask.add_description == 'Mandatory' && this.taskForm.remarks) ||
            (this.selectedTask && this.selectedTask.add_description != 'Mandatory')) &&
          ((this.selectedTask && this.selectedTask.add_control_description == 'Mandatory' && this.taskForm.control_description) ||
            (this.selectedTask && this.selectedTask.add_control_description != 'Mandatory')) &&
          ((this.selectedTask && this.selectedTask.add_notes == 'Mandatory' && this.taskForm.notes) ||
            (this.selectedTask && this.selectedTask.add_notes != 'Mandatory')) &&
          ((this.selectedTask && this.selectedTask.add_performed_text == 'Mandatory' && this.taskForm.performed_text) ||
            (this.selectedTask && this.selectedTask.add_performed_text != 'Mandatory')) &&
          ((this.selectedTask && this.selectedTask.add_response == 'Mandatory' && ((this.selectedAnswer && this.selectedAnswer.title) || (this.selectedDate && this.selectedDate != ''))) ||
            (this.selectedTask && this.selectedTask.add_response != 'Mandatory'))
        ) {

          if (this.selectedTask.response_type != 'Datepicker') {
            responseAnswer = this.selectedAnswer && this.selectedAnswer.title ? this.selectedAnswer.title : undefined;
          } else {
            responseAnswer = this.selectedDate ? moment(this.selectedDate).format('YYYY-MM-DD') : undefined;
          }

          piincHttp.put('tasks/' + this.selectedTask.id, {
            remarks: this.taskForm.remarks,
            control_description: this.taskForm.control_description,
            notes: this.taskForm.notes,
            performed_text: this.taskForm.performed_text,
            response: responseAnswer,
            status: 'completed'
          }).then(function (response) {
            self.buttonDisabled = false;
            if (self.taskList.length > 1) {
              let index = _.findIndex(self.taskList, function (o) {
                return o.id == self.selectedTask.id
              });
              if (index < self.taskList.length - 1) {
                self.selectedTask = self.taskList[index + 1];
              } else {
                self.selectedTask = self.taskList[0];
              }
              self.taskList.splice(index, 1);
              self.loadTaskById(self.selectedTask.id);
            } else {
              self.taskCompleted = true;
              self.$store.commit('storeTaskToolbarState', false);
              self.hideTaskModal();
            }
          }, function (error) {
            if (error.status !== 200) {
              self.buttonDisabled = false;
              self.error = error.data.message;
            }
          })
        } else {
          this.error = '';
          self.buttonDisabled = false;
          if (this.selectedTask.add_control_description == 'Mandatory' && !this.taskForm.control_description) {
            this.error += ' ' + this.$t('tasks.modal.control_description_error_message');
          }
          if (this.selectedTask.add_notes == 'Mandatory' && !this.taskForm.notes) {
            this.error += ' ' + this.$t('tasks.modal.notes_error_message');
          }
          if (this.selectedTask.add_performed_text == 'Mandatory' && !this.taskForm.performed_text) {
            this.error += ' ' + this.$t('tasks.modal.performed_test_error_message');
          }
          if (this.selectedTask.add_response == 'Mandatory' && (!this.selectedAnswer || (this.selectedAnswer && !this.selectedAnswer.title))) {
            this.error += ' ' + this.$t('tasks.modal.response_error_message');
          }
          if (this.selectedTask.add_description == 'Mandatory' && !this.taskForm.remarks) {
            this.error += ' ' + this.$t('tasks.modal.conclusion_error_message');
          }
          if ((this.selectedTask.add_attachments == 'Mandatory' && this.selectedTask.media && !this.selectedTask.media.length) && (this.selectedTask.add_attachments == 'Mandatory' && this.selectedTask.links && !this.selectedTask.links.length)) {
            this.error += ' ' + this.$t('tasks.modal.attachments_error_message');
          }

        }
      },
      reOpenTask() {
        this.buttonDisabled = true;
        // Keep the reference in a variable
        // to use it inside function called when promise returned
        let self = this;

        piincHttp.put('tasks/' + this.selectedTask.id, {
          status: 'pending'
        }).then(function (response) {
          self.selectedTask = response.data;
          self.buttonDisabled = false;
        }, function (error) {
          if (error.status !== 200) {
            self.buttonDisabled = false;
            self.error = error.data.message;
          }
        })
      },
      completeDataTask() {
        var moment = require('moment');
        this.buttonDisabled = true;
        // Keep the reference in a variable
        // to use it inside function called when promise returned
        let self = this;

        if (
          ((this.selectedTask && this.selectedTask.data_collection_add_attachments == 'Mandatory' && ((this.getMedia('data_documents') && this.getMedia('data_documents').length) || (this.getLinks('data_documents') && this.getLinks('data_documents').length))) ||
            (this.selectedTask && this.selectedTask.data_collection_add_attachments != 'Mandatory')) &&
          ((this.selectedTask && this.selectedTask.data_collection_add_description == 'Mandatory' && this.taskForm.data_remarks) ||
            (this.selectedTask && this.selectedTask.data_collection_add_description != 'Mandatory'))
        ) {
          piincHttp.put('tasks/' + this.selectedTask.id, {
            data_remarks: this.taskForm.data_remarks,
            data_status: 'completed'
          }).then(function (response) {
            self.buttonDisabled = false;
            if (self.taskList.length > 1) {
              let index = _.findIndex(self.taskList, function (o) {
                return o.id == self.selectedTask.id
              });
              if (index < self.taskList.length - 1) {
                self.selectedTask = self.taskList[index + 1];
              } else {
                self.selectedTask = self.taskList[0];
              }
              self.taskList.splice(index, 1);
              self.loadTaskById(self.selectedTask.id);
            } else {
              self.$store.commit('storeTaskToolbarState', false);
              if (self.isOwner(self.selectedTask)) {
                self.currentView = 'default';
                self.loadTaskById(self.selectedTask.id);
              } else {
                self.hideTaskModal();
              }
            }
          }, function (error) {
            if (error.status !== 200) {
              self.buttonDisabled = false;
              self.error = error.data.message;
            }
          })
        } else {
          this.error = '';
          self.buttonDisabled = false;
          if (this.selectedTask.data_collection_add_description == 'Mandatory' && !this.taskForm.data_remarks) {
            this.error += ' ' + this.$t('tasks.modal.comment_error_message');
          }
          if ((this.selectedTask.data_collection_add_attachments == 'Mandatory' && this.getMedia('data_documents') && !this.getMedia('data_documents').length) && (this.selectedTask.data_collection_add_attachments == 'Mandatory' && this.getLinks('data_documents') && !this.getLinks('data_documents').length)) {
            this.error += ' ' + this.$t('tasks.modal.attachments_error_message');
          }

        }
      },
      saveDataTask() {
        var moment = require('moment');
        this.buttonDisabled = true;
        // Keep the reference in a variable
        // to use it inside function called when promise returned
        let self = this;

        if (
          ((this.selectedTask && this.selectedTask.data_collection_add_attachments == 'Mandatory' && ((this.getMedia('data_documents') && this.getMedia('data_documents').length) || (this.getLinks('data_documents') && this.getLinks('data_documents').length))) ||
            (this.selectedTask && this.selectedTask.data_collection_add_attachments != 'Mandatory')) &&
          ((this.selectedTask && this.selectedTask.data_collection_add_description == 'Mandatory' && this.taskForm.data_remarks) ||
            (this.selectedTask && this.selectedTask.data_collection_add_description != 'Mandatory'))
        ) {
          piincHttp.put('tasks/' + this.selectedTask.id, {
            data_remarks: this.taskForm.data_remarks
          }).then(function (response) {
            self.buttonDisabled = false;
            if (self.taskList.length > 1) {
              let index = _.findIndex(self.taskList, function (o) {
                return o.id == self.selectedTask.id
              });
              if (index < self.taskList.length - 1) {
                self.selectedTask = self.taskList[index + 1];
              } else {
                self.selectedTask = self.taskList[0];
              }
              self.taskList.splice(index, 1);
              self.loadTaskById(self.selectedTask.id);
            } else {
              self.$store.commit('storeTaskToolbarState', false);
              if (self.isOwner(self.selectedTask)) {
                self.currentView = 'default';
                self.loadTaskById(self.selectedTask.id);
              } else {
                self.hideTaskModal();
              }
            }
          }, function (error) {
            if (error.status !== 200) {
              self.buttonDisabled = false;
              self.error = error.data.message;
            }
          })
        } else {
          this.error = '';
          self.buttonDisabled = false;
          if (this.selectedTask.data_collection_add_description == 'Mandatory' && !this.taskForm.data_remarks) {
            this.error += ' ' + this.$t('tasks.modal.comment_error_message');
          }
          if ((this.selectedTask.data_collection_add_attachments == 'Mandatory' && this.getMedia('data_documents') && !this.getMedia('data_documents').length) && (this.selectedTask.data_collection_add_attachments == 'Mandatory' && this.getLinks('data_documents') && !this.getLinks('data_documents').length)) {
            this.error += ' ' + this.$t('tasks.modal.attachments_error_message');
          }

        }
      },
      reOpenDataTask() {
        this.buttonDisabled = true;
        // Keep the reference in a variable
        // to use it inside function called when promise returned
        let self = this;

        piincHttp.put('tasks/' + this.selectedTask.id, {
          data_status: 'pending'
        }).then(function (response) {
          self.selectedTask = response.data;
          self.buttonDisabled = false;
        }, function (error) {
          if (error.status !== 200) {
            self.buttonDisabled = false;
            self.error = error.data.message;
          }
        })
      },
      completeSystemRiskTask() {
        this.buttonDisabled = true;
        // Keep the reference in a variable
        // to use it inside function called when promise returned
        let self = this;
        this.error = '';

        if (
          this.taskForm.remarks != '' &&
          this.selectedConfidentialityImpact && this.selectedConfidentialityImpact.value &&
          this.selectedIntegrityImpact && this.selectedIntegrityImpact.value &&
          this.selectedAvailabilityImpact && this.selectedAvailabilityImpact.value &&
          this.taskForm.rto != '' &&
          this.taskForm.rpo != '' &&
          this.rtoInterval && this.rtoInterval.value &&
          this.rpoInterval && this.rpoInterval.value
        ) {
          piincHttp.put('tasks/' + this.selectedTask.id, {
            remarks: this.taskForm.remarks,
            confidentiality: this.selectedConfidentialityImpact ? this.selectedConfidentialityImpact.value : '',
            integrity: this.selectedIntegrityImpact ? this.selectedIntegrityImpact.value : '',
            availability: this.selectedAvailabilityImpact ? this.selectedAvailabilityImpact.value : '',
            rto_number: this.taskForm.rto,
            rto_type: this.rtoInterval ? this.rtoInterval.value : '',
            rpo_number: this.taskForm.rpo,
            rpo_type: this.rtoInterval ? this.rpoInterval.value : '',
            status: 'completed'
          }).then(function (response) {
            self.$toastr('success', self.$t('tasks.modal.success'));
            self.buttonDisabled = false;

            if (self.taskList.length > 1) {
              let index = _.findIndex(self.taskList, function (o) {
                return o.id == self.selectedTask.id
              })
              if (index < self.taskList.length - 1) {
                self.selectedTask = self.taskList[index + 1];
              } else {
                self.selectedTask = self.taskList[0];
              }
              self.taskList.splice(index, 1);
              self.loadTaskById(self.selectedTask.id);
            } else {
              self.$refs['task-modal'].hide();
            }
          }, function (error) {
            if (error.status !== 200) {
              self.buttonDisabled = false;
              self.error = error.data.message;
            }
          })
        } else {
          this.error = '';
          self.buttonDisabled = false;
          this.error = this.$t('tasks.modal.all_fields_error_message');
        }
      },
      saveTask() {
        var moment = require('moment');
        this.buttonDisabled = true;
        // Keep the reference in a variable
        // to use it inside function called when promise returned
        let self = this;
        let responseAnswer = '';

        if (this.selectedTask.response_type != 'Datepicker') {
          responseAnswer = this.selectedAnswer && this.selectedAnswer.title ? this.selectedAnswer.title : '';
        } else {
          responseAnswer = this.selectedDate ? moment(this.selectedDate).format('YYYY-MM-DD') : '';
        }

        piincHttp.put('tasks/' + this.selectedTask.id, {
          data_remarks: this.taskForm.data_remarks,
          remarks: this.taskForm.remarks,
          performed_text: this.taskForm.performed_text,
          control_description: this.taskForm.control_description,
          notes: this.taskForm.notes,
          confidentiality: this.selectedConfidentialityImpact ? this.selectedConfidentialityImpact.value : '',
          integrity: this.selectedIntegrityImpact ? this.selectedIntegrityImpact.value : '',
          availability: this.selectedAvailabilityImpact ? this.selectedAvailabilityImpact.value : '',
          rto_number: this.taskForm.rto,
          rto_type: this.rtoInterval ? this.rtoInterval.value : '',
          rpo_number: this.taskForm.rpo,
          rpo_type: this.rtoInterval ? this.rpoInterval.value : '',
          response: responseAnswer,
          data_response: this.selectedDataCollectionAnswer && this.selectedDataCollectionAnswer.length ? this.selectedDataCollectionAnswer : '',
        }).then(function (response) {
          self.buttonDisabled = false;
          self.$emit('reloadContent', response.data);
          self.hideTaskModal();
          self.$toastr('success', self.$t('tasks.modal.success'))
        }, function (error) {
          if (error.status !== 200) {
            self.buttonDisabled = false;
            self.error = error.data.message;
          }
        })

      },
      openDeleteAttachmentModal(object) {
        this.selectedFile = object;
        this.showDeleteAttachmentModal = true;
      },
      closeDeleteAttachmentModal() {
        this.selectedFile = {};
        this.showDeleteAttachmentModal = false;
        this.loadTaskById(this.selectedTask.id, true);
      },
      openDeleteLinkModal(object) {
        this.selectedLink = object;
        this.showDeleteLinkModal = true;
      },
      closeDeleteLinkModal() {
        this.selectedLink = {};
        this.showDeleteLinkModal = false;
        this.loadTaskById(this.selectedTask.id, true);
      },
      openDeleteCommentModal(object) {
        this.selectedComment = object;
        this.showDeleteCommentModal = true;
      },
      closeDeleteCommentModal() {
        this.selectedComment = {};
        this.showDeleteCommentModal = false;
        this.loadComments();
      },
      handleLink() {
        let self = this;
        let formData = new FormData();

        if (!this.inputLink.startsWith('http')) {
          this.inputLink = 'http://' + this.inputLink;
        }

        formData.append("url", this.inputLink);
        formData.append("name", this.inputLinkName);
        formData.append("collection", "task");
        formData.append("model_type", this.selectedTask.who_am_i);
        formData.append("model_id", this.selectedTask.id);

        piincHttp.post('links', formData).then(function () {
          self.inputLink = '';
          self.inputLinkName = '';
          self.hideCreateLinkModal();
          self.loadTaskById(self.selectedTask.id, true);
          self.showInput = false;
        }, function () {
        })
      },
      handleDataCollectionLink() {
        let self = this;
        let formData = new FormData();

        if (!this.inputLink.startsWith('http')) {
          this.inputLink = 'http://' + this.inputLink;
        }

        formData.append("url", this.inputLink);
        formData.append("name", this.inputLinkName);
        formData.append("collection", "data_documents");
        formData.append("model_type", this.selectedTask.who_am_i);
        formData.append("model_id", this.selectedTask.id);

        piincHttp.post('links', formData).then(function () {
          self.inputLink = '';
          self.inputLinkName = '';
          self.loadTaskById(self.selectedTask.id, true);
          self.showDataCollectionLinksSection = false;
        }, function () {
        })
      },
      downloadMedia(object) {
        piincHttp.get('documents/' + object.id + '/download', {
          responseType: 'blob',
          params: {
            task: this.selectedTask.id
          }
        }).then(function (response) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.download = object.file_name;

          link.click();
          link.remove();
          window.URL.revokeObjectURL(url);
        }, function () {
        });
      },
      loadTaskById(id, ignorePopulate) {
        let self = this;
        piincHttp.get('tasks/' + id).then(function (response) {
          self.selectedTask = response.data;
          self.project_progress = self.selectedTask.project.user_progress.progress;
          if (!ignorePopulate) {
            self.populateForm();
            // Checking the type of the users so the right view is displayed
            if (self.isOwner(self.selectedTask)) {
              self.switchCurrentView('default');
              if (self.selectedTask.code == 'update_measures') {
                self.loadGuidesSystem('system_description_task');
              } else {
                self.loadGuides('assessment_task');
              }
            } else {
              if (self.isDataOwner(self.selectedTask)) {
                self.switchCurrentView('data_collection');
              }
            }
          }
        }, function () {
        });
      },
      onFileChangeEdit(file) {
        let self = this;
        let formData = new FormData();
        let sizeError = false;

        // Append list of attachments to FormData
        for (var i = 0; i < this.files.length; i++) {
          // Equal to 10 MB
          if (this.files[i].size > 31457280) {
            sizeError = true;
          }
          formData.append("document[]", this.files[i].file);
        }

        formData.append("name", "documents");
        formData.append("model_type", this.selectedTask.who_am_i);
        formData.append("model_id", this.selectedTask.id);

        if (sizeError) {
          this.sizeError = this.$t('system.file_size_30');
          this.files = [];
        } else {
          piincHttp.post('documents', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }).then(function () {
            self.error = '';
            self.sizeError = '';
            self.loadTaskById(self.selectedTask.id, true);
            self.files = [];
          }, function (error) {
            self.error = error.data.message;
            if (error.data.errors['document.0']) {
              self.error += ' ' + error.data.errors['document.0'][0];
            }
          })
        }
      },
      onDataFileChange(file) {
        let self = this;
        let formData = new FormData();
        let sizeError = false;

        // Append list of attachments to FormData
        for (var i = 0; i < this.data_collection_files.length; i++) {
          // Equal to 10 MB
          if (this.data_collection_files[i].size > 31457280) {
            sizeError = true;
          }
          formData.append("document[]", this.data_collection_files[i].file);
        }

        formData.append("name", "documents");
        formData.append("collection", "data_documents");
        formData.append("model_type", this.selectedTask.who_am_i);
        formData.append("model_id", this.selectedTask.id);

        if (sizeError) {
          this.error = this.$t('system.file_size_30')
          this.data_collection_files = [];
        } else {
          piincHttp.post('documents', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }).then(function () {
            self.error = '';
            self.loadTaskById(self.selectedTask.id, true);
            self.data_collection_files = [];
          }, function (error) {
            self.error = error.data.message;
            if (error.data.errors['document.0']) {
              self.error += ' ' + error.data.errors['document.0'][0];
            }
          })
        }
      },
      loadObservations(task_id) {
        let self = this;

        // If page is not defined, set page to default 1
        let pageNumber = 1;

        piincHttp.get('projects/' + this.$route.params.slug + '/observations', {
          params:
            {
              page: pageNumber,
              client: this.getClient.slug,
              task_id: task_id
            }
        }).then(function (response) {
          if (response.data && response.data.items) {
            self.sTableItems = response.data.items;
          }
          // Set number of total items
          self.totalRows = response.data.total_count;
          self.sLoading = false;
        }, function () {
        });
      },
      showEditModal(object) {
        let self = this;
        this.error = '';
        this.selectedS = object

        this.editForm.name = object.name
        this.editForm.description = object.description

        if (this.selectedTask.project.type != 'control_assessment') {
          this.selectedScope = []
          if (object.scope && object.scope.id) {
            this.selectedScope = _.find(this.scopeItems, function (item) {
              return item.id === object.scope.id;
            });
          }
        } else {

        }

        this.selectedImpact = []
        if (object.impact_assessment) {
          this.selectedImpact = _.find(this.impactItems, function (item) {
            return item.value === object.impact_assessment;
          });
        }

        this.$refs['edit-observation'].show()
      },
      editObservationFormSubmit(evt) {
        this.buttonDisabled = true;
        // Keep the reference in a variable
        // to use it inside function called when promise returned
        let self = this;
        evt.preventDefault();

        this.editForm.impact_assessment = this.selectedImpact ? this.selectedImpact.value : '';

        if (this.activity && this.activity.type == 'control_assessment') {
          if (this.selectedTask && this.selectedTask.id) {
            this.editForm.scope_id = this.selectedTask ? this.selectedTask.id : '';
          }
        } else {
          this.editForm.scope_id = this.selectedScope ? this.selectedScope.id : '';
        }

        if (this.selectedImpact && this.selectedImpact.value) {
          piincHttp.put('projects/' + this.$route.params.slug + '/observations/' + this.selectedS.id, this.editForm).then(function(response) {
            self.hideEditModal();
            self.loadObservations(self.selectedTask.id);
            self.$toastr('success', self.$t('projects.observations.update.success'))
            self.buttonDisabled = false;
          }, function(error) {
            if (error.status !== 200) {
              self.buttonDisabled = false;
              self.error = error.data.message;
              if (error.data.errors.users) {
                self.error += ' ' + error.data.errors.users[0]
              }
            }
          })
        } else {
          this.error = '';
          self.buttonDisabled = false;
          if (!this.selectedScope || (this.selectedScope && !this.selectedScope.id)) {
            this.error += this.$t('projects.observations.create.scope_error_message')
          }
          if (!this.selectedImpact || (this.selectedImpact && !this.selectedImpact.value)) {
            this.error += ' ' + this.$t('projects.observations.create.impact_error_message')
          }
        }
      },
      hideEditModal() {
        this.selectedS = {}
        this.$refs['edit-observation'].hide()
      },
      showSConfirmDeleteModal(object) {
        this.itemToDelete = object
        this.$refs['delete-observation'].show()
      },
      hideSConfirmDeleteModal() {
        this.itemToDelete = {}
        this.$refs['delete-observation'].hide()
      },
      handleCloseViewModal() {
        this.loadObservations(this.selectedTask.id);
        this.viewObservationModal = false;
        this.selectedObservation = []
      },
      openViewModal(object) {
        this.selectedObservation = object
        this.viewObservationModal = true
      },
      onDragOver(event) {
        // Optional: You can handle visual cues when dragging over the area.
        console.log("Drag over");
      },
      processFiles(fileList) {
        // Process the list of files
        for (let i = 0; i < fileList.length; i++) {
          this.files.push(fileList[i]);
        }

        let self = this;
        let formData = new FormData();
        let sizeError = false;

        // Append list of attachments to FormData
        for (var i = 0; i < this.files.length; i++) {
          // Equal to 10 MB
          if (this.files[i].size > 31457280) {
            sizeError = true;
          }
          formData.append("document[]", this.files[i]);
        }

        formData.append("name", "documents");
        formData.append("model_type", this.selectedTask.who_am_i);
        formData.append("model_id", this.selectedTask.id);

        if (sizeError) {
          this.sizeError = this.$t('system.file_size_30');
          this.files = [];
        } else {
          piincHttp.post('documents', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }).then(function () {
            self.error = '';
            self.sizeError = '';
            self.loadTaskById(self.selectedTask.id, true);
            self.files = [];
          }, function (error) {
            self.error = error.data.message;
            if (error.data.errors['document.0']) {
              self.error += ' ' + error.data.errors['document.0'][0];
            }
          })
        }

        console.log("Dropped files:", this.files);
      },

      processFilesDocumentation(fileList) {
        // Process the list of files
        for (let i = 0; i < fileList.length; i++) {
          this.data_collection_files.push(fileList[i]);
        }

        let self = this;
        let formData = new FormData();
        let sizeError = false;

        // Append list of attachments to FormData
        for (var i = 0; i < this.data_collection_files.length; i++) {
          // Equal to 10 MB
          if (this.data_collection_files[i].size > 31457280) {
            sizeError = true;
          }
          formData.append("document[]", this.data_collection_files[i]);
        }

        formData.append("name", "documents");
        formData.append("collection", "data_documents");
        formData.append("model_type", this.selectedTask.who_am_i);
        formData.append("model_id", this.selectedTask.id);

        if (sizeError) {
          this.error = this.$t('system.file_size_30')
          this.data_collection_files = [];
        } else {
          piincHttp.post('documents', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }).then(function () {
            self.error = '';
            self.loadTaskById(self.selectedTask.id, true);
            self.data_collection_files = [];
          }, function (error) {
            self.error = error.data.message;
            if (error.data.errors['document.0']) {
              self.error += ' ' + error.data.errors['document.0'][0];
            }
          })
        }

        console.log("Dropped files:", this.files);
      },
      onFileDrop(event) {
        // Handle the file drop, prevent default to stop the default behavior
        console.log("File dropped");
      },
      handleDrop(event) {
        event.preventDefault(); // Prevent the default file-open behavior
        const files = event.dataTransfer.files; // Get the dropped files
        if (files.length) {
          this.processFiles(files); // Call a custom method to handle the files
        }
      },
      handleDropDocumentation(event) {
        event.preventDefault(); // Prevent the default file-open behavior
        const data_collection_files = event.dataTransfer.files; // Get the dropped files
        if (data_collection_files.length) {
          this.processFilesDocumentation(data_collection_files); // Call a custom method to handle the files
        }
      },
      hideDropActiveFunction() {
        // Handle when drop area becomes inactive
        console.log("Drop inactive");
      },
    },
    created() {
      // this.loadTaskById(this.task.id);
    }
  }
</script>
